import React, { useState, useContext, createContext } from 'react';
import { configData } from '../config.js';

const authContext = createContext();

// delivers auth context for entire app after signing in
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')));

    const signin = (email, password) => {
        let url = `${configData.API_URL}/user/login`;
        let credentials = { email: email, password: password };

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        };

        return fetch(url, requestOptions).then(async (response) => {
            const text = await response.text();
            if (response.ok) {
                const user = text && JSON.parse(text);
                localStorage.setItem('user', JSON.stringify(user));
                setUser(user);
            } else if (response.status === 400 && text === "Users don't have login data.") {
                throw new Error(text);
            } else throw new Error('Unauthorized');
        });
    };

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
    };

    return {
        user,
        signin,
        logout,
    };
}
