import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, ThemeProvider, Box, Button, } from '@mui/material';
import colourTheme from '../styles/ColourTheme';
import { withTranslation } from 'react-i18next';
import { flexBox, mainButtonStyle } from '../styles/ComponentStylings';
import SurveyLanguageHeader from '../components/Language/SurveyLanguageHeader';
// import { useReactPWAInstall } from "react-pwa-install";
// import myLogo from "../assets/favicon.ico";

const SurveySuccessPage = (props) => {
    const { t, controlGroup, userType } = props;

    return (
        <div className='SignUpCompletePage'>
            <ThemeProvider theme={colourTheme}>
                <SurveyLanguageHeader />
                <Container component='main' maxWidth='s'>
                    <Box sx={{
                        pt: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 5
                    }} >
                        <Typography component='h1' align='center' marginTop={5}>
                            {controlGroup && userType === "Participant" ? t('text_signupsuccess_controlgroup') :
                                // text with line break
                                <div>
                                    {t('text_signupsuccess_usergroup_1')}
                                    <br />
                                    <br />
                                    {t('text_signupsuccess_usergroup_2')}
                                </div>
                            }
                        </Typography>
                        {!(controlGroup && userType === "Participant") &&
                            <Box sx={flexBox}>
                                <Link to='/' style={{ textDecoration: 'none' }}>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        sx={mainButtonStyle}
                                    >
                                        {t('button_home')}
                                    </Button>
                                </Link>
                            </Box>
                        }
                    </Box>
                </Container>
            </ThemeProvider>
        </div >
    )
}

export default withTranslation()(SurveySuccessPage);