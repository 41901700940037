import * as React from 'react';
import { Grid, Paper, Container, Button } from '@mui/material';
import useSWR, { mutate } from 'swr';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PatientInformation from '../../components/Tables/PatientInformation';
import NutrientTableDetailsTable from '../../components/NutrientTable/NutrientTableDetailsTable';
import DieticianDashboardPage from './DieticianDashboardPage';
import HistorySuggestions from '../../components/Suggestions/HistorySuggestions';
import NutrientTable from '../../components/NutrientTable/NutrientTable';
import CreateSuggestions from '../../components/Recommendations/CreateSuggestions';
import RejectedRecommendations from '../../components/Recommendations/RejectedRecommendations';
import UserShoppingHistory from '../../components/Recommendations/UserShoppingHistory';
import CircularLoading from '../../components/Loading/CircularLoading';
import { majorFoodCategoryIds, minorFoodCategoryParentIds } from '../../utils/NutrientTableCategories';
import { getData } from '../../services/dietician';
import { minor_category_translations_accusative, nutrient_translations } from '../../utils/ListOfThings';

const PatientOverview = () => {
    const { t } = useTranslation();

    const userid = useParams().userid;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    const [nutrientTableDetails, setNutrientTableDetails] = useState({
        averageNutriScore: '-',
        inTableBasketNumber: '-',
        allPurchasesItemNumber: '-',
        inTableItems: '-',
        allPurchasesTotalPrice: '-',
        inTableTotalPrice: '-',
        earliestTimestamp: '-',
        latestTimestamp: '-',
    });

    const [suggestions, setSuggestions] = useState([]);
    const { data: surveyData, error: surveyDataError } = useSWR(['/dietician/user-survey', userid], (url) =>
        getData(url, userid)
    );

    const { data: suggestionHistory, error: suggestionHistoryError } = useSWR(
        ['/dietician/recommendations-with-products', userid],
        (url) => getData(url, userid)
    );

    const { data: shoppingHistory, error: shoppingHistoryError } = useSWR(
        ['/dietician/shopping-history', userid],
        (url) => {
            return getData(url, userid).then((history) => {
                if (history.length > 0) {
                    const maxDate = getMaxDate(history);
                    const minDate = getMinDate(history);

                    const fourWeeksAgo = new Date(maxDate);
                    fourWeeksAgo.setDate(fourWeeksAgo.getDate() - 28);
                    if (minDate.getTime() < fourWeeksAgo.getTime()) {
                        setStartDate(fourWeeksAgo);
                    } else {
                        setStartDate(minDate);
                    }

                    setEndDate(maxDate);
                    setMinDate(minDate);
                    setMaxDate(maxDate);

                    
                }
                return history;
            });
        },
        { revalidateOnFocus: false }
    );

    const { data: rejectedRecommendations, error: rejectedRecommendationsError } = useSWR(
        ['/dietician/get-rejected-recommendations', userid],
        (url) => getData(url, userid)
    );

    React.useEffect(() => {
        mutate(['/dietician/nutrient-table', userid]);
    }, [minDate, maxDate, userid]);

    const {
        data: nutrientTableData,
        error: nutrientTableDataError,
        isValidating: fetchingNutrientTable,
    } = useSWR(
        ['/dietician/nutrient-table', userid],
        async (url) => {
            if (!startDate || !endDate) return [];
            return await getData(url, userid, Math.round(startDate / 1000), Math.round(endDate / 1000)).then((res) => {
                let tableData = [];
                if (res.length === 0) {
                    // set empty nutrient table details so that timeframe is selectable
                    const defaultDetailsTable = {
                        averageNutriScore: '-',
                        inTableBasketNumber: '-',
                        allPurchasesItemNumber: '-',
                        inTableItems: '-',
                        allPurchasesTotalPrice: '-',
                        inTableTotalPrice: '-',
                        earliestTimestamp: '-',
                        latestTimestamp: '-',
                    };
                    setNutrientTableDetails(defaultDetailsTable);
                } else {
                    // set nutrient table details
                    const detailsData = {
                        averageNutriScore: res.averageNutriScore,
                        inTableBasketNumber: res.inTableBasketNumber,
                        allPurchasesItemNumber: res.allPurchasesItemNumber,
                        inTableItems: res.inTableItems,
                        allPurchasesTotalPrice: Number(res.allPurchasesTotalPrice).toFixed(2),
                        inTableTotalPrice: Number(res.inTableTotalPrice).toFixed(2),
                        earliestTimestamp: res.earliestTimestamp,
                        latestTimestamp: res.latestTimestamp,
                    };
                    setNutrientTableDetails(detailsData);
                    tableData = formatNutrientTableData(res.nutrientTable);
                }
                // return tabledata but sorted alphabetically
                return tableData.sort((a, b) => (a.parentId > b.parentId ? 1 : -1));
            });
        },
        { revalidateOnFocus: false }
    );

    // sets the new suggestions table contents
    const handleUpdateSuggestions = (isParent, parentId, incr_or_reduce, nutrient, minor_category, value) => {
        let newSuggestion = {};
        let description_en;
        let description_de;
        // fruits and vegetables should always have increase suggestion
        if (minor_category === 'Fruits' || minor_category === 'Vegetables') {
            description_en = 'Increase your intake from ' + minor_category;
            description_de = 'Erhöhen Sie Ihren Verzehr von ' + minor_category_translations_accusative[minor_category];
            newSuggestion = {
                description_en: description_en,
                description_de: description_de,
                id: suggestions.length + 1,
                instruction: 'Increase',
                nutrient: '',
                minor_category: minor_category,
            };
        }
        // alcoholic beverages should always have decrease suggestion
        // TODO check with Jing what minor category this is
        else if (minor_category === 'Beverages') {
            description_en = 'Reduce your intake from ' + minor_category;
            description_de =
                'Reduzieren Sie Ihren Verzehr von ' + minor_category_translations_accusative[minor_category];
            newSuggestion = {
                description_en: description_en,
                description_de: description_de,
                id: suggestions.length + 1,
                instruction: 'Reduce',
                nutrient: '',
                minor_category: minor_category,
            };
        } else {
            description_en = incr_or_reduce + ' ' + nutrient + ' from ' + minor_category;
            description_de =
                germanIncrReduce(incr_or_reduce) +
                ' ' +
                nutrient_translations[nutrient] +
                ' aus ' +
                minor_category_translations_accusative[minor_category];
            newSuggestion = {
                description_en: description_en,
                description_de: description_de,
                id: suggestions.length + 1,
                instruction: incr_or_reduce,
                nutrient: nutrient,
                minor_category: minor_category,
            };
        }

        // cannot add the same suggestion twice
        if (
            !suggestions
                .map((sug) => sug.recommendation)
                .find((element) => element.description_en === description_en) &&
            !isParent &&
            value !== '-' &&
            suggestions.length < 5
        ) {
            let updateSuggestions = [...suggestions];
            updateSuggestions.push({ recommendation: newSuggestion });
            setSuggestions(updateSuggestions);
        }
    };

    const removeSuggestion = (id) => {
        const remainingSuggestions = suggestions.filter((rec) => rec.recommendation.id !== id);
        // readjust priority ids
        for (var i = 0, suggestion_id = 1; i < remainingSuggestions.length; i++, suggestion_id++) {
            remainingSuggestions[i].recommendation.id = suggestion_id;
        }
        setSuggestions(remainingSuggestions);
    };

    // select a history suggestions data set and reload nutrient table
    const handleSuggestionHistoryClick = (value) => {
        let startDate = null;
        let endDate = null;
        suggestionHistory
            .filter((rec) => new Date(rec.save_time).getTime() === value.getTime())
            .forEach((sug) => {
                startDate = new Date(sug.purchases_start);
                endDate = new Date(sug.purchases_end);
            });
        setStartDate(startDate);
        setEndDate(endDate);
        mutate(['/dietician/nutrient-table', userid]);
    };

    const useSuggestionHistory = () => {
        setSuggestions(() => {
            return suggestionHistory.slice(0, 5).map((sug) => {
                const split = sug.recommendation.recommendation_en.split(' from ');
                const minor_category = split[1];
                const incr_or_reduce = split[0].substring(0, split[0].indexOf(' '));
                const nutrient = split[0].substring(split[0].indexOf(' ') + 1);

                return {
                    recommendation: {
                        description_en: sug.recommendation.recommendation_en,
                        description_de: sug.recommendation.recommendation_de,
                        id: sug.importance,
                        instruction: incr_or_reduce,
                        nutrient: nutrient,
                        minor_category: minor_category,
                    },
                    products: sug.products,
                };
            });
        });
    };

    return (
        <DieticianDashboardPage
            title={t('title_patient_overview')}
            content={
                <Container sx={{ mt: 4, mb: 4, ml: 2 }}>
                    {/* only display whole page once all data has been loaded */}
                    <Grid container spacing={2} alignItems="center" justify="center">
                        {((suggestionHistory && suggestionHistory.length > 0) || suggestionHistoryError) && (
                            <HistorySuggestions
                                suggestionHistory={suggestionHistory}
                                suggestionHistoryError={suggestionHistoryError}
                                handleSuggestionHistoryClick={handleSuggestionHistoryClick}
                            />
                        )}
                        <Grid item xs={12}>
                            <NutrientTableDetailsTable
                                tableData={nutrientTableDetails}
                                onFilter={() => mutate(['/dietician/nutrient-table', userid])}
                                startDate={startDate}
                                endDate={endDate}
                                updateStartDate={(value) => setStartDate(value)}
                                updateEndDate={(value) => setEndDate(value)}
                                minDate={minDate}
                                maxDate={maxDate}
                            />
                            {shoppingHistory && nutrientTableData && !fetchingNutrientTable ? (
                                <NutrientTable
                                    tableData={nutrientTableData}
                                    nutrientTableDataError={nutrientTableDataError}
                                    suggestions={suggestions}
                                    handleUpdateSuggestions={handleUpdateSuggestions}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    userid={userid}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ) : (
                                <CircularLoading />
                            )}
                            {(rejectedRecommendations && rejectedRecommendations.length > 0) ||
                                (rejectedRecommendationsError && (
                                    <RejectedRecommendations
                                        rejectedRecommendations={rejectedRecommendations}
                                        rejectedRecommendationsError={rejectedRecommendationsError}
                                    />
                                ))}
                            {suggestionHistory && suggestionHistory.length > 0 && !suggestionHistory[0].is_pushed && (
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'grid',
                                        flexDirection: 'column',
                                        height: 'auto',
                                        mb: 2,
                                        mt: 2,
                                    }}
                                >
                                    <Button variant="contained" color="primary" onClick={useSuggestionHistory}>
                                        {t('show_last_saved_recommendations')}
                                    </Button>
                                </Paper>
                            )}
                            {suggestions.length > 0 && (
                                <CreateSuggestions
                                    suggestions={suggestions}
                                    userid={userid}
                                    startDate={startDate}
                                    endDate={endDate}
                                    removeSuggestion={(id) => removeSuggestion(id)}
                                    updateSuggestions={(sug) => setSuggestions(sug)}
                                />
                            )}
                            <UserShoppingHistory
                                shoppingHistory={shoppingHistory}
                                shoppingHistoryError={shoppingHistoryError}
                            />
                        </Grid>
                        {(surveyData || surveyDataError) && (
                            <PatientInformation
                                userid={userid}
                                surveyData={surveyData}
                                surveyDataError={surveyDataError}
                            />
                        )}
                    </Grid>
                </Container>
            }
        />
    );
};

// returns correctly formatted row for nutrient table display
function createNutrientTableRows(
    majorcategory,
    minorcategory,
    quantity,
    energyShare,
    energy,
    sugar,
    saturatedfat,
    sodium,
    fiber,
    fruitveg,
    protein
) {
    if (minorcategory === 'none') {
        return {
            id: majorFoodCategoryIds.get(majorcategory),
            parent: true,
            name: majorcategory,
            quantity: quantity,
            energyShare: energyShare,
            energy: energy,
            sugar: sugar,
            saturatedfat: saturatedfat,
            sodium: sodium,
            DietaryFiber: fiber,
            fruitveg: fruitveg,
            protein: protein,
        };
    } else {
        // handle minor categories (child rows)
        return {
            // set a unique id so that nutrient table check isn't stuck in endless loop
            id: `id-${minorcategory}`,
            parentId: minorFoodCategoryParentIds.get(minorcategory),
            parent: false,
            name: minorcategory,
            quantity: quantity,
            energyShare: energyShare,
            energy: energy,
            sugar: sugar,
            saturatedfat: saturatedfat,
            sodium: sodium,
            DietaryFiber: fiber,
            fruitveg: fruitveg,
            protein: protein,
        };
    }
}

// function to handle each row sent from backend
function formatNutrientTableData(data) {
    const tableData = data.map((row) =>
        createNutrientTableRows(
            row.MajorCategory,
            row.MinorCategory,
            row.Quantity,
            row.EnergyShare,
            row.EnergyKJ,
            row.Sugar,
            row.SaturatedFat,
            row.Sodium,
            row.DietaryFiber,
            row.FVPN,
            row.Protein
        )
    );
    return tableData;
}

const getMaxDate = (history) => {
    const maxDate = new Date(history[0]['baskets'][0]['basket_timestamp'] * 1000)
    return maxDate
};

const getMinDate = (history) => {
    const minDate = new Date(history[history.length - 1]['baskets'][history[history.length - 1]['baskets'].length - 1]['basket_timestamp'] * 1000)
    return minDate
};

const germanIncrReduce = (incr_or_reduce) => {
    if (incr_or_reduce === 'Increase') return 'Erhöhen Sie Ihren Verzehr von';
    if (incr_or_reduce === 'Reduce') return 'Reduzieren Sie Ihren Verzehr von';
};

export default PatientOverview;
