import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { Paper, Divider, Container, Grid, Card, CardContent, Typography } from '@mui/material';

import DieticianDashboardPage from './DieticianDashboardPage';
import { paperStyling } from '../../styles/ComponentStylings';
import { Title } from '../../utils/commonComponents';
import { getData } from '../../services/dietician';
import CircularLoading from '../../components/Loading/CircularLoading';

export default function DieticianDashboard() {
    const { t } = useTranslation();
    const { data, error } = useSWR('/dietician/get-dietcoach-user-statistics', (url) => getData(url));

    if (error) {
        return (
            <DieticianDashboardPage
                title={t('title_dietician_dashboard')}
                isDietician={true}
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('alert_error')}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    return (
        <DieticianDashboardPage
            title={t('title_dietician_dashboard')}
            isDietician={true}
            content={
                <>
                    {!data ? (
                        <CircularLoading />
                    ) : (
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                            <Paper sx={paperStyling}>
                                <Title sx={{ flexGrow: 1, pl: 2.5, pb: 2 }} title="App-User" />
                                <Divider />
                                <List sx={{ width: '100%', alignItems: 'center', justify: 'center' }}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                        direction="column"
                                    >
                                        <Grid item xs={12}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <SupervisorAccountIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={t('dieticians')} secondary={data.dieticians} />
                                            </ListItem>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mt: 2, mb: 4 }} />
                                    <Container maxWidth="lg">
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={4}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PersonIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={t('participantswithshoppingdata')}
                                                        secondary={data.insel_participants_with_shopping_data}
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PersonOffIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={t('participantswithoutshoppingdata')}
                                                        secondary={data.insel_participants_without_shopping_data}
                                                    />
                                                </ListItem>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PersonIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={t('volunteerswithshoppingdata')}
                                                        secondary={
                                                            data.volunteer_experiment_with_shopping_data +
                                                            data.volunteer_control_with_shopping_data
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PersonOffIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={t('volunteerswithoutshoppingdata')}
                                                        secondary={
                                                            data.volunteer_experiment_without_shopping_data +
                                                            data.volunteer_control_without_shopping_data
                                                        }
                                                    />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </List>
                            </Paper>
                        </Container>
                    )}
                </>
            }
        />
    );
}
