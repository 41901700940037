import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@material-ui/core';
import colourTheme from './styles/ColourTheme';
import { ProvideAuth } from './services/useAuth';
import ReactPWAInstallProvider from 'react-pwa-install';
import { useServiceWorker } from './useServiceWorker';
import { useEffect } from 'react';
import { InfoAlert } from './components/Alert/Alerts';
import { useTranslation } from 'react-i18next';

function App() {
    const content = useRoutes(routes);
    const importantUpdate = true;

    const [showRefresh, setShowRefresh] = React.useState(false);
    const { waitingWorker, showReload, reloadPage } = useServiceWorker();
    const { t } = useTranslation();
    // decides when to show the toast
    useEffect(() => {
        if (showReload && waitingWorker && importantUpdate) {
            setShowRefresh(true);
        }
    }, [waitingWorker, showReload, reloadPage, importantUpdate]);

    return (
        <ProvideAuth>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={colourTheme}>
                    <ReactPWAInstallProvider enableLogging>
                        {content}
                        <InfoAlert
                            open={showRefresh}
                            onClose={() => setShowRefresh(false)}
                            text={t('infotext_refresh')}
                            refresh={true}
                            reloadPage={reloadPage}
                        />
                    </ReactPWAInstallProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </ProvideAuth>
    );
}

export default App;
