import React, { Component } from 'react';
import { Grid } from '@mui/material';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FormHelperText } from '@mui/material';
import { Link } from 'react-router-dom';

import { checkStudyId } from '../../services/users';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import { SurveyTextField } from '../../utils/commonComponents';

class userType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTypeError: false,
            participantIdError: false,
            value: props.value,
        };
        this.options = [
            { label: i18next.t('survey_select_usertypeoption_inselparticipant'), value: 'InselParticipant' },
            { label: i18next.t('survey_select_usertypeoption_dietician'), value: 'Dietician' },
            //It will be separate to experiment and control in VolunteerAdvise.js
            { label: i18next.t('survey_select_usertypeoption_volunteer'), value: 'Volunteer' },
        ];
    }

    // check for valid userType and dietician email
    // returns true if valid, false if not
    // async validateUserType() {
    //     let userType = this.props.values.userType;
    //     if (!validateSurveyFields('select', userType)) {
    //         this.setState({ userTypeError: true });
    //         return false;
    //     } else if (userType === 'Dietician' || userType === 'Volunteer' || userType === 'InselParticipant') {
    //         return true;
    //     }
    // }

    validateStudyId = async (e) => {
        //return the status code, 200: ok, 400: invalid study id, 409: used study id
        e.preventDefault();
        let userType = this.props.values.userType;
        if (userType !== 'InselParticipant') {
            return 400;
        }
        const studyId = this.props.values.studyId;
        if (studyId.length === 8 && /^[a-z0-9]+$/.test(studyId)) {
            try {
                const status_code = await checkStudyId('/user/check-study-id', studyId);
                return status_code;
            } catch (e) {
                return 500;
            }
        }
        return 400;
    };

    // function that returns only alphanumeric characters
    handleIdInput = (value) => {
        return value.replace(/[^a-z0-9]/g, '');
    };

    continue = async (e) => {
        if (!this.props.values.userType) {
            this.setState({ userTypeError: true });
            return;
        }
        e.preventDefault();
        if (this.props.values.userType === 'InselParticipant') {
            let validateStudyIdStatus = await this.validateStudyId(e);
            if (validateStudyIdStatus === 200) {
                this.props.nextStep(1);
            } else if (validateStudyIdStatus === 400) {
                alert(this.props.t('alert_invalid_study_id'));
            } else if (validateStudyIdStatus === 409) {
                alert(this.props.t('alert_used_study_id'));
            } else if (validateStudyIdStatus === 500) {
                alert(this.props.t('error_connection'));
            } else {
                alert(this.props.t('error_connection'));
            }
        } else {
            this.props.nextStep(1);
        }
    };

    handleTypeChange = (e) => {
        var userType = e.value;
        this.props.handleSurveyChange('userType', userType);
        if (userType === 'InselParticipant') {
            this.props.handleChange('controlGroup', false);
        }
    };

    render() {
        const { values, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_userType')}
                content={[
                    <Select
                        value={this.options.filter(({ value }) => value === values.userType)}
                        name="userType-selection"
                        placeholder={t('survey_select_label_userType')}
                        options={this.options}
                        onChange={this.handleTypeChange}
                        styles={{ menu: (base) => ({ ...base, zIndex: 100 }) }}
                    />,
                    <>
                        {this.state.userTypeError && <FormHelperText error>{t('form_error_selection')}</FormHelperText>}
                        {values.userType === 'InselParticipant' && (
                            <SurveyTextField
                                value={values.studyId}
                                id="studyId"
                                label={t('study_id')}
                                error={this.state.studyIdError}
                                helperText={this.state.studyIdError && t('enter_study_id_error')}
                                onChange={(e) =>
                                    this.props.handleSurveyChange('studyId', this.handleIdInput(e.target.value))
                                }
                                autoFocus={true}
                            />
                        )}
                    </>,
                    <Grid container justifyContent="flex-end">
                        <SurveyButtonGroup back={() => <Link to="/"></Link>} next={this.continue} />
                    </Grid>,
                ]}
            />
        );
    }
}

export default withTranslation()(userType);
