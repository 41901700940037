import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container, ThemeProvider } from '@mui/material';

import colourTheme from '../../styles/ColourTheme';
import LanguageHeader from '../Language/LanguageHeader';
import { ErrorAlert, InfoAlert } from '../Alert/Alerts';
import { linkWithUnderline } from '../../styles/ComponentStylings';
import validateAuthFields from '../../utils/formValidator';
import { useAuth } from '../../services/useAuth';
import { checkExists } from '../../services/users';
import { scraperRequest } from '../../services/scraper';

export default function SignIn() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem('user');
    }, []);
    let auth = useAuth();

    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
    });

    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [controlUserSigninError, setControlUserSigninError] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [emailNotRegistered, setEmailNotRegistered] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const [isUsedEmail, error] = await checkExists(`/user/check-if-email-used/${fields.email}`);
        if (!isUsedEmail | error) {
            setEmailNotRegistered(true);
            return false;
        }

        if (validateAuthFields('email', fields.email)) {
            try {
                await auth.signin(fields.email, fields.password);
            } catch (error) {
                if (error.message === "Users don't have login data.") {
                    setControlUserSigninError(true);
                    return false;
                } else if (error.message === 'Unauthorized') {
                    setOpenWarningDialog(true);
                    return false;
                } else if (error) {
                    setConnectionError(true);
                    return false;
                }
            }
            let user = JSON.parse(localStorage.getItem('user'));
            if (user && user.verified) {
                if (user.type === 'InselParticipant' || user.type === 'VolunteerExperiment') {
                    if (user.completed_registration === false) navigate('/complete-registration');
                    else if (user.bam_user === false) {
                        scraperRequest('POST', '/user/update-migros');
                        scraperRequest('POST', '/user/update-coop');
                        navigate('/user/dashboard?initial=true');
                    } else navigate('/user/dashboard');
                } else if (user.type === 'Dietician') {
                    navigate('/dietician/dashboard');
                }
            } else {
                auth.logout();
                navigate('/email-needs-verification');
            }
        } else {
            setOpenWarningDialog(true);
        }
    };

    return (
        <>
            <ThemeProvider theme={colourTheme}>
                <LanguageHeader />
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: '#99d5c5' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {t('signin')}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                type="email"
                                label="E-Mail"
                                name="email"
                                autoComplete="email"
                                value={fields.email}
                                onChange={handleFieldChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={t('form_password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={fields.password}
                                onChange={handleFieldChange}
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                {t('signin')}
                            </Button>
                            <Button
                                color="info"
                                fullWidth
                                variant="contained"
                                sx={{ mb: 2 }}
                                onClick={() => {
                                    navigate('/signup');
                                }}
                            >
                                {t('signin_text_signup')}
                            </Button>
                            <ErrorAlert
                                open={emailNotRegistered}
                                onClose={() => {
                                    setEmailNotRegistered(false);
                                }}
                                text={t('error_email_not_registered')}
                            />
                            <ErrorAlert
                                open={openWarningDialog}
                                onClose={() => {
                                    setOpenWarningDialog(false);
                                }}
                                text={t('alert_signinunauthorized')}
                            />
                            <ErrorAlert
                                open={connectionError}
                                onClose={() => {
                                    setConnectionError(false);
                                }}
                                text={t('error_connection')}
                            />
                            <InfoAlert
                                open={controlUserSigninError}
                                onClose={() => {
                                    setControlUserSigninError(false);
                                }}
                                text={t('alert_controlgroup_password_reset_error')}
                            />
                            <Container maxWidth="lg">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={12}>
                                        <Link to="/reset-password" style={linkWithUnderline}>
                                            {t('signin_text_forgotpassword')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}

/**
 * custom hook for common stateful logic of all form related components
 * @param initialState takes the initial state of form field as an object and saves it as a state variable called fields
 */
function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value,
            });
        },
    ];
}
