import React, { Component } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import LanguageMenu from './LanguageMenu';
import { withTranslation } from 'react-i18next';


export class SurveyLanguageHeader extends Component {
    render() {
        // const { t } = this.props;

        return (
            <>
                <AppBar position='absolute'>
                    <Toolbar>
                        <Typography
                            component='h1'
                            variant='h6'
                            color='inherit'
                            noWrap
                            align='center'
                            sx={{ flexGrow: 1 }}
                        >
                            {/* {t('page_title_survey')} */}
                        </Typography>
                        <LanguageMenu />
                    </Toolbar>
                </AppBar>
            </>
        )
    }
}

export default withTranslation()(SurveyLanguageHeader);
