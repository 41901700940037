import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { orange, green, lightGreen, amber, red } from '@mui/material/colors';

// Avatars for Nutri-Scores Display
export const nutrientScores = new Map([
    [
        'A',
        <Avatar sx={{ width: 24, height: 24, bgcolor: green[500] }} variant="rounded">
            A
        </Avatar>,
    ],
    [
        'B',
        <Avatar sx={{ width: 24, height: 24, bgcolor: lightGreen[500] }} variant="rounded">
            B
        </Avatar>,
    ],
    [
        'C',
        <Avatar sx={{ width: 24, height: 24, bgcolor: amber[500] }} variant="rounded">
            C
        </Avatar>,
    ],
    [
        'D',
        <Avatar sx={{ width: 24, height: 24, bgcolor: orange[500] }} variant="rounded">
            D
        </Avatar>,
    ],
    [
        'E',
        <Avatar sx={{ width: 24, height: 24, bgcolor: red[500] }} variant="rounded">
            E
        </Avatar>,
    ],
]);

export const nutrientScoreTitles = new Map([
    ['quantity', null],
    ['energyShare', null],
    ['energy', 'FSA Energy Score'],
    ['sugar', 'FSA Sugars Score'],
    ['saturated fat', 'FSA Saturated fat Score'],
    ['sodium', 'FSA Sodium Score'],
    ['fruits, Veg', 'FSA Fruits/Veg Score'],
    ['fiber', 'FSA Fiber Score'],
    ['protein', 'FSA Protein Score'],
]);

export const nutrientOfcomValues = new Map([
    ['energy', 'ofcom_n_energy_kj'],
    ['sugar', 'ofcom_n_sugars'],
    ['saturated fat', 'ofcom_n_saturated_fat'],
    ['sodium', 'ofcom_n_salt'],
    ['fruits, Veg', 'ofcom_p_fvpn'],
    ['fiber', 'ofcom_p_dietary_fiber'],
    ['protein', 'ofcom_p_protein'],
]);
