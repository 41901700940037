import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Container, Grid, Paper, Dialog, Slide, AppBar, Toolbar, IconButton } from '@mui/material';

import RecommendationsDetailsTable from './RecommendationsDetailsTable';
import RecommendationsProductsTable from './RecommendationsProductsTable';
import ProductsTable from './ProductsTable';
import { Title } from '../../utils/commonComponents';
import { nutrientScoreTitles } from '../../utils/NutrientScore';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductRecommendations(props) {
    const { t } = useTranslation();
    return (
        <Dialog fullScreen open={props.products} onClose={props.onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Title
                        sx={{ ml: 2, flex: 1 }}
                        title={
                            props.germanTranslation
                                ? props.productRecommendationsData.description_de
                                : props.productRecommendationsData.description_en
                        }
                    />
                </Toolbar>
            </AppBar>
            <Container>
                <Grid item sx={{ mt: 4 }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'grid',
                            flexDirection: 'column',
                            height: 'auto',
                        }}
                    >
                        <Title sx={{ flexGrow: 1, pl: 3 }} title={t('section_title_recommendationsproductdetails')} />
                        <Container sx={{ mt: 2 }}>
                            {/* TODO: values should be default values from backend */}
                            <RecommendationsDetailsTable
                                nutriscore="A"
                                fsascore={9}
                                nutrientScoreTitle={nutrientScoreTitles.get(props.nutrient)}
                                fsanutrientscore={8}
                            />
                        </Container>
                    </Paper>
                </Grid>
                <Grid item sx={{ mt: 4, justifyContent: 'center', display: 'flex' }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'grid',
                            flexDirection: 'column',
                            height: 'auto',
                        }}
                    >
                        <Title sx={{ flexGrow: 1, pl: 3 }} title={t('section_title_recommendationsproducts')} />
                        <Container sx={{ mt: 2 }}>
                            {props.products.better_products && (
                                <RecommendationsProductsTable
                                    products={props.products.better_products}
                                    nutrient={props.nutrient}
                                    handleSelectRecommendProducts={props.handleSelectRecommendProducts}
                                    onClose={props.onClose}
                                />
                            )}
                        </Container>
                    </Paper>
                </Grid>
                <Grid item sx={{ mt: 4, mb: 4 }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'grid',
                            flexDirection: 'column',
                            height: 'auto',
                        }}
                    >
                        <Title sx={{ flexGrow: 1, pl: 3 }} title={t('section_title_recommendationsoldproducts')} />
                        <Container sx={{ mt: 2 }}>
                            <ProductsTable history={props.products.category_history} />
                        </Container>
                    </Paper>
                </Grid>
            </Container>
        </Dialog>
    );
}
