import React, { useState } from 'react';
import { AppBar, Container, Toolbar, Typography, IconButton, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FoodBankIcon from '@mui/icons-material/FoodBank';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import { InfoAlert } from '../Alert/Alerts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReactPWAInstall } from 'react-pwa-install';
import myLogo from '../../assets/favicon.ico';

const TopAppBar = () => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    // set amount of notifications to be displayed in badge of notification icon
    // const [notifications, setNotifications] = useState(0);

    const [openNotificationsDialog, setOpenNotificationsDialog] = useState(false);
    const handleClose = () => {
        setOpenNotificationsDialog(false);
    };

    // const handleNotificationsClick = () => {
    //     if (notifications === 0) setOpenNotificationsDialog(true);
    // };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSettings = () => {
        setAnchorEl(null);
        navigate('/settings');
    };

    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const handleClick = () => {
        pwaInstall({
            title: t('install_dietcoach'),
            logo: myLogo,
            features: (
                <ul>
                    <li>{t('install_feature_1')}</li>
                    <li>{t('install_feature_2')}</li>
                    <li>{t('install_feature_3')}</li>
                </ul>
            ),
        });
    };

    return (
        <AppBar position="sticky">
            <Container>
                <Toolbar disableGutters>
                    <Button color="inherit" sx={{ mr: 1 }} href="/user/dashboard">
                        <FoodBankIcon sx={{ mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                fontWeight: 700,
                                textDecoration: 'none',
                                textTransform: 'none',
                            }}
                        >
                            DietCoach
                        </Typography>
                    </Button>
                    {supported() && !isInstalled() && (
                        <Button
                            variant="contained"
                            onClick={handleClick}
                            sx={{
                                bgcolor: 'green',
                                color: 'white',
                                ml: 'auto',
                                height: 50,
                                fontSize: 13,
                            }}
                        >
                            {t('install_app')}
                        </Button>
                    )}
                    {/* <IconButton color="inherit" sx={{ ml: 'auto', mr: 1 }} onClick={handleNotificationsClick}>
                        <Badge badgeContent={notifications} color="warning" />
                        <NotificationsIcon />
                    </IconButton> */}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open nav"
                        onClick={handleMenu}
                        sx={{
                            color: 'white',
                            ml: 'auto',
                            height: 50,
                            fontSize: 13,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        {/* <MenuItem onClick={handleCloseMenu}>FAQ</MenuItem>
                        <Divider /> */}
                        <MenuItem onClick={handleSettings}>{t('settings')}</MenuItem>
                    </Menu>
                    <InfoAlert
                        open={openNotificationsDialog}
                        onClose={handleClose}
                        text={t('alert_message_nonotifications')}
                    />
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default TopAppBar;
