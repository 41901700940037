import React, { useState } from 'react';
import {
    Typography,
    Button,
    Paper,
    Card,
    Grid,
    List,
    Divider,
    ListItem,
    ListItemText,
    CardContent,
    Accordion,
    IconButton,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import i18next from 'i18next';
import UndoIcon from '@mui/icons-material/Undo';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import useSWR, { mutate } from 'swr';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DashboardPage from '../DashboardPage';
import { getData, sendRejectRecommendation, undoRejectRecommendation } from '../../services/users';
import CircularLoading from '../../components/Loading/CircularLoading';
import BetterProducts from '../../components/Recommendations/BetterProducts';
import { ErrorAlert } from '../../components/Alert/Alerts';

export default function Recommendations() {
    const { t } = useTranslation();

    // fetch data
    const { data: recommendations, error: recommendationsError } = useSWR(
        '/user/recommendations-with-products',
        (url) => getData(url).then((data) => data.slice(0, 3)),
        { revalidateOnFocus: false }
    );
    const { data: rejections, error: rejectionsError } = useSWR('/get-rejected-recommendations', getData, {
        revalidateOnFocus: false,
    });

    const [displayRejectErrorAlert, setDisplayRejectErrorAlert] = useState(false);
    const [expanded, setExpanded] = React.useState([false, false, false]);

    const handleSendRejection = (recommendationToReject) => {
        setExpanded([false, false, false]);
        sendRejectRecommendation(recommendationToReject).then((success) => {
            if (!success) setDisplayRejectErrorAlert(true);
        });
        mutate('/user/recommendations-with-products');
        mutate('/get-rejected-recommendations');
    };

    const handleSendUndoRejection = (recommendationToUndoReject) => {
        setExpanded([false, false, false]);
        let recToUndoRejection = rejections[recommendationToUndoReject];
        undoRejectRecommendation(recToUndoRejection).then((success) => {
            if (!success) setDisplayRejectErrorAlert(true);
        });
        mutate('/user/recommendations-with-products');
        mutate('/get-rejected-recommendations');
    };

    var displayGermanRecommendationText = false;
    if (i18next.language === 'de' || i18next.language === 'de-CH') displayGermanRecommendationText = true;

    return (
        <DashboardPage
            content={
                recommendations || recommendationsError ? (
                    <Paper
                        sx={{
                            mt: 2,
                            mb: 9,
                            display: 'grid',
                            height: 'auto',
                        }}
                    >
                        <Typography variant="h5" noWrap align="center" sx={{ mt: 2, mb: 2 }}>
                            {t('recommendations')}
                        </Typography>
                        {recommendationsError ? (
                            <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                                <CardContent>
                                    <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                        {t('error_fetching', { data: t('error_fetching_recommendations') })}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : recommendations.length !== 0 ? (
                            <>
                                {recommendations
                                    .filter((rec) => {
                                        // filter if recommendation is not rejected
                                        return !rejections.some((rejectedRec) => {
                                            return (
                                                rejectedRec.recommendation_en === rec.recommendation.recommendation_en
                                            );
                                        });
                                    })
                                    .slice(0, 3)
                                    .map(
                                        (suggestion, index) =>
                                            Object.keys(suggestion).length !== 0 && (
                                                <React.Fragment key={index}>
                                                    <Accordion expanded={expanded[index]}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="recommendations-content"
                                                            sx={{ bgcolor: '#b3e5fc' }}
                                                            onClick={() => {
                                                                let newExpanded = [...expanded];
                                                                newExpanded[index] = !newExpanded[index];
                                                                setExpanded(newExpanded);
                                                            }}
                                                        >
                                                            {suggestion.recommendation.recommendation_en.includes(
                                                                'Increase'
                                                            ) ? (
                                                                <NorthIcon fontSize="large" color="success" />
                                                            ) : (
                                                                <SouthIcon fontSize="large" color="alert" />
                                                            )}
                                                            <Typography variant="button">
                                                                {displayGermanRecommendationText
                                                                    ? suggestion.recommendation.recommendation_de
                                                                    : suggestion.recommendation.recommendation_en}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails align={'center'}>
                                                            <Typography
                                                                sx={{
                                                                    bgcolor: '#97e8ad',
                                                                    padding: 1,
                                                                    flexDirection: 'column',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <InfoIcon fontSize="large" color="action" />
                                                                {displayGermanRecommendationText
                                                                    ? suggestion.recommendation.explanation_de
                                                                    : suggestion.recommendation.explanation_en}
                                                            </Typography>
                                                            <BetterProducts
                                                                products={suggestion.products}
                                                                suggestion={suggestion}
                                                            />
                                                            <Grid container justifyContent="center">
                                                                <Button
                                                                    variant="outlined"
                                                                    color="alert"
                                                                    startIcon={<DeleteForeverIcon />}
                                                                    onClick={() =>
                                                                        handleSendRejection(suggestion.recommendation)
                                                                    }
                                                                    sx={{ mt: 2 }}
                                                                >
                                                                    {t('reject_recommendation')}
                                                                </Button>
                                                            </Grid>
                                                        </AccordionDetails>
                                                        <ErrorAlert
                                                            open={displayRejectErrorAlert}
                                                            onClose={() => {
                                                                setDisplayRejectErrorAlert(false);
                                                            }}
                                                            text="There was an error with the recommendation rejection. Please try again."
                                                        />
                                                    </Accordion>
                                                </React.Fragment>
                                            )
                                    )}
                            </>
                        ) : (
                            <Card sx={{ bgcolor: '#17a2b8', m: 2 }}>
                                <CardContent>
                                    <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                        {t('infotext_accessingdata_recommendations')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}

                        {rejectionsError ? (
                            <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                                <CardContent>
                                    <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                        {t('error_fetching', { data: t('error_fetching_rejected_recommendations') })}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : (
                            rejections &&
                            rejections.length !== 0 && (
                                <>
                                    <Typography variant="h6" noWrap align="center" sx={{ mt: 4, mb: 2 }}>
                                        {t('rejected_recommendations')}
                                    </Typography>
                                    <List>
                                        {rejections.map((rejectedRecommendation, index) => (
                                            <React.Fragment>
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton
                                                            color="alert"
                                                            edge="end"
                                                            aria-label="undo-rejection"
                                                            onClick={() => handleSendUndoRejection(index)}
                                                        >
                                                            <UndoIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            displayGermanRecommendationText
                                                                ? rejectedRecommendation.recommendation_de
                                                                : rejectedRecommendation.recommendation_en
                                                        }
                                                    />
                                                </ListItem>
                                                <Divider component="li" />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </>
                            )
                        )}
                    </Paper>
                ) : (
                    <CircularLoading />
                )
            }
        />
    );
}
