import { Grid, List, Divider, ListItemText, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Title } from '../../utils/commonComponents';

export default function RejectedRecommendations({ rejectedRecommendations, rejectedRecommendationsError }) {
    const { t } = useTranslation();
    var displayGermanRecommendationText = false;
    if (i18next.language === 'de' || i18next.language === 'de-CH') displayGermanRecommendationText = true;

    if (rejectedRecommendationsError)
        return (
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'grid',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <Grid container ml={2.5}>
                        {t('error_fetching', {
                            data: t('error_fetching_rejected_recommendations'),
                        })}
                    </Grid>
                </Paper>
            </Grid>
        );
    return (
        <>
            <Grid item xs={12} mt={2}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'grid',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <Title sx={{ flexGrow: 1, pb: 2, pl: 2.5 }} title={t('rejected_recommendations')} />
                    <List>
                        {rejectedRecommendations.map((rejectedRecommendation, index) => (
                            <>
                                <ListItemText
                                    primary={
                                        displayGermanRecommendationText
                                            ? rejectedRecommendation.recommendation_de
                                            : rejectedRecommendation.recommendation_en
                                    }
                                />
                                <Divider component="li" />
                            </>
                        ))}
                    </List>
                </Paper>
            </Grid>
        </>
    );
}
