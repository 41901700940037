import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DateOfBirthQuestion from './DateOfBirthQuestion';
import LoyaltyCardsQuestion from './LoyaltyCardsQuestion';
import DemographicQuestions from './DemographicQuestions';
import CardsSharingQuestion from './CardsSharingQuestion';
import AllergiesQuestion from './AllergiesQuestion';
import ActivityLevelQuestion from './ActivityLevelQuestion';
import MeatQuestion from './MeatQuestion';
import EducationQuestion from './EducationQuestion';
import IncomeQuestion from './IncomeQuestion';
import SportsQuestion from './SportsQuestion';
import DisordersQuestion from './DisordersQuestion';
import ShoppingMigrosQuestion from './ShoppingMigrosQuestion';
import ShoppingCoopQuestion from './ShoppingCoopQuestion';
import ShoppingQuestion from './ShoppingQuestion';
import SelectDataCollectionMethod from './SelectDataCollectionMethod';
import NextSteps from './NextSteps';
import ConnectLoyaltyCards from './ConnectLoyaltyCards';
import DatafetchingStarted from './DatafetchingStarted';

import UserType from './UserType';
import UserSignUp from './UserSignUp';
import ControlGroupSignUp from './ControlGroupSignUp';
import SignUpLink from './SignUpLink';
import SurveySuccessPage from '../../pages/SurveySuccessPage';
import { registerDietician } from '../../services/dietician';
import { sendSignUpSurvey, sendRegistrationMin, sendCompleteRegistration } from '../../services/users';
import VolunteerAdvise from './VolunteerAdvise';
import DieticianRegister from './DieticianRegister';
import DieticianRegisterSuccessPage from '../../pages/DieticianRegisterSuccessPage';
import { scraperRequest } from '../../services/scraper';
import SurveyPage from '../../pages/SurveyPage';

export class MultiStepSignUpSurvey extends Component {
    constructor(props) {
        super(props);
        // delete user from localstorage if token is stored
        if (!this.props.completeRegistration) localStorage.removeItem('user');
        let controlGroup = Math.random() < 0.5;

        let step = 0;
        let loyaltyCards = '';
        // When a user has connected loyalty cards and is redirected to the survey
        if (this.props.completeRegistration) {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                if (user.type === 'VolunteerControl') controlGroup = true;
                else if (user.type === 'VolunteerExperiment' || user.type === 'InselParticipant') controlGroup = false;
            } else {
                // get JWT token from URL
                const jwtToken = new URLSearchParams(window.location.search).get('token');
                if (jwtToken) {
                    // get userType from JWT token
                    const decodedToken = jwtToken.split('.')[1];
                    const decoded = JSON.parse(window.atob(decodedToken));
                    if (decoded.userType === 'VolunteerControl') controlGroup = true;
                    else if (decoded.userType === 'VolunteerExperiment' || user.type === 'InselParticipant') controlGroup = false;
                }
            }
            step = 7.5;
            // too much effort to get the loyalty cards from the user and we have the data anyway
            loyaltyCards = 'both';
        }
        this.state = {
            step: step,
            controlGroup: controlGroup,
            dataFetchingMethod: 'HSG', // only method at the moment
            openMigrosDialog: false,
            openCoopDialog: false,
            credentials: {
                email: '',
                password: '', //create a default value for control users
                passwordConfirm: '',
                migrosEmail: '',
                migrosPassword: '',
                coopEmail: '',
                coopPassword: '',
            },
            dieticianInfo: {
                firstName: '',
                lastName: '',
            },
            survey: {
                studyId: '',
                userType: '',
                birthdate: new Date(),
                loyaltyCards: loyaltyCards,
                height: '',
                weight: '',
                bmi: 0,
                gender: '',
                percShoppingMigros: '',
                percShoppingCoop: '',
                usageMigros: '',
                usageCoop: '',
                loyaltyShareAdults: '',
                loyaltyShareKids: '',
                loyaltyShareTeens: '',
                meat: '',
                allergiesAndAbstentions: { allergies: [], abstentions: [] },
                disorders: [],
                otherDiseases: '',
                activityLevelAtWork: '',
                sports: '',
                education: '',
                householdIncome: '',
            },
        };
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    };

    handleSurveyChange = (key, value) => {
        // create a copy of state and change the value of key
        const updateState = { ...this.state.survey, [key]: value };
        this.setState({ survey: updateState });
    };

    handleDieticianInfoChange = (key, value) => {
        // create a copy of state and change the value of key
        this.setState((prevState) => ({ ...prevState, dieticianInfo: { ...prevState.dieticianInfo, [key]: value } }));
    };

    handleAuthChange = (keysValues) => {
        const updateState = { ...this.state.credentials, ...keysValues };
        let loyaltyCards = '';
        if (updateState.migrosEmail && !updateState.coopEmail) {
            loyaltyCards = 'migros';
        } else if (!updateState.migrosEmail && updateState.coopEmail) {
            loyaltyCards = 'coop';
        } else if (updateState.migrosEmail && updateState.coopEmail) {
            loyaltyCards = 'both';
        }
        this.setState({
            credentials: updateState,
            survey: {
                ...this.state.survey,
                loyaltyCards: loyaltyCards,
            },
        });
    };

    submitRegistrationMin = async () => {
        await sendRegistrationMin(this.state.credentials, this.state.survey, this.state.survey.loyaltyCards).then(
            (statusOrToken) => {
                if (statusOrToken.toString().length > 3) {
                    localStorage.setItem('user', JSON.stringify({ token: statusOrToken }).replace(/\\"/g, ''));
                    this.setState({ step: this.state.step + 1 });
                } else if (statusOrToken === 409) {
                    alert(this.props.t('alert_email_registered'));
                } else alert(this.props.t('something_went_wrong'));
            }
        );
    };

    submitCompleteRegistration = () => {
        const data = { ...this.state };

        if (data.survey.userType !== 'InselParticipant') {
            delete data.survey.studyId;
        }

        // delete data that should not be sent to backend
        delete data.controlGroup;
        delete data.credentials.passwordConfirm;

        // // add -1 values for missing survey data
        ['percShoppingMigros', 'percShoppingCoop', 'usageMigros', 'usageCoop'].forEach((e) => {
            if (data.survey[e] === '') data.survey[e] = -1;
        });

        const jwtToken = new URLSearchParams(window.location.search).get('token');
        sendCompleteRegistration(this.state.survey, jwtToken).then((res) => {
            if (res === true) {
                this.setState({ step: this.state.step + 3 });
            } else alert(this.props.t('something_went_wrong'));
        });
    };

    // submit signup / survey data to backend
    submitData = () => {
        const data = { ...this.state };
        if (data.survey.userType === 'Dietician') {
            try {
                registerDietician(data).then((status) => {
                    if (status === 201) {
                        this.setState({ step: 21 });
                    } else if (status === 409) {
                        alert(this.props.t('error_registered_dietician_email'));
                    } else if (status === 400) {
                        alert(this.props.t('error_invalid_dietician_email'));
                    } else {
                        alert(this.props.t('error_connection'));
                    }
                });
            } catch (error) {
                alert(this.props.t('something_went_wrong'));
            }
        } else {
            // delete data that should not be sent to backend
            delete data['controlGroup'];
            delete data.credentials['passwordConfirm'];

            // // add -1 values for missing survey data
            ['percShoppingMigros', 'percShoppingCoop', 'usageMigros', 'usageCoop'].forEach((e) => {
                if (data.survey[e] === '') data.survey[e] = -1;
            });

            sendSignUpSurvey(data).then((user) => {
                if (user.token) {
                    localStorage.setItem('user', JSON.stringify(user));
                    this.setState({ step: this.state.step + 1 });
                } else {
                    // TODO: Error handling
                    alert(this.props.t('alert_message_error_sending_survey'));
                }
            });
        }
    };

    // handle number inputs in the survey
    handleNumberInput = (value) => {
        if (isNaN(parseInt(value))) return '';
        return parseInt(value);
    };

    render() {
        const { controlGroup } = this.state;
        // const { t } = this.props;
        const values = this.state.survey;
        const authValues = this.state.credentials;

        // Each step defines the survey page that should be displayed
        switch (this.state.step) {
            // case 0:
            //     return <WelcomePage t={t} nextStep={() => this.setState({ step: this.state.step + 1 })} />;
            case 0:
                return (
                    <UserType
                        nextStep={() => {
                            if (values.userType === 'InselParticipant') this.setState({ step: this.state.step + 2 });
                            else this.setState({ step: this.state.step + 1 });
                        }}
                        prevStep={() => <Link to="/"></Link>}
                        handleChange={this.handleChange}
                        handleSurveyChange={this.handleSurveyChange}
                        values={values}
                        controlGroup={controlGroup}
                    />
                );
            case 1:
                if (this.state.survey.userType === 'InselParticipant') {
                    this.setState({ step: this.state.step + 1 });
                } else if (this.state.survey.userType === 'Dietician') {
                    return (
                        <DieticianRegister
                            submitData={this.submitData}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleAuthChange={this.handleAuthChange}
                            handleDieticianInfoChange={this.handleDieticianInfoChange}
                            data={this.state}
                        />
                    );
                } else if (
                    this.state.survey.userType === 'Volunteer' || this.state.survey.userType === 'VolunteerControl' || this.state.survey.userType === 'VolunteerExperiment'
                ) {
                    return (
                        <VolunteerAdvise
                            handleChange = {this.handleSurveyChange}
                            controlGroup={controlGroup}
                            values={values}
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                        />
                    );
                }
                break;
            case 2:
                return (
                    <DateOfBirthQuestion
                        // change this to integrate BAM again
                        // nextStep={() => this.setState({ step: this.state.step + 1 })}
                        nextStep={() => this.setState({ step: this.state.step + 2 })}
                        prevStep={() => {
                            if (values.userType === 'InselParticipant') this.setState({ step: this.state.step - 2 });
                            else this.setState({ step: this.state.step - 1 });
                        }}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        datafetchingMethod={this.state.datafetchingMethod}
                        completeRegistration={false}
                    />
                );
            case 3:
                // Deprecated, we only use HSG data collection method
                return (
                    <SelectDataCollectionMethod
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleChange}
                    />
                );
            case 4:
                return (
                    <NextSteps
                        nextStep={(steps) => this.setState({ step: this.state.step + steps })}
                        // change this to integrate BAM again
                        // prevStep={() => this.setState({ step: this.state.step - 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 2 })}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                    />
                );
            case 5:
                // this is only for HSG data collection method
                return (
                    <ConnectLoyaltyCards
                        nextStep={async () => {
                            await this.submitRegistrationMin();
                            scraperRequest('POST', '/user/reset');
                        }}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleAuthChange}
                        authValues={authValues}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                        controlGroup={this.state.controlGroup}
                    />
                );
            case 6:
                // this is only for HSG data collection method
                return <DatafetchingStarted nextStep={() => this.setState({ step: this.state.step + 2 })} />;
            case 7:
                return (
                    <LoyaltyCardsQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 3 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            // Only when participants finished connecting loyalty cards but not the onboarding survey
            case 7.5:
                return (
                    <DateOfBirthQuestion
                        nextStep={() => this.setState({ step: 9 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        datafetchingMethod={this.state.datafetchingMethod}
                        completeRegistration={true}
                    />
                );
            case 8:
                return (
                    <DemographicQuestions
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        handleNumberInput={this.handleNumberInput}
                        values={values}
                    />
                );
            case 9:
                if (this.state.survey.loyaltyCards === 'both') {
                    return (
                        <ShoppingQuestion
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleSurveyChange}
                            handleNumberInput={this.handleNumberInput}
                            values={values}
                        />
                    );
                } else if (this.state.survey.loyaltyCards === 'migros') {
                    return (
                        <ShoppingMigrosQuestion
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleSurveyChange}
                            handleNumberInput={this.handleNumberInput}
                            values={values}
                        />
                    );
                } else if (this.state.survey.loyaltyCards === 'coop') {
                    return (
                        <ShoppingCoopQuestion
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleSurveyChange}
                            handleNumberInput={this.handleNumberInput}
                            values={values}
                        />
                    );
                } else {
                    alert(this.props.t('something_went_wrong'));
                }
                break;
            case 10:
                return (
                    <CardsSharingQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        handleNumberInput={this.handleNumberInput}
                        values={values}
                    />
                );
            case 11:
                return (
                    <MeatQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 12:
                return (
                    <AllergiesQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 13:
                return (
                    <ActivityLevelQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 14:
                return (
                    <SportsQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 15:
                return (
                    <DisordersQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 16:
                return (
                    <EducationQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 17:
                return (
                    <IncomeQuestion
                        submitData={this.submitData}
                        nextStep={() => {
                            if (this.state.dataFetchingMethod === 'HSG') {
                                this.submitCompleteRegistration();
                            } else {
                                this.setState({ step: this.state.step + 1 });
                            }
                        }}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                    />
                );
            case 18: {
                if (this.state.survey.userType === 'VolunteerControl') {
                    return (
                        <ControlGroupSignUp
                            nextStep={this.submitData}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleAuthChange}
                            values={authValues}
                        />
                    );
                } else {
                    return (
                        <UserSignUp
                            nextStep={this.submitData}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleAuthChange}
                            values={authValues}
                        />
                    );
                }
            }
            case 19:
                return (
                    <SignUpLink
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        controlGroup={controlGroup}
                        values={values}
                    />
                );
            case 20:
                return <SurveySuccessPage controlGroup={controlGroup} userType={this.state.survey.userType} />;
            case 21:
                return <DieticianRegisterSuccessPage />;
            default:
                return <SurveyPage />;
        }
    }
}

export default withTranslation()(MultiStepSignUpSurvey);
