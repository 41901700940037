import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, TextField, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { FeatureNotImplementedAlert } from '../Alert/Alerts';


export default function RecommendationsDetailsTable(props) {
    const { t } = useTranslation();
    const [nutrientScoreTitle] = React.useState(props.nutrientScoreTitle);

    const [nutriscore, setNutriscore] = React.useState(props.nutriscore);
    const [fsascore, setFsascore] = React.useState(props.fsascore);
    const [fsanutrientscore, setFsanutrientscore] = React.useState(props.fsanutrientscore);

    const handleNutriscoreChange = (e) => {
        setNutriscore(e.target.value);
    };

    const handleFsascoreChange = (e) => {
        setFsascore(e.target.value);
    }

    const handleFsaNutrientscoreChange = (e) => {
        setFsanutrientscore(e.target.value);
    }

    const [openWarningDialog, setOpenWarningDialog] = useState(false);

    const handleClick = () => {
        setOpenWarningDialog(true);
    }
    const handleClose = () => {
        setOpenWarningDialog(false);
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Nutri-Score</TableCell>
                            <TableCell align='center'>FSA Score</TableCell>
                            {nutrientScoreTitle && <TableCell align='center'>{nutrientScoreTitle || 'FSA Score'}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            key={nutriscore}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align='center'>
                                <Select
                                    value={nutriscore}
                                    onChange={handleNutriscoreChange}
                                    styles={{ menu: base => ({ ...base, zIndex: 100 }) }}
                                >
                                    <MenuItem value='A'>A</MenuItem>
                                    <MenuItem value='B'>B</MenuItem>
                                    <MenuItem value='C'>C</MenuItem>
                                    <MenuItem value='D'>D</MenuItem>
                                    <MenuItem value='E'>E</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell align='center'>
                                <TextField
                                    type='number'
                                    value={fsascore}
                                    InputProps={{ inputProps: { min: -15, max: 40 } }}
                                    onChange={handleFsascoreChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </TableCell>
                            {nutrientScoreTitle &&
                                <TableCell align='center'>
                                    <TextField
                                        type='number'
                                        value={fsanutrientscore}
                                        InputProps={{ inputProps: { min: 0, max: 5 } }}
                                        onChange={handleFsaNutrientscoreChange}
                                    />
                                </TableCell>
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                m={1}
                display='flex'
                justifyContent='flex-end'
                alignItems='flex-end'
            >
                <Button variant='contained'
                    onClick={handleClick}
                >
                    {t('button_filterproducts')}
                </Button>
                <FeatureNotImplementedAlert
                    open={openWarningDialog}
                    onClose={handleClose}
                />
            </Box>
        </div>
    );
}