import { configData } from '../config.js';
import {
    getPostRequestOptions,
    getRequestOptions,
    getNoUserRequestOptions,
    getNoUserPostRequestOptions,
    handlePostResponse,
    handleResponse,
    handleResponseObject,
    getShoppingAnalysisRequestOptions,
} from './utils.js';

export function getData(url, userid = null, startTimestamp = null, endTimestamp = null, category = null) {
    const requestOptions = getRequestOptions(userid, startTimestamp, endTimestamp, category);
    return fetch(`${configData.API_URL}${url}`, requestOptions).then(handleResponse);
}

export function checkExists(url, userid = null) {
    const requestOptions = getNoUserRequestOptions(userid);
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponse)
        .then((data) => [data, null])
        .catch(() => [null, 'connection error']);
}

export function checkStudyId(url, studyId = null) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Study-Id': studyId,
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
    };

    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then((response) => response.status)
        .catch((e) => {
            console.log(e);
            return 500;
        });
}

export function getShoppingAnalysisData(url, userid, analysisType) {
    const requestOptions = getShoppingAnalysisRequestOptions(userid, analysisType);
    return fetch(`${configData.API_URL}${url}`, requestOptions)
        .then(handleResponseObject)
        .then((data) => {
            if (Object.keys(data).length !== 0) {
                return Object.values(data).map((value) =>
                    createAnalysisData(value.month_timestamp, value.total_value, value.category_analysis, analysisType)
                );
            }
            return {};
        });
}

export function sendRejectRecommendation(recommendationToReject) {
    let recBody = {
        nutrient: recommendationToReject.nutrient,
        dietcoach_minor_category: recommendationToReject.dietcoach_minor_category,
        recommendation_en: recommendationToReject.recommendation_en,
        recommendation_de: recommendationToReject.recommendation_de,
    };
    const requestOptions = getPostRequestOptions(recBody);
    return (
        fetch(`${configData.API_URL}/insert-rejected-recommendations`, requestOptions)
            // .then(handleRejectRecommendationResponse)
            .then(handlePostResponse)
    );
}

export function undoRejectRecommendation(rejectedRecommendationToDelete) {
    let recBody = {
        nutrient: rejectedRecommendationToDelete.nutrient,
        dietcoach_minor_category: rejectedRecommendationToDelete.dietcoach_minor_category,
        recommendation_en: rejectedRecommendationToDelete.recommendation_en,
        recommendation_de: rejectedRecommendationToDelete.recommendation_de,
    };
    const requestOptions = getPostRequestOptions(recBody);
    return fetch(`${configData.API_URL}/delete-rejected-recommendations`, requestOptions).then(handlePostResponse);
}

export function createAnalysisData(unixDate, totalCost, analysis, type) {
    let date = new Date(unixDate * 1000);
    let total = totalCost;

    const analysisData = Object.values(analysis).map((value) => {
        return {
            name: value.category_name,
            secondCol: value.category_total_value,
            percent: value.category_percentage,
        };
    });
    return { date, total, analysisData };
}

export function sendRegistrationMin(credentials, survey, connectedLoyaltyCard) {
    //keep password so that we generate user tokens, remove other empty fields
    for (var key in credentials) {
        if (key !== 'password' && credentials.hasOwnProperty(key)) {
            var value = credentials[key];
            if (value === '') {
                delete credentials[key];
            }
        }
    }

    //prepare request body
    const requestBody = {
        userType: survey.userType,
        ...(survey.studyId.length > 0 && { studyId: survey.studyId }),
        connectedLoyaltyCard: connectedLoyaltyCard,
        ...credentials,
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    };

    return fetch(`${configData.API_URL}/user/register-minimal`, requestOptions)
        .then((res) => {
            if (res.status === 201) {
                return res.text().then((token) => {
                    return token;
                });
            } else {
                return res.status;
            }
        })
        .catch((e) => {
            console.log(e);
            return 500;
        });
}

export function sendCompleteRegistration(data, jwtToken) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'PUT',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
            ...(user && { Authentication: user.token }),
            jwt: jwtToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(`${configData.API_URL}/user/complete-registration`, requestOptions)
        .then((res) => {
            if (res.ok) {
                return true;
            } else return false;
        })
        .catch(() => {
            return false;
        });
}

// returns received user data or error if invalid credentials
export function sendSignUpSurvey(surveyData) {
    const requestOptions = getNoUserPostRequestOptions(surveyData);
    return fetch(`${configData.API_URL}/user/register`, requestOptions)
        .then(handleResponse)
        .catch(() => 'error');
}

export const sendResetPasswordMail = async (email) => {
    try {
        const requestOptions = getNoUserPostRequestOptions({ email: email });
        return await fetch(`${configData.API_URL}/user/pwreset`, requestOptions).then((r) =>
            r.text().then((data) => ({ status: r.status, body: data }))
        );
    } catch (error) {
        return null;
    }
};

export const sendChangePasswordRequest = async (token, password) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token, password: password }),
    };
    return await fetch(`${configData.API_URL}/user/update-password`, requestOptions)
        .then((r) => r.status)
        .catch(() => {
            return null;
        });
};
