import * as React from 'react';
import './style.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// plugin that handles chart styling for nutrigraph for mobile view
const pluginView = [
    {
        beforeDraw: function (chart) {
            var ctx = chart.ctx;
            var yaxis = chart.scales['y'];
            var xaxis = chart.scales['x'];

            const colors = ['green', 'yellowgreen', 'gold', 'darkorange', 'red'];
            var height = yaxis.height / 5;
            var width = xaxis.width;
            var x = xaxis.left;
            var y = yaxis.top;

            const scores = ['A', 'B', 'C', 'D', 'E'];
            var fontSize = (height / 30).toFixed(2);
            ctx.font = fontSize + 'em Verdana';
            ctx.textBaseline = 'middle';
            var textX = x + 5;
            var textY = y + height / 2;

            for (var i = 0; i < 5; i++) {
                ctx.fillStyle = colors[i];
                ctx.fillRect(x, y, width, height);
                y = y + height;

                ctx.fillStyle = 'white';
                ctx.fillText(scores[i], textX, textY);
                textY = y + height / 2;
            }
        },
    },
];

const NutrientGraph = (props) => {
    const { t } = useTranslation();
    const options = {
        responsive: true,
        maintainAspectRatio: false, // for resizing on mobile view
        scales: {
            y: {
                title: {
                    text: 'Nutri-Score',
                    display: false,
                },
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1,
                    display: false, // do not display yaxis values
                },
            },
            x: {
                title: {
                    text: t('date'),
                    display: true,
                },
                grid: {
                    display: false, // do not display vertical grid lines
                },
                offset: true, // add extra space to both sides of chart area
            },
        },
        plugins: {
            legend: {
                // do not display dataset label
                display: false,
            },
        },
    };

    return (
        <div id="canvas-container">
            <Line options={options} data={props.data} plugins={pluginView} />
        </div>
    );
};

export default NutrientGraph;
