import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import i18next from 'i18next';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Title } from '../../utils/commonComponents';
import {
    getAbstentionsValue,
    getAllergiesValue,
    activityLevelAtWork_list,
    getDiseasesValue,
    educations_list,
} from '../../utils/ListOfThings';

export default function PatientInformation({ surveyData, surveyDataError }) {
    const { t } = useTranslation();
    // get and load survey data for patient information table

    if (surveyData) {
        surveyData = formatSurveyData(surveyData);
    }

    if (surveyDataError) {
        return (
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'grid',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <Grid container ml={2.5}>
                        {t('error_fetching', { data: t('error_fetching_profile') })}
                    </Grid>
                </Paper>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Paper
                sx={{
                    p: 2,
                    display: 'grid',
                    flexDirection: 'column',
                    height: 'auto',
                }}
            >
                <Title sx={{ flexGrow: 1, pb: 2, pl: 2.5 }} title={t('section_title_aboutparticipant')} />
                {surveyData && (
                    <Grid container spacing={2}>
                        <Grid item ml={2} lg={5}>
                            <PatientInformationTable rows={surveyData.slice(2, 15)} />
                        </Grid>
                        <Grid item ml={2} lg={5}>
                            <PatientInformationTable rows={surveyData.slice(15, 31)} />
                        </Grid>
                    </Grid>
                )}
                {/* {surveyDataError && (
                    <Grid container ml={2.5}>
                        {t('error_fetching', { data: t('error_fetching_profile') })}
                    </Grid>
                )} */}
            </Paper>
        </Grid>
    );
}

const PatientInformationTable = ({ rows }) => {
    const { t } = useTranslation();
    return (
        <TableContainer component={Paper}>
            <Table
                className="ParticipantInformation-table"
                sx={{ minWidth: 'auto', maxWidth: 'auto' }}
                aria-label="simple table"
            >
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.title}>
                            <TableCell component="th" scope="row" padding="normal" sx={{ fontWeight: 'bold' }}>
                                {t(row.title)}
                            </TableCell>
                            {/* No is for 'false' data values as it otherwise displays nothing */}
                            <TableCell align="left">
                                {!row.data || row.data.length === 0 ? t('no') : row.data}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export function formatSurveyData(receivedData) {
    // parse all received data from backend and give them title and data props for the table
    const surveyData = Object.entries(receivedData).map(([key, value]) => createData(key, value));

    // need to handle allergies and abstentions differently as they are dict items
    const allergies = createData(i18next.t('allergies'), getAllergiesValue(receivedData));
    const abstentions = createData(i18next.t('abstentions'), getAbstentionsValue(receivedData));
    const disorders = createData(i18next.t('disorders'), getDiseasesValue(receivedData));
    // delete the allergiesAndAbstentions item in the surveyData and replace with above items
    surveyData.forEach(function (e, index) {
        if (e.title === 'allergiesAndAbstentions' || e.title === 'disorders' || e.title === 'userType') {
            delete surveyData[index];
        } else if (e.data === null || e.data === '' || e.data === undefined) {
            delete surveyData[index];
        } else if (e.title === 'activityLevelAtWork') {
            e.data = i18next.t(activityLevelAtWork_list[e.data]);
        } else if (e.title === 'education') {
            e.data = i18next.t(educations_list[e.data]);
        }
    });
    surveyData.push(allergies);
    surveyData.push(abstentions);
    surveyData.push(disorders);
    return surveyData;
}

function createData(title, data) {
    if (data === -1) return { title, data: '' };
    if (title === 'birthdate') return createData(i18next.t('birthyear'), new Date(data).getFullYear());
    if (isNaN(data)) return { title, data: i18next.t(data) };
    return { title, data };
}
