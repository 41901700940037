import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Box, Container, Paper, IconButton } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { AutoResizer } from 'react-base-table';
import CheckIcon from '@mui/icons-material/Check';
import useSWR, { mutate } from 'swr';
import i18next from 'i18next';

import DraggableTable from '../Tables/DraggableTable';
import { Title } from '../../utils/commonComponents';
import { paperStyling } from '../../styles/ComponentStylings';
import ProductRecommendations from './ProductRecommendations';
import { SuccessAlert, InfoAlert, ErrorAlert } from '../Alert/Alerts';
import { getData, saveRecommendations } from '../../services/dietician';

export default function CreateSuggestions({
    suggestions,
    userid,
    startDate,
    endDate,
    removeSuggestion,
    updateSuggestions,
}) {
    const { t } = useTranslation();
    const [germanTranslation, setGermanTranslation] = useState(false);
    useEffect(() => {
        if (i18next.language === 'de' || i18next.language === 'de-CH') {
            setGermanTranslation(true);
        } else setGermanTranslation(false);
    }, []);

    const [revalidateBetterProducts, setRevalidateBetterProducts] = useState(false);

    const [productRecommendationsData, setProductRecommendationsData] = useState({
        priority: null,
        nutrient: null,
        description_en: null,
        description_de: null,
        category: null,
    });

    const {
        data: betterProducts,
        error: betterProductsError,
        isValidating,
    } = useSWR(
        revalidateBetterProducts
            ? ['/dietician/category-history-and-recommendations', productRecommendationsData.category]
            : null,
        (url) => {
            return getData(
                url,
                userid,
                Math.round(startDate.getTime() / 1000),
                Math.round(endDate.getTime() / 1000),
                productRecommendationsData.category
            );
        },
        { revalidateOnFocus: false }
    );

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openBetterProductsFetchError, setOpenBetterProductsFetchError] = useState(false);
    const [openPushSuggestions, setOpenPushSuggestions] = useState(false);
    const [openWarningTooFewSuggestions, setOpenWarningTooFewSuggestions] = useState(false);
    const [openWarningTooFewProducts, setOpenWarningTooFewProducts] = useState(false);
    const [openWarningUnsuccessfulDataTransmission, setOpenWarningUnsuccessfulDataTransmission] = useState(false);
    const [openWarningSuggestionsLimitReached, setOpenWarningSuggestionsLimitReached] = useState(false);

    useEffect(() => {
        if (betterProductsError && !isValidating) {
            setOpenBetterProductsFetchError(true);
        }
    }, [betterProductsError, isValidating]);

    const handleOpenRecommendations = (rowData) => {
        setProductRecommendationsData({
            priority: rowData.recommendation.id - 1,
            nutrient: rowData.recommendation.nutrient,
            description_en: rowData.recommendation.description_en,
            description_de: rowData.recommendation.description_de,
            category: rowData.recommendation.minor_category,
        });
        setRevalidateBetterProducts(true);
    };

    const handleClickSendSuggestions = (push) => {
        // if less than 5, suggestions cannot be saved
        let openWarningSuggestions = false;
        let openWarningProducts = false;
        if (suggestions.length < 5) {
            openWarningSuggestions = true;
        }
        suggestions.forEach((sug) => {
            if (sug.products === undefined) {
                openWarningProducts = true;
            } else if (sug.products.length !== 5) {
                openWarningProducts = true;
            }
        });
        if (openWarningSuggestions) {
            setOpenWarningTooFewSuggestions(true);
            return;
        } else if (openWarningProducts) {
            setOpenWarningTooFewProducts(true);
            return;
        }
        const suggestionsData = formatSuggestions(suggestions, startDate, endDate, push);
        saveRecommendations(suggestionsData, userid).then((response) => {
            if (response) setOpenSuccess(true);
            else setOpenWarningUnsuccessfulDataTransmission(true);
        });
    };

    const formatSuggestions = (suggestions, startDate, endDate, push) => {
        const formatted_suggestions = suggestions.map((row) => {
            return {
                recommendation: {
                    nutrient: row.recommendation.nutrient,
                    dietcoach_minor_category: row.recommendation.minor_category,
                    recommendation_en: row.recommendation.description_en,
                    recommendation_de: row.recommendation.description_de,
                },
                products: row.products,
            };
        });

        return {
            purchases_start: startDate,
            purchases_end: endDate,
            push: push,
            recommendations: formatted_suggestions,
        };
    };

    const handleSelectRecommendProducts = (products) => {
        let sug = [...suggestions];
        let updatedSuggestion = { ...sug[productRecommendationsData.priority] };
        updatedSuggestion.products = products;
        sug[productRecommendationsData.priority] = updatedSuggestion;
        updateSuggestions(sug);
        setRevalidateBetterProducts(false);
    };

    const suggestionsTableColumns = [
        {
            key: 'col0',
            dataKey: 'priority',
            title: 'Priority',
            width: '10%',
            cellRenderer: ({ rowIndex }) => <div style={{ marginLeft: 10 }}>{rowIndex + 1}</div>,
        },
        {
            key: 'col1',
            dataKey: germanTranslation ? 'recommendation.description_de' : 'recommendation.description_en',
            title: 'Description',
            width: '55%',
        },
        {
            key: 'col2',
            dataKey: 'products selected',
            title: 'Products Selected',
            width: '10%',
            cellRenderer: ({ rowData }) => {
                if (rowData.products?.length === 5) {
                    return (
                        <CheckIcon
                            color={
                                Number(rowData.recommendation.id) === 1 ||
                                Number(rowData.recommendation.id) === 2 ||
                                Number(rowData.recommendation.id) === 3
                                    ? 'secondary'
                                    : 'primary'
                            }
                        />
                    );
                }
            },
        },
        {
            key: 'col3',
            dataKey: 'recommendations',
            title: 'Recommendations',
            width: '20%',
            cellRenderer: ({ rowData }) => (
                <Button
                    variant="contained"
                    color={
                        Number(rowData.recommendation.id) === 1 ||
                        Number(rowData.recommendation.id) === 2 ||
                        Number(rowData.recommendation.id) === 3
                            ? 'secondary'
                            : 'primary'
                    }
                    onClick={() => handleOpenRecommendations(rowData)}
                >
                    {t('button_product_recommendations')}
                </Button>
            ),
        },
        {
            key: 'col4',
            dataKey: 'delete',
            title: 'Delete',
            width: '5%',
            cellRenderer: ({ rowData }) => (
                <IconButton
                    edge="start"
                    color={
                        Number(rowData.recommendation.id) === 1 ||
                        Number(rowData.recommendation.id) === 2 ||
                        Number(rowData.recommendation.id) === 3
                            ? 'secondary'
                            : 'primary'
                    }
                    onClick={() => removeSuggestion(rowData.recommendation.id)}
                >
                    <BackspaceIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <>
            <Paper sx={(paperStyling, { mt: 2 })}>
                <Title sx={{ flexGrow: 1, pl: 2.5 }} title={t('paper_title_create_recommendations')} />
                <Container sx={{ mt: 2 }}>
                    <AutoResizer height={250}>
                        {({ width, height }) => (
                            <DraggableTable
                                suggestions={suggestions}
                                width={width}
                                height={height}
                                columns={suggestionsTableColumns}
                                headerHeight={0} // hide header
                                highlight={true}
                                updateSuggestions={updateSuggestions}
                            />
                        )}
                    </AutoResizer>
                </Container>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 2,
                    }}
                >
                    <Button color="primary" variant="contained" onClick={() => handleClickSendSuggestions(false)}>
                        {t('button_save')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ ml: '10px' }}
                        onClick={() => setOpenPushSuggestions(true)}
                    >
                        {t('button_send')}
                    </Button>
                </Box>
            </Paper>
            {betterProducts && !betterProductsError && (
                <ProductRecommendations
                    products={betterProducts}
                    germanTranslation={germanTranslation}
                    productRecommendationsData={productRecommendationsData}
                    onClose={() => setRevalidateBetterProducts(false)}
                    handleSelectRecommendProducts={handleSelectRecommendProducts}
                />
            )}

            <InfoAlert
                open={openPushSuggestions}
                onClose={() => setOpenPushSuggestions(false)}
                text={t('alert_message_send3recommendations')}
                content={
                    <Container
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: '10px',
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setOpenPushSuggestions(false);
                                handleClickSendSuggestions(true);
                            }}
                        >
                            {t('yes')}
                        </Button>
                        <Button
                            color="alert"
                            variant="contained"
                            sx={{ ml: '10px' }}
                            onClick={() => setOpenPushSuggestions(false)}
                        >
                            {t('no')}
                        </Button>
                    </Container>
                }
            />
            <SuccessAlert
                open={openSuccess}
                onClose={() => {
                    setOpenSuccess(false);
                    updateSuggestions([]);
                    mutate(['/dietician/recommendations-with-products', userid]);
                }}
                text={t('alert_successfuldatatransmission')}
            />
            <ErrorAlert
                open={openWarningTooFewSuggestions}
                onClose={() => setOpenWarningTooFewSuggestions(false)}
                text={t('warning_toofewsuggestionssave')}
            />
            <ErrorAlert
                open={openWarningTooFewProducts}
                onClose={() => setOpenWarningTooFewProducts(false)}
                text={t('warning_toofewproductssave')}
            />
            {/* Warning Alert for reaching 5 suggestions and trying to add more */}
            <ErrorAlert
                open={openWarningSuggestionsLimitReached}
                onClose={() => setOpenWarningSuggestionsLimitReached(false)}
                text={t('warning_suggestionslimitreached')}
            />

            {/* Error Alert if issues arise while saving/sending suggestions */}
            <ErrorAlert
                open={openWarningUnsuccessfulDataTransmission}
                onClose={() => {
                    setOpenWarningUnsuccessfulDataTransmission(false);
                    // mutate(['/dietician/recommendations-with-products', userid]);
                }}
                text={t('alert_unsuccessfuldatatransmission')}
            />
            <ErrorAlert
                open={openBetterProductsFetchError}
                onClose={() => setOpenBetterProductsFetchError(false)}
                text={t('alert_errorfetchingbetterproducts')}
            />
        </>
    );
}
