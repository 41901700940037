import { configData } from '../config.js';

export function scraperRequest(method, endpoint, migrosEmail, migrosPassword, coopEmail, coopPassword) {
    //Let it return the status code
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method,
        headers: {
            endpoint,
            ...(user && { Authentication: user.token }),
            'migros-email': migrosEmail,
            'migros-password': migrosPassword,
            'coop-email': coopEmail,
            'coop-password': coopPassword,
        },
    };

    return fetch(`${configData.API_URL}/scraper-api`, requestOptions)
        .then((res) => {
            return res.status;
        })
        .catch((e) => {
            console.log(e);
            return 500;
        });
}

export function updateScraperCredentials(migrosEmail, migrosPassword, coopEmail, coopPassword) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {
            ...(user && { Authentication: user.token }),
            endpoint: '/user/update-credentials',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            migrosEmail,
            migrosPassword,
            coopEmail,
            coopPassword,
        }),
    };
    return fetch(`${configData.API_URL}/scraper-api`, requestOptions).then((res) => {
        return res.status;
    });
}
