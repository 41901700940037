import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BetterProductsTable from '../Tables/BetterProductsTable';
import { InfoAlert } from '../Alert/Alerts';

// TODO: Implement swr
export default function BetterProducts(props) {
    const betterProducts = props.products;
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);

    return (
        <>
            {betterProducts ? (
                <>
                    <Grid container justifyContent="center">
                        <Typography
                            component="h2"
                            variant="h6"
                            gutterBottom
                            sx={{ pt: 1.5, textAlign: 'center' }}
                            onClick={() => setShowInfo(!showInfo)}
                        >
                            {t('better_products')}
                            <InfoOutlinedIcon style={{ marginLeft: 2 }} fontSize="small" />
                        </Typography>
                    </Grid>
                    <BetterProductsTable data={betterProducts} />
                    <InfoAlert open={showInfo} onClose={() => setShowInfo(false)} text={t('info_better_products')} />
                </>
            ) : props.suggestion.recommendation.dietcoach_minor_category === 'Vegetables' ? (
                <Card sx={{ bgcolor: '#45574b', mt: 2 }}>
                    <CardContent>
                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                            {t('buy_more_vegetables')}
                        </Typography>
                    </CardContent>
                </Card>
            ) : props.suggestion.recommendation.dietcoach_minor_category === 'Fruits' ? (
                <Card sx={{ bgcolor: '#45574b', mt: 2 }}>
                    <CardContent>
                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                            {t('buy_more_fruits')}
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <Card sx={{ bgcolor: '#45574b', mt: 2 }}>
                    <CardContent>
                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                            {t('no_better_products')}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </>
    );
}
