import * as React from 'react';
import { ThemeProvider, Box, CssBaseline, Container } from '@mui/material';
import colourTheme from '../styles/ColourTheme';
import UserNavigation from '../components/Navigation/UserNavigation';
import TopAppBar from '../components/AppBar/TopAppBar';

export default function DashboardPage(props) {
    return (
        <ThemeProvider theme={colourTheme}>
            <CssBaseline />
            <TopAppBar />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100' }}>
                <Container sx={{ overflowY: 'auto', flex: 1, pl: 1, pr: 1 }}>{props.content}</Container>
                <UserNavigation />
            </Box>
        </ThemeProvider>
    );
}
