import React from 'react';
import { TableContainer, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { nutrientScores } from '../../utils/NutrientScore';

export default function BetterProductsTable(props) {
    const data = props.data;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {data.map((product, index) => (
                        <TableRow key={`better-products-row-${index}`}>
                            <TableCell style={{ padding: 5 }} align="center">
                                {product.image && (
                                    <img
                                        src={product.image.replace(
                                            'https://eatfitmedias.blob.core.windows.net',
                                            'https://eatfit-service.dietcoach.ch'
                                        )}
                                        width="40"
                                        height="40"
                                        alt="Product"
                                    />
                                )}
                            </TableCell>
                            <TableCell style={{ padding: 5 }} align="left">
                                {product.product_name_de}
                            </TableCell>
                            <TableCell style={{ padding: 5 }} align="center">
                                {nutrientScores.get(product.nutri_score_final)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
