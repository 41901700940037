import React, { useEffect, useState } from 'react';
import {
    Typography,
    Divider,
    Box,
    Container,
    TextField,
    Grid,
    Paper,
    InputAdornment,
    Card,
    CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularLoading from '../../components/Loading/CircularLoading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { FeatureNotImplementedAlert } from '../../components/Alert/Alerts';
import AnalysisTable from '../../components/Tables/AnalysisTable';
import { BackTooltip } from '../../components/Tooltip/Tooltips';

// page that handles common characteristics for spending and nutrition analysis display
export default function AnalysisPage(props) {
    const { t } = useTranslation();

    const [showAnalysis, setShowAnalysis] = useState(false);

    let analysisType = props.analysisType;
    const measure = analysisType === 'Spending' ? 'CHF' : 'Kcal';

    const [totalCost, setTotalCost] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState(null);

    const [monthPicked, setMonthPicked] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [minDate, setMinDate] = useState(new Date());

    // for not implemented button
    // TODO: remove when button has functionality
    const [openDialog, setOpenDialog] = useState(false);

    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleBack = () => {
        props.handleBack();
    };

    const data = props.data;
    const error = props.error;

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            // get selectable dates
            let selectable = Object.entries(data).map(([key, value]) => {
                let date = value.date.setUTCHours(0, 0, 0, 0);
                return date;
            });

            // set monthpicked to last month with data
            var maxSelectableDate = new Date(Math.max.apply(null, selectable));
            setMonthPicked(new Date(maxSelectableDate));

            // set min and max selectable dates for datepicker
            setMaxDate(maxSelectableDate);
            setMinDate(new Date(Math.min.apply(null, selectable)));

            // set total cost for selected month
            setTotalCost(getTotalCost(data, maxSelectableDate));

            // set correct data to display in table
            setDataToDisplay(getDataToDisplay(data, maxSelectableDate));
        }
        setShowAnalysis(true);
    }, [data, error]);

    const getDataToDisplay = (data, datePicked) => {
        let selectedData = data.find(
            (e, i) => e.date.getMonth() === datePicked.getMonth() && e.date.getFullYear() === datePicked.getFullYear()
        );
        return selectedData.analysisData;
    };

    const getTotalCost = (data, datePicked) => {
        let selectedData = data.find(
            (e, i) => e.date.getMonth() === datePicked.getMonth() && e.date.getFullYear() === datePicked.getFullYear()
        );
        return selectedData.total;
    };

    const handleChange = (newValue) => {
        setMonthPicked(newValue);
        setTotalCost(getTotalCost(data, newValue));
        setDataToDisplay(getDataToDisplay(data, newValue));
    };

    return (
        <>
            <Grid container>
                <Grid item xs={2} sx={{ alignItems: 'center' }}>
                    <BackTooltip onClick={handleBack} sx={{ pt: 2 }} />
                </Grid>
                <Typography variant="h6" gutterBottom sx={{ pt: 1.5, textAlign: 'center' }}>
                    {props.pageTitle}
                </Typography>
            </Grid>
            <Divider />
            {error ? (
                <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                    <CardContent>
                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                            {t('error_fetching', { data: t('error_fetching_data') })}
                        </Typography>
                    </CardContent>
                </Card>
            ) : !showAnalysis ? (
                <CircularLoading />
            ) : (
                <>
                    {Object.keys(data).length === 0 ? (
                        <Card sx={{ bgcolor: '#17a2b8', m: 2 }}>
                            <CardContent>
                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                    {t('infotext_noshoppinghistory')}
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : (
                        <Container fixed sx={{ mt: 2, mb: 9 }}>
                            <Grid>
                                <Paper
                                    sx={{
                                        display: 'grid',
                                        height: 'auto',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid
                                            container
                                            item
                                            alignItems="center"
                                            justifyContent="space-around"
                                            sx={{ pb: 1, pt: 2 }}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    views={['year', 'month']}
                                                    label={t('month')}
                                                    openTo="month"
                                                    minDate={minDate}
                                                    maxDate={maxDate}
                                                    defaultValue={new Date()}
                                                    value={monthPicked}
                                                    onChange={handleChange}
                                                    error={false}
                                                    renderInput={(params) => (
                                                        <TextField {...params} helperText={null} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid container sx={{ pb: 2 }}>
                                            <Grid
                                                item
                                                xs={10}
                                                sx={{
                                                    pl: 2,
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <TextField
                                                    label={t('total')}
                                                    margin="dense"
                                                    size="small"
                                                    value={Math.round(totalCost)
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    variant="filled"
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start">{measure}</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sx={{
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {
                                                    // TODO: implement this functionality (i guess?)
                                                    /* <ViewAllItemsTooltip
                                                                onClick={handleClick}
                                                                title="View All Items"
                                                            /> */
                                                }
                                            </Grid>
                                        </Grid>
                                        <FeatureNotImplementedAlert open={openDialog} onClose={handleClose} />
                                        <Container sx={{ p: 1 }}>
                                            {dataToDisplay && (
                                                <AnalysisTable type={analysisType} data={dataToDisplay} />
                                            )}
                                        </Container>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Container>
                    )}
                </>
            )}
        </>
    );
}
