import * as React from 'react';
import { TableHead, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// Displays table with history of suggestions for dietician
export default function SuggestionsHistoryTable({ uniqueSuggestionDates, handleHistoryClick }) {
    const { t } = useTranslation();
    const [selected, setSelected] = React.useState(new Date());
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('date')}</TableCell>
                        <TableCell align="center">{t('dieticianName')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* first check if suggestions array is empty before calling .map function */}
                    {uniqueSuggestionDates.map(([date, firstName, lastName]) => {
                        return (
                            <TableRow
                                className={selected.getTime() === date.getTime() ? classes.selected : ''}
                                onClick={() => {
                                    handleHistoryClick(date);
                                    setSelected(date);
                                }}
                                key={date}
                            >
                                <TableCell align="center" style={{ cursor: 'pointer' }}>
                                    {date.toLocaleDateString(
                                        'de-CH',
                                        // display hours minutes and seconds
                                        {
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            second: 'numeric',
                                        }
                                    )}
                                </TableCell>
                                <TableCell align="center">{firstName + ' ' + lastName}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: '#d9d9d9',
    },
}));
