import * as React from 'react';
import { TableHead, TableContainer, TableCell, TableBody, Table, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Displays table with list of users
export default function UserListTable(props) {
    let navigate = useNavigate();
    const { t } = useTranslation();

    const handleRowClick = (userid) => {
        navigate(`/dietician/participant/${userid}`);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('participant_id')}</TableCell>
                        <TableCell align="center">{t('birthyear')}</TableCell>
                        <TableCell align="center">{t('lastpurchasedate')}</TableCell>
                        <TableCell align="center">{t('last_recommendation')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.users && props.users.length
                        ? props.users.map((user) => (
                              <TableRow
                                  key={user.externalId}
                                  onClick={() => handleRowClick(user.externalId)}
                                  style={{ cursor: 'pointer' }}
                              >
                                  <TableCell align="center">{user.externalId}</TableCell>
                                  <TableCell align="center">{user.birthMonth || ''}</TableCell>
                                  <TableCell align="center">
                                      {user.lastPurchaseTimestamp
                                          ? new Date(user.lastPurchaseTimestamp * 1000).toLocaleDateString('de-CH')
                                          : t('no_shoppingdata')}
                                  </TableCell>
                                  <TableCell align="center">
                                      {user.lastRecommendationTimestamp
                                          ? new Date(user.lastRecommendationTimestamp * 1000).toLocaleDateString(
                                                'de-CH'
                                            )
                                          : t('no_recommendationdata')}
                                  </TableCell>
                              </TableRow>
                          ))
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
