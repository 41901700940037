import React, { Component } from 'react';
import { Button, FormControlLabel, Checkbox, Grid } from '@mui/material';
import { ErrorAlert } from '../Alert/Alerts';
import validateAuthFields from '../../utils/formValidator';
import { SurveyTextField } from '../../utils/commonComponents';
import SurveyPage from '../../pages/SurveyPage';
import { withTranslation } from 'react-i18next';
import { checkExists } from '../../services/users';
import TermsAndConditions from '../../utils/TermsAndConditions';
import { SurveyButtonGroup } from '../../utils/commonComponents';

class UserSignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsOfServicesUnagreed: false,
            displayTerms: false,
            openWarningDialog: false,
            emailAlreadyInUse: false,
            emailError: false,
            value: props.value,
        };
    }

    // checks if email is valid if not used already with another account
    async isValidEmail() {
        let email = this.props.values.email;
        const [isUsedEmail, error] = await checkExists(`/user/check-if-email-used/${email}`);
        if (error) {
            alert(this.props.t('alert_message_error_sending_survey'));
        } else if (isUsedEmail === true) {
            this.setState({ emailAlreadyInUse: true });
            return false;
        } else {
            this.setState({ emailAlreadyInUse: false });
            return true;
        }
    }

    validateSignUpForm = (checkbox) => {
        let errorCount = 0;

        if (!validateAuthFields('email', this.props.values.email)) {
            errorCount += 1;
            this.setState({ emailError: true });
        } else this.setState({ emailError: false });

        if (!checkbox) {
            errorCount += 1;
            this.setState({ termsOfServicesUnagreed: true });
            this.setState({ openWarningDialog: true });
        } else this.setState({ termsOfServicesUnagreed: false });

        return errorCount === 0;
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        let connectionValidated = true;
        if (this.props.dataFetchingMethod === 'HSG') {
            connectionValidated = false;
            if (this.props.migrosCredentialsValidated) connectionValidated = true;
            if (this.props.coopCredentialsValidated) connectionValidated = true;
        }
        if (!connectionValidated) {
            this.setState({ openConnectionWarning: true });
            return;
        }

        let checkbox = document.getElementById('checkboxTerms').checked;
        const validEmail = await this.isValidEmail();
        const validatedForm = this.validateSignUpForm(checkbox);
        if (validEmail && validatedForm && connectionValidated) this.props.nextStep();
    };

    handleTermsClick = (e) => {
        this.setState({ displayTerms: !this.state.displayTerms });
    };

    render() {
        const { values, handleChange, t } = this.props;

        if (this.props.dataFetchingMethod === 'HSG') {
            return (
                <>
                    <SurveyTextField
                        value={values.email}
                        id="email"
                        label="E-Mail"
                        placeholder="E-Mail"
                        autoComplete="email"
                        onChange={(e) => handleChange({ email: e.target.value })}
                        error={this.state.emailError || this.state.emailAlreadyInUse}
                        helperText={
                            this.state.emailError
                                ? t('form_error_email')
                                : this.state.emailAlreadyInUse && t('form_error_emailinuse')
                        }
                        autoFocus={true}
                    />
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => this.setState({ displayTerms: !this.state.displayTerms })}
                                fullWidth
                                variant="outlined"
                                sx={{ mt: 1, mb: 2 }}
                            >
                                {t('terms_and_conditions')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox id="checkboxTerms" value="acceptTerms" color="primary" required />}
                                label={t('termsandconds')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                        <SurveyButtonGroup back={this.props.prevStep} next={this.handleSubmit} />
                    </Grid>
                    <TermsAndConditions
                        open={this.state.displayTerms}
                        onClose={() => this.setState({ displayTerms: !this.state.displayTerms })}
                    />
                    <ErrorAlert
                        open={this.state.openConnectionWarning}
                        onClose={() => {
                            this.setState({ openConnectionWarning: false });
                        }}
                        text={t('alert_connectionnotvalidated')}
                    />
                    <ErrorAlert
                        open={this.state.termsOfServicesUnagreed && this.state.openWarningDialog}
                        onClose={() => {
                            this.setState({ openWarningDialog: false });
                        }}
                        text={t('alert_termsofservicesunagreed')}
                    />
                </>
            );
        } else {
            return (
                <SurveyPage
                    title={t('survey_controlgrouptext')}
                    content={[
                        <SurveyTextField
                            value={values.email}
                            id="email"
                            label="E-Mail"
                            placeholder="E-Mail"
                            autoComplete="email"
                            onChange={(e) => handleChange({ email: e.target.value })}
                            error={this.state.emailError || this.state.emailAlreadyInUse}
                            helperText={
                                this.state.emailError
                                    ? t('form_error_email')
                                    : this.state.emailAlreadyInUse && t('form_error_emailinuse')
                            }
                            autoFocus={true}
                        />,
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    onClick={this.handleTermsClick}
                                    fullWidth
                                    variant="outlined"
                                    sx={{ mt: 1, mb: 2 }}
                                >
                                    {t('terms_and_conditions')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox id="checkboxTerms" value="acceptTerms" color="primary" required />
                                    }
                                    label={t('termsandconds')}
                                />
                            </Grid>
                        </Grid>,
                        <Button onClick={this.handleSubmit} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            {t('button_next')}
                        </Button>,
                        <TermsAndConditions open={this.state.displayTerms} onClose={() => this.handleTermsClick()} />,
                        <ErrorAlert
                            open={this.state.termsOfServicesUnagreed && this.state.openWarningDialog}
                            onClose={() => {
                                this.setState({ openWarningDialog: false });
                            }}
                            text={t('alert_termsofservicesunagreed')}
                        />,
                    ]}
                />
            );
        }
    }
}

export default withTranslation()(UserSignUp);
