import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Paper, Container, Box, Card, CardContent, Typography } from '@mui/material';
import useSWR from 'swr';

import CircularLoading from '../../components/Loading/CircularLoading';
import DieticianDashboardPage from './DieticianDashboardPage';
import { getData } from '../../services/dietician';
import { Title } from '../../utils/commonComponents';

export default function DashboardContent() {
    const { t } = useTranslation();

    const { data: dietician, error } = useSWR('/dietician/profile', (url) => getData(url));

    return (
        <DieticianDashboardPage
            title={t('title_dietician_dashboard')}
            content={
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    {error ? (
                        <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                            <CardContent>
                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                    {t('alert_error')}
                                </Typography>
                            </CardContent>
                        </Card>
                    ) : dietician ? (
                        <Paper
                            sx={{
                                p: 2,
                                display: 'grid',
                                flexDirection: 'column',
                                height: 'auto',
                            }}
                        >
                            <Title sx={{ flexGrow: 1, pl: 2 }} title={t('paper_title_profiledetails')} />
                            <Container>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        value={dietician.firstName && dietician.firstName}
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        // onChange={handleChange}
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        value={dietician.lastName && dietician.lastName}
                                        fullWidth
                                        label={t('form_label_surname')}
                                        name="surname"
                                        // onChange={handleChange}
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        label="E-Mail"
                                        name="email"
                                        value={dietician.email && dietician.email}
                                        variant="outlined"
                                        // onChange={handleChange}
                                    />
                                </Box>
                            </Container>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2,
                                }}
                            >
                                {/* <Button color="primary" variant="contained" onClick={() => {}}>
                                    {t('button_savedetails')}
                                </Button> */}
                            </Box>
                        </Paper>
                    ) : (
                        <CircularLoading />
                    )}
                </Container>
            }
        />
    );
}
