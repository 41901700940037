import React from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SurveyInfoPage from '../../pages/SurveyInfoPage';

export default function NextSteps(props) {
    const { t } = useTranslation();
    if (props.dataFetchingMethod === 'HSG') {
        return (
            <SurveyInfoPage
                title={t('survey_steps')}
                content={[
                    <>
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography fontWeight={600} fontSize={18}>
                                    {t('survey_step_1_hsg')}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography>{t('survey_step1_hsg')}</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography fontWeight={600} fontSize={18}>
                                    {t('survey_step_2_hsg')}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography>{t('survey_step_questionnaire')}</Typography>
                            </CardContent>
                        </Card>

                        <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                            <SurveyButtonGroup back={props.prevStep} next={() => props.nextStep(1)} />
                        </Grid>
                    </>,
                ]}
            />
        );
    } else if (props.dataFetchingMethod === 'BITSABOUTME') {
        return (
            <SurveyInfoPage
                title={t('survey_steps')}
                content={[
                    <>
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography fontWeight={600} fontSize={18}>
                                    {t('survey_step_1_bitsaboutme')}
                                </Typography>
                            </CardContent>
                        </Card>
                        {/* <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography>{t('survey_step_questionnaire')}</Typography>
                            </CardContent>
                        </Card> */}
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography fontWeight={600} fontSize={18}>
                                    {t('survey_step_2_bitsaboutme')}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ textAlign: 'center' }}>
                            <CardContent>
                                <Typography>{t('survey_step2_bitsaboutme')}</Typography>
                            </CardContent>
                        </Card>
                        <Grid container justifyContent="space-around" sx={{ pt: 2, pb: 2 }}>
                            <SurveyButtonGroup back={props.prevStep} next={() => props.nextStep(3)} />
                        </Grid>
                    </>,
                ]}
            />
        );
    }
}
