import React from 'react';
import { useTranslation } from 'react-i18next';
import SurveyInfoPage from '../../pages/SurveyInfoPage';
import { Button, Grid, Typography } from '@mui/material';
import hsg_logo from '../../assets/images/hsg_logo.png';
import bitsaboutme_logo from '../../assets/images/bitsaboutme_logo.jpg';

export default function SelectDataCollectionMethod(props) {
    const { t } = useTranslation();
    return (
        <SurveyInfoPage
            title={t('choose_datafetching_method')}
            content={[
                <>
                    <Grid container justifyContent="space-around" spacing={2} sx={{ pt: 2, pb: 2 }}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    props.handleChange('dataFetchingMethod', 'HSG');
                                    props.nextStep();
                                }}
                            >
                                <img
                                    src={hsg_logo}
                                    alt="Logo"
                                    width="150"
                                    height="150"
                                    style={{
                                        padding: 10,
                                        marginBottom: 10,
                                        boxShadow: '0px 0px 5px 2px grey',
                                    }}
                                />
                            </Button>
                            <Typography>{t('fetching_data_option_hsg')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    props.handleChange('dataFetchingMethod', 'BITSABOUTME');
                                    props.nextStep();
                                }}
                            >
                                <img
                                    src={bitsaboutme_logo}
                                    alt="Logo"
                                    width="150"
                                    height="150"
                                    style={{
                                        padding: 10,
                                        marginBottom: 10,
                                        boxShadow: '0px 0px 5px 2px grey',
                                    }}
                                />
                            </Button>
                            <Typography>{t('fetching_data_option_bitsaboutme')}</Typography>
                        </Grid>
                    </Grid>
                </>,
            ]}
        />
    );
}
