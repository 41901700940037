import React, { Component } from 'react';
import { disorders } from '../../utils/Disorders';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import SurveyPage from '../../pages/SurveyPage';
import { validateSurveyFields } from '../../utils/formValidator';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { ErrorAlert } from '../Alert/Alerts';

class DisordersQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disorders: [],
            missingChoiceError: false,
            validated: false,
            otherDiseasesErrorText: '',
            showOtherTextfield: false,
            value: props.value,
        };
        this.handleCloseError = this.handleCloseError.bind(this);
    }

    handleCheckboxChange = (checkboxName, e) => {
        let checkbox = e.target;
        const isCheckboxChecked = checkbox.type === 'checkbox' ? checkbox.checked : checkbox.value;

        // copy the state array to not directly modify the state
        let listOfDisorders = [...this.state.disorders];

        // if 'none of the above' is selected, nothing else can be selected
        if (checkboxName !== 'None of the above' && listOfDisorders.includes('None of the above')) return;

        if (isCheckboxChecked) {
            // if 'none of the above' is selected, remove all other disorders from checklist
            if (checkboxName === 'None of the above' && isCheckboxChecked) {
                listOfDisorders = ['None of the above'];
                this.setState({ showOtherTextfield: false });
            }
            if (!listOfDisorders.includes(checkboxName)) listOfDisorders.push(checkboxName);
        } else {
            // uncheck if already checked
            listOfDisorders = listOfDisorders.filter((e) => checkboxName !== e);
        }

        // handle 'other' textbox
        if (checkboxName === 'Other' && isCheckboxChecked) this.setState({ showOtherTextfield: true });
        if (checkboxName === 'Other' && !isCheckboxChecked) this.setState({ showOtherTextfield: false });

        this.setState({ disorders: listOfDisorders });
        this.props.handleChange('disorders', listOfDisorders);
    };

    validateForm = (e) => {
        e.preventDefault();
        let invalidInputCount = 0;

        if (this.state.showOtherTextfield && !validateSurveyFields('select', this.props.values.otherDiseases)) {
            this.setState({ otherDiseasesErrorText: 'Please input a valid disease name!' });
            invalidInputCount += 1;
        }

        if (this.state.disorders.length === 0) invalidInputCount += 1;

        if (this.state.disorders.includes('None of the above') && this.state.disorders.length !== 1)
            invalidInputCount += 1;

        this.setState({ validated: true });

        if (invalidInputCount === 0) this.props.nextStep();
        else {
            this.setState({ missingChoiceError: true });
        }
    };

    handleCloseError() {
        this.setState({ missingChoiceError: false });
    }

    render() {
        const { values, handleChange, t } = this.props;
        const { showOtherTextfield } = this.state;

        // choose if german translation should be called from disorders list
        var { displayGermanDisorders } = false;
        if (i18next.language === 'de' || i18next.language === 'de-CH') displayGermanDisorders = true;

        return (
            <SurveyPage
                title={t('survey_question_disorders')}
                content={[
                    <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">
                            <TableBody>
                                {disorders.map(({ name, deTranslation }, index) => {
                                    return (
                                        <TableRow key={`disorders-row-${index}}`} style={{ width: '100%' }}>
                                            <label style={{ cursor: 'pointer' }}>
                                                <TableCell>
                                                    <input
                                                        type="checkbox"
                                                        id={`disorder-checkbox-${index}`}
                                                        name={name}
                                                        checked={values.disorders.includes(name)}
                                                        onChange={(e) => this.handleCheckboxChange(name, e)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: '100%' }}>
                                                    {displayGermanDisorders ? deTranslation : name}
                                                </TableCell>
                                            </label>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>,
                    <>
                        {showOtherTextfield ? (
                            <SurveyTextField
                                id="otherDisease"
                                label="Other Disease"
                                value={values.otherDisease}
                                onChange={(e) => handleChange('otherDiseases', e.target.value)}
                                error={this.state.validated && this.state.otherDiseasesErrorText.length !== 0}
                                helperText={
                                    this.state.validated && this.state.otherDiseasesErrorText.length !== 0
                                        ? this.state.otherDiseasesErrorText
                                        : ''
                                }
                                autoFocus={true}
                            />
                        ) : (
                            ''
                        )}
                    </>,
                    <ErrorAlert
                        open={this.state.missingChoiceError}
                        onClose={this.handleCloseError}
                        text={t('alert_missing_choice')}
                    />,
                    <SurveyButtonGroup
                        back={() => {
                            this.props.prevStep();
                        }}
                        next={this.validateForm}
                    />,
                ]}
            />
        );
    }
}

export default withTranslation()(DisordersQuestion);
