import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import two_fa_notification from '../../assets/images/two_fa_notification.jpeg';

class Deactivate2fa extends Component {
    render() {
        const { t } = this.props;

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* {supported() && !isInstalled() && !controlGroup && (
                    <>
                        <Typography align="center" sx={{ mt: 2 }}>
                            {t('install_app_message')}
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{
                                bgcolor: 'green',
                                width: 230,
                                mb: 4,
                                mt: 2,
                            }}
                            onClick={handleClick}
                        >
                            {t('install_app')}
                        </Button>
                        <Divider orientation="horizontal" flexItem sx={{ mb: 2 }} />
                    </>
                )} */}
                <Typography align="center" sx={{ mt: 2 }}>
                    <Trans>
                        {t('text_signuplink_2')}
                        <a href="https://login.migros.ch/security" target="_blank" rel="noreferrer">
                            {' '}
                        </a>
                    </Trans>
                </Typography>
                <Typography align="center">
                    <img
                        src={two_fa_notification}
                        alt="two-fa-notification"
                        width="35%"
                        style={{
                            overflow: 'hidden',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderRadius: 25,
                            marginRight: 10,
                        }}
                    />
                </Typography>
            </Box>
        );
    }
}

export default withTranslation()(Deactivate2fa);
