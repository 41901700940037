// input validation for form fields
export default function validateAuthFields(fieldname, value) {
    if (value === undefined || value === null || value === '') return false
    let valid = false;
    if (value !== '') {
        switch (fieldname) {
            case 'email':
                // This regex accepts anystring@anystring.anystring
                let re = /\S+@\S+\.\S+/;
                valid = re.test(value);
                break;
            case 'password':
                let atLeastOneDigit = /^.*\d.*$/;
                let atLeastOneLowerCase = /^.*[a-z].*$/;
                let atLeastOneUpperCase = /^.*[A-Z].*$/;
                let atLeastOneSymbol = /^.*[^a-zA-Z\d\s].*$/;
                if (value.length >= 8 && value.length <= 24 && value.match(atLeastOneDigit)
                    && value.match(atLeastOneLowerCase) && value.match(atLeastOneUpperCase)
                    && value.match(atLeastOneSymbol)) {
                    valid = true;
                }
                break;
            default:
                break;
        }
    }
    return valid;
}

// input validation for survey form fields
export function validateSurveyFields(fieldname, value) {
    let valid = false;

    if (value !== '' && value !== undefined && value !== null) {
        switch (fieldname) {
            case 'birthdate':
                var minimumAgeDate = new Date();
                minimumAgeDate.setFullYear(minimumAgeDate.getFullYear() - 18);
                var compareDate = new Date(value);
                if (compareDate.getTime() < minimumAgeDate.getTime()) valid = true;
                break;
            case 'select':
                // for any select input that doesn't need more checks other than if something is selected
                valid = true;
                break;
            case 'weight':
                if (!isNaN(Number(value)) && Number(value) >= 2 && Number(value) <= 650) valid = true;
                break;
            case 'height':
                if (!isNaN(Number(value)) && value >= 30 && value <= 300) valid = true;
                break;
            case 'percentage':
                if (!isNaN(Number(value)) && value >= 0 && value <= 100) valid = true;
                break;
            case 'shareAdult':
                if (!isNaN(value) && value > 0 && value % 1 === 0) valid = true;
                break;
            case 'number':
                if (!isNaN(value)) valid = true;
                break;
            case 'username':
                let re = /^[a-zA-Z0-9@.-_]{2,30}$/;
                // let re = /[a-zA-Z0-9]{6,20}/;
                valid = re.test(value);
                break;
            default:
                break;
        }
    }
    return valid;
}