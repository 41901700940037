import React from 'react';
import { Box, Button, Typography, Divider } from '@mui/material';
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { getUserBAMLink } from '../../services/utils';
import { Buffer } from 'buffer';
import Error404Page from '../../pages/Error404Page';
import { useState, useEffect } from 'react';
import { useReactPWAInstall } from 'react-pwa-install';
import myLogo from '../../assets/favicon.ico';
import { Trans } from 'react-i18next';
import two_fa_notification from '../../assets/images/two_fa_notification.jpeg';

const SignUpLink = (props) => {
    const isValidURL = (string) => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
    };

    const handleContinue = () => {
        props.nextStep();
    };

    const handleClick = () => {
        pwaInstall({
            title: t('install_foodcoach'),
            logo: myLogo,
            features: (
                <ul>
                    <li>{t('install_feature_1')}</li>
                    <li>{t('install_feature_2')}</li>
                    <li>{t('install_feature_3')}</li>
                </ul>
            ),
        });
    };

    const [validLink, setValidLink] = useState(true);
    const [link, setLink] = useState('');
    const { t, controlGroup, values } = props;
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    useEffect(() => {
        // once this page is mounted, the user link is called from the signUpService
        // to save to the state in order for the Link component to call the corresponding url
        const link = getUserBAMLink();
        if (link !== undefined && link !== '' && Object.entries(link).length !== 0 && isValidURL(link)) {
            setValidLink(true);
            // 'hack' to hide authorization popup on BAM registration link
            // at least for the preproduction environment the base64 string should always be the same
            let authorization = 'YmY6YmYyMDIx';
            let base64ToString = Buffer.from(authorization, 'base64').toString();
            let [username, password] = base64ToString.split(':');
            let authorizedLink = new URL(link);
            authorizedLink.username = username;
            authorizedLink.password = password;
            setLink(link);
        } else {
            console.log('There seems to be a problem with this page, no user link could be found.');
        }
    }, []);

    return (
        <>
            {validLink ? (
                <SurveyPage
                    title={t('survey_step_2_bitsaboutme')}
                    content={[
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {supported() && !isInstalled() && !(controlGroup && values.userType === 'Patient') && (
                                <>
                                    <Typography align="center" sx={{ mt: 2 }}>
                                        {t('install_app_message')}
                                    </Typography>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            bgcolor: 'green',
                                            width: 230,
                                            mb: 4,
                                            mt: 2,
                                        }}
                                        onClick={handleClick}
                                    >
                                        {t('install_app')}
                                    </Button>
                                    <Divider orientation="horizontal" flexItem sx={{ mb: 2 }} />
                                </>
                            )}

                            <Typography align="center">{t('text_signuplink_1')}</Typography>

                            {(values.connectedLoyaltyCards === 'Migros' || values.connectedLoyaltyCards === 'Both') && (
                                <>
                                    <Typography align="center" sx={{ mt: 2 }}>
                                        <Trans>
                                            {t('text_signuplink_2')}
                                            <a href="https://login.migros.ch/security" target="_blank" rel="noreferrer">
                                                {' '}
                                            </a>
                                        </Trans>
                                    </Typography>
                                </>
                            )}
                            <Button
                                onClick={() => {
                                    window.open(link, '_blank');
                                    handleContinue();
                                }}
                                color="primary"
                                variant="contained"
                                sx={{
                                    width: 230,
                                    mt: 3,
                                    mb: 5,
                                }}
                            >
                                {t('button_continuetoBAM')}
                            </Button>
                            {(values.connectedLoyaltyCard === 'Migros' || values.connectedLoyaltyCard === 'Both') && (
                                <Typography align="center">
                                    <img
                                        src={two_fa_notification}
                                        alt="two-fa-notification"
                                        width="45%"
                                        style={{
                                            overflow: 'hidden',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderRadius: 25,
                                            marginRight: 10,
                                        }}
                                    />
                                </Typography>
                            )}
                        </Box>,
                    ]}
                />
            ) : (
                <Error404Page />
            )}
        </>
    );
};

export default withTranslation()(SignUpLink);
