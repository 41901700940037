import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Divider } from '@mui/material';

import { FeatureNotImplementedAlert } from '../../components/Alert/Alerts';
import { Title } from '../../utils/commonComponents';

const SettingsPassword = (props) => {
    const { t } = useTranslation();
    const formPwd = 'form_password';
    const formConfirmPwd = 'form_confirmpassword';
    const save = 'button_save';
    const [values, setValues] = useState({
        password: '',
        confirm: '',
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const [openDialog, setOpenDialog] = useState(false);
    const handleClick = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <form {...props}>
            <Title sx={{ flexGrow: 1, pb: 2, pl: 2 }} title={t('paper_title_password')} />
            <Divider />
            <TextField
                fullWidth
                label={t(formPwd)}
                margin="normal"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                autoComplete="new-password"
            />
            <TextField
                fullWidth
                label={t(formConfirmPwd)}
                margin="normal"
                name="confirm"
                onChange={handleChange}
                type="password"
                value={values.confirm}
                variant="outlined"
                autoComplete="new-password"
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                }}
            >
                <Button color="primary" variant="contained" onClick={handleClick}>
                    {t(save)}
                </Button>
            </Box>
            <FeatureNotImplementedAlert open={openDialog} onClose={handleClose} />
        </form>
    );
};

export default SettingsPassword;
