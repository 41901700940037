import React, { Component } from 'react';
import { ThemeProvider, Typography, Grid, Box, CssBaseline, Container, Divider } from '@mui/material';
import SurveyLanguageHeader from '../components/Language/SurveyLanguageHeader';
import colourTheme from '../styles/ColourTheme';

// Layout page for all survey info pages
export class SurveyInfoPage extends Component {
    render() {
        const { title, content } = this.props;

        return (
            <ThemeProvider theme={colourTheme}>
                <SurveyLanguageHeader />
                <Container component='main' maxWidth='xs'>
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component='form' noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={1} alignItems='center' justifyContent='center'>
                                <Grid item xs={12} sx={{ pb: 2 }}>
                                    <Typography component='h2' variant='h5' align='center' >
                                        {title}
                                    </Typography>
                                    <Divider light sx={{ pt: 2 }} />
                                </Grid>
                                <Grid container spacing={1}>
                                    {content.map((comp, index) => (
                                        <Grid item key={index} xs={12} >
                                            {comp}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider >
        );
    }
}

export default SurveyInfoPage;