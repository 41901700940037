import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Table that displays the old products used for the calculations in the nutrient table
export default function ProductsTable({ history }) {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('table_recommendations_productname')}</TableCell>
                        <TableCell align="center">Nutri-Score</TableCell>
                        <TableCell align="center">price</TableCell>
                        <TableCell align="center">quantity</TableCell>
                        <TableCell align="center">date</TableCell>

                        {/* <TableCell align="center">FSA score</TableCell>
                        <TableCell align="center">FSA sodium score</TableCell>
                        <TableCell align="center">{t('table_recommendations_priceperunit')}</TableCell>
                        <TableCell align="center">{t('table_recommendations_priceper100g')}</TableCell>
                        <TableCell align="center">{t('table_recommendations_frequency')}</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {history.map((row, index) => {
                        const date = new Date(row.timestamp * 1000);
                        return (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                                <TableCell align="center">
                                    <div>
                                        <div>
                                            <img
                                                src={row.product_image.replace(
                                                    'https://eatfitmedias.blob.core.windows.net',
                                                    'https://eatfit-service.dietcoach.ch'
                                                )}
                                                style={{ width: 30, height: 30 }}
                                                alt=""
                                            />
                                        </div>
                                        <div>{row.item_name}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center">{row.item_nutri_score}</TableCell>
                                <TableCell align="center">{row.item_price}</TableCell>
                                <TableCell align="center">{row.item_quantity}</TableCell>
                                <TableCell align="center">{date.toLocaleDateString('de-CH')}</TableCell>

                                {/* <TableCell align="center">{row.fsascore}</TableCell> */}
                                {/* <TableCell align="center">{row.fsasodiumscore}</TableCell> */}
                                {/* <TableCell align="center">{row.ppu}</TableCell> */}
                                {/* <TableCell align="center">{row.ppg}</TableCell> */}
                                {/* <TableCell align="center">{row.frequency}</TableCell> */}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
