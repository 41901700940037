import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { colourTheme } from '../styles/ColourTheme';
import { useTranslation } from 'react-i18next';
import LanguageHeader from '../components/Language/LanguageHeader';
import { centeredBox, mainButtonStyle } from '../styles/ComponentStylings';
import { useReactPWAInstall } from 'react-pwa-install';
import myLogo from '../assets/favicon.ico';

// for verified experimental group users
export default function AccountVerifiedPage() {
    const { t } = useTranslation();
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    const handleClick = () => {
        pwaInstall({
            title: t('install_dietcoach'),
            logo: myLogo,
            features: (
                <ul>
                    <li>{t('install_feature_1')}</li>
                    <li>{t('install_feature_2')}</li>
                    <li>{t('install_feature_3')}</li>
                </ul>
            ),
        });
    };

    return (
        <>
            <ThemeProvider theme={colourTheme}>
                <LanguageHeader />
                <Container component="main" maxWidth="s">
                    <Box sx={centeredBox}>
                        <Typography component="h3" variant="h6" align="center">
                            {t('text_verificationcomplete')}
                        </Typography>
                        <Box sx={{ marginTop: 8, '& button': { m: 1 }, display: 'flex', flexDirection: 'column' }}>
                            {supported() && !isInstalled() && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        bgcolor: 'green',
                                        color: 'white',
                                        align: 'right',
                                        ml: 'auto',
                                        textAlign: 'center',
                                        width: '250px',
                                        margin: 1,
                                        padding: 2,
                                        marginBottom: 2,
                                        fontSize: '20px',
                                    }}
                                    onClick={handleClick}
                                >
                                    {t('install_app')}
                                </Button>
                            )}
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Button color="primary" variant="contained" sx={mainButtonStyle}>
                                    {t('button_home')}
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </>
    );
}
