import i18next from 'i18next';

export const toUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getDiseasesValue = (data) => {
    let diseases = [];
    if (data.disorders) {
        data.disorders.forEach((dis) => {
            if (disorders_list[dis]) {
                let translation = i18next.t(disorders_list[dis]);
                diseases.push(translation);
            }
        });
    }
    if (data.otherDiseases) diseases.push(data.otherDiseases);
    return diseases.join(', ');
};

export const getAllergiesValue = (data) => {
    let allergies = [];
    if (data.allergiesAndAbstentions.allergies) {
        data.allergiesAndAbstentions.allergies.forEach((allergy) => {
            if (allergies_list[allergy]) {
                let translation = i18next.t(allergies_list[allergy]);
                allergies.push(translation);
            }
        });
    }
    return allergies.join(', ');
};

export const getAbstentionsValue = (data) => {
    let abstentions = [];
    if (data.allergiesAndAbstentions.abstentions) {
        data.allergiesAndAbstentions.abstentions.forEach((abst) => {
            if (allergies_list[abst]) {
                let translation = i18next.t(allergies_list[abst]);
                abstentions.push(translation);
            }
        });
    }
    return abstentions.join(', ');
};

export const sports_list = {
    '4+': 'survey_select_usersports_4',
    '2-3': 'survey_select_usersports_3',
    1: 'survey_select_usersports_2',
    '<1': 'survey_select_usersports_1',
    0: 'survey_select_usersports_0',
};

export const educations_list = {
    noFormalEducation: 'survey_select_usereducation_noFormalEducation',
    obligatory: 'survey_select_usereducation_obligatory',
    apprenticeship: 'survey_select_usereducation_apprenticeship',
    high: 'survey_select_usereducation_high',
    university: 'survey_select_usereducation_university',
};

export const activityLevelAtWork_list = {
    sittingLying: 'survey_select_useractivity_lying',
    exclusivelySedentary: 'survey_select_useractivity_exclusivelySedentary',
    sedentary: 'survey_select_useractivity_sedentary',
    goingStanding: 'survey_select_useractivity_standing',
    strenuous: 'survey_select_useractivity_strenuous',
};

export const disorders_list = {
    Obesity: 'diseases_obesity',
    Diabetes: 'diseases_diabetes',
    Dyslipidemia: 'diseases_dyslipidemia',
    'Arterial hypertension': 'diseases_arterialhypertension',
    'Atherosclerotic cardiovascular disease': 'diseases_atheroscleroticcardiovasculardisease',
    'None of the above': 'diseases_none',
};

export const allergies_list = {
    Eggs: 'allergens_eggs',
    SesameSeeds: 'allergens_sesame',
    Gluten: 'allergens_gluten',
    Soy: 'allergens_soy',
    Nuts: 'allergens_nuts',
    Peanuts: 'allergens_peanuts',
    Sulphites: 'allergens_sulphites',
    Mustards: 'allergens_mustards',
    Cellery: 'allergens_cellery',
    Fish: 'allergens_fish',
    Lupin: 'allergens_lupin',
    Crustaceans: 'allergens_crustaceans',
    Molluscs: 'allergens_molluscs',
    Milk: 'allergens_milk',
};

export const minor_category_translations = {
    Beverages: 'Getränke',
    'Milk drinks': 'Milchgetränke',
    Juices: 'Säfte',
    'Sweet drinks': 'Süßgetränke',
    Fruits: 'Früchte',
    'Grains, potatoes and legumes': 'Getreide, Kartoffeln und Hülsenfrüchte',
    'Muesli and cereals': 'Müsli und Cerealien',
    Dough: 'Teigwaren',
    Legumes: 'Hülsenfrüchte',
    'Grain products': 'Getreideprodukte',
    Breads: 'Brotwaren',
    Potatoes: 'Kartoffeln',
    'Oils, fats, sauces, nuts and seeds': 'Öle, Fette, Saucen, Nüsse und Samen',
    Cream: 'Sahne',
    'Fats and oil': 'Fette und Öle',
    Sauces: 'Saucen',
    'Nuts, seeds and oil fruits': 'Nüsse, Samen und Ölfrüchte',
    'Processed food': 'Verarbeitete Lebensmittel',
    'Fast food': 'Fast Food',
    'Jams and sweet spreads': 'Marmeladen und süße Aufstriche',
    'Salty snacks': 'Salzige Snacks',
    'Sweet snacks': 'Süße Snacks',
    Bars: 'Riegel',
    'Ice cream and puddings': 'Glace und Puddings',
    'Protein foods': 'Proteinreiche Lebensmittel',
    'Cheese products': 'Käseprodukte',
    'Vegetarian alternatives': 'Fleischersatz',
    Eggs: 'Eier',
    'Milk products': 'Milchprodukte',
    'Cream cheese and cottage cheese': 'Quark und Frischkäse',
    'Meat and fish': 'Fleisch und Fisch',
    'Sausages and cold cuts': 'Wurst und Aufschnitt',
    Vegetables: 'Gemüse',
};

export const minor_category_translations_accusative = {
    Beverages: 'Getränken',
    'Milk drinks': 'Milchgetränken',
    Juices: 'Säften',
    'Sweet drinks': 'Süßgetränken',
    Fruits: 'Früchten',
    'Grains, potatoes and legumes': 'Getreide, Kartoffeln und Hülsenfrüchten',
    'Muesli and cereals': 'Müsli und Cerealien',
    Dough: 'Teigwaren',
    Legumes: 'Hülsenfrüchten',
    'Grain products': 'Getreideprodukten',
    Breads: 'Brotwaren',
    Potatoes: 'Kartoffeln',
    'Oils, fats, sauces, nuts and seeds': 'Ölen, Fetten, Saucen, Nüssen und Samen',
    Cream: 'Sahne',
    'Fats and oil': 'Fette und Öle',
    Sauces: 'Saucen',
    'Nuts, seeds and oil fruits': 'Nüsse, Samen und Ölfrüchten',
    'Processed food': 'Verarbeitete Lebensmitteln',
    'Fast food': 'Fast Food',
    'Jams and sweet spreads': 'Marmeladen und süßen Aufstrichen',
    'Salty snacks': 'Salzigen Snacks',
    'Sweet snacks': 'Süßen Snacks',
    Bars: 'Riegeln',
    'Ice cream and puddings': 'Glacen und Puddings',
    'Protein foods': 'Proteinreichen Lebensmitteln',
    'Cheese products': 'Käseprodukten',
    'Vegetarian alternatives': 'Fleischersatz',
    Eggs: 'Eiern',
    'Milk products': 'Milchprodukten',
    'Cream cheese and cottage cheese': 'Quark und Frischkäse',
    'Meat and fish': 'Fleisch und Fisch',
    'Sausages and cold cuts': 'Wurst und Aufschnitt',
    Vegetables: 'Gemüse',
};

export const nutrient_translations = {
    energy: 'Energie',
    sugar: 'Zucker',
    'saturated fat': 'gesättigten Fettsäuren',
    sodium: 'Salz',
    fiber: 'Ballaststoffen',
    protein: 'Protein',
};
