import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography } from '@mui/material';

import NutrientGraph from '../../components/NutrientGraph/NutrientGraph';
import { Title } from '../../utils/commonComponents';

export default function DashboardHomePage({ data, nutrientData, displayGermanDisorders }) {
    const { t } = useTranslation();
    return (
        <>
            <Title sx={{ flexGrow: 1, pl: 2 }} title={t('section_title_nutrigraph')} />
            <NutrientGraph data={nutrientData} />
            <Card
                sx={{
                    bgcolor: 'white',
                    mb: 1,
                }}
            >
                <CardContent>
                    <Typography
                        sx={{ color: '#1d211f', fontWeight: 500, fontSize: 12 }}
                        align="center"
                        variant="subtitle1"
                    >
                        {displayGermanDisorders ? data.user_nutri_score_message_de : data.user_nutri_score_message_en}
                    </Typography>
                </CardContent>
            </Card>
            <Card
                sx={{
                    bgcolor: 'white',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{ color: '#1d211f', fontWeight: 500, fontSize: 12 }}
                        align="center"
                        variant="subtitle1"
                    >
                        {displayGermanDisorders
                            ? data.user_ofcom_percentage_message_de
                            : data.user_ofcom_percentage_message_en}
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
}
