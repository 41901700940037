import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WarningAlert } from '../Alert/Alerts';
import { nutrientScores, nutrientOfcomValues } from '../../utils/NutrientScore';
import './style.css';

function createProductRecRows(id, productname, gtin, nutriscore, fsascore, fsanutrientscore, frequency) {
    return { id, productname, gtin, nutriscore, fsascore, fsanutrientscore, frequency, align: 'center' };
}

function formatProductRecommendationData(data, nutrient) {
    const productsData = data.map((row, index) => {
        try {
            return createProductRecRows(
                index,
                row.product_name_de,
                row.gtin,
                row.nutri_score_final,
                row.ofcom_value,
                row.nutri_score_facts[nutrientOfcomValues.get(nutrient)],
                '-'
            );
        } catch {
            return createProductRecRows(
                index,
                row.product_name_de,
                row.gtin,
                row.nutri_score_final,
                row.ofcom_value,
                '-',
                '-'
            );
        }
    });
    return productsData;
}

// Table that displays the products for recommendation
export default function RecommendationsProductsTable(props) {
    const [selectedIndices, setSelectedIndices] = React.useState([]);
    const { t } = useTranslation();
    const [openWarningDialog, setOpenWarningDialog] = useState(false);

    const handleClose = () => {
        setOpenWarningDialog(false);
    };

    const columns = [
        {
            field: 'productname',
            headerName: t('table_recommendations_productname'),
            width: 360,
            sortable: false,
            wrapText: true,
        },
        {
            field: 'gtin',
            headerName: 'GTIN',
            width: 150,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            wrapText: false,
        },
        {
            field: 'nutriscore',
            headerName: 'Nutri-Score',
            width: 110,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                return nutrientScores.get(params.value);
            },
        },
        { field: 'fsascore', headerName: 'FSA score', headerAlign: 'center', align: 'center', sortable: false },
        // {
        //     field: 'fsanutrientscore',
        //     headerName: nutrientScoreTitles.get(props.nutrient),
        //     width: 160,
        //     headerAlign: 'center',
        //     align: 'center',
        //     sortable: false,
        // },
        // {
        //     field: 'frequency',
        //     headerName: t('table_recommendations_frequency'),
        //     width: 110,
        //     headerAlign: 'center',
        //     align: 'center',
        //     sortable: false,
        // },
    ];

    const rows = formatProductRecommendationData(props.products, props.nutrient);

    return (
        <div style={{ width: 800 }}>
            <div style={{ height: 600 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    // pageSize={5}
                    checkboxSelection
                    onSelectionModelChange={(newSelection) => {
                        setSelectedIndices(newSelection);
                    }}
                    selectionModel={selectedIndices}
                    disableColumnFilter
                    disableColumnSelector
                    disableVirtualization
                    hideFooterSelectedRowCount
                    disableColumnMenu
                />
                {/* The following line will help me access the correct row selected for 
                saving which products should be sent as recommendation to backend*/}
                {/* {selectionModel.map(val =><h1>{val}</h1>)} */}
            </div>
            <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button
                    variant="contained"
                    onClick={() => {
                        if (selectedIndices.length === 5) {
                            props.handleSelectRecommendProducts(selectedIndices.map((i) => props.products[i]));
                            props.onClose();
                        } else {
                            setOpenWarningDialog(true);
                        }
                    }}
                >
                    {t('button_save')}
                </Button>
                <WarningAlert open={openWarningDialog} onClose={handleClose} text={t('alert_select_five_products')} />
            </Box>
        </div>
    );
}
