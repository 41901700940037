import * as React from 'react';
import { Paper, Grid, Table, TableCell, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import { paperStyling } from '../../styles/ComponentStylings';
import { Title } from '../../utils/commonComponents';
import { useTranslation } from 'react-i18next';
import SuggestionsHistoryTable from '../Tables/SuggestionsHistoryTable';

export default function HistorySuggestions({
    suggestionHistory,
    suggestionHistoryError,
    handleSuggestionHistoryClick,
}) {
    const { t } = useTranslation();

    const [historySuggestionsDetails, setHistorySuggestionsDetails] = React.useState([]);

    let uniqueSuggestionDates = [];
    if (suggestionHistory) {
        const recommendationDates = suggestionHistory
            .filter(
                // filter all is_pushed suggestions
                (sug) => sug.is_pushed === true
            )
            .map((sug) => [sug.save_time, sug.dietician_first_name, sug.dietician_last_name]);
        uniqueSuggestionDates = [...new Set(recommendationDates.map(JSON.stringify))]
            .map(JSON.parse)
            .map((rec) => [new Date(rec[0]), rec[1], rec[2]]);
    }

    const handleHistoryClick = (date) => {
        const suggestions = suggestionHistory.filter((rec) => new Date(rec.save_time).getTime() === date.getTime());
        setHistorySuggestionsDetails(suggestions);
        handleSuggestionHistoryClick(date);
    };

    return (
        <Grid item xs={12}>
            <Paper sx={paperStyling}>
                <Title title={t('section_title_recommendations_history')} />
                {suggestionHistoryError ? (
                    <Grid container ml={2.5}>
                        {t('error_fetching', { data: t('error_fetching_recommendation_history') })}
                    </Grid>
                ) : (
                    <>
                        {suggestionHistory ? (
                            <SuggestionsHistoryTable
                                uniqueSuggestionDates={uniqueSuggestionDates}
                                handleHistoryClick={(date) => handleHistoryClick(date)}
                            />
                        ) : null}

                        {historySuggestionsDetails.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">priority</TableCell>
                                            <TableCell align="center">recommendation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* first check if suggestions array is empty before calling .map function */}
                                        {historySuggestionsDetails.map((sug, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{sug.importance}</TableCell>
                                                <TableCell align="center">
                                                    {sug.recommendation.recommendation_en}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : null}
                    </>
                )}
            </Paper>
        </Grid>
    );
}

// const suggestionsHistoryTableColumns = [
//     {
//         key: 'col0',
//         dataKey: 'id',
//         title: 'Priority',
//         width: '5%',
//     },
//     {
//         key: 'col1',
//         dataKey: 'instruction',
//         title: 'Instruction',
//         width: '10%',
//     },
//     {
//         key: 'col2',
//         dataKey: 'description',
//         title: 'Description',
//         width: '25%',
//     },
//     {
//         key: 'col3',
//         dataKey: 'modifiedText',
//         title: 'Last Modified',
//         width: '60%',
//     },
// ];
