import React, { Component } from 'react'
import Select from 'react-select';
import { ErrorAlert } from '../Alert/Alerts';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { FormHelperText } from '@mui/material';


class LoyaltyCardsQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value,
            displayEligibilityAlert: false,
            navigateToHome: false,
        }
        this.options = [
            { value: 'Migros', label: 'Migros Cumulus' },
            { value: 'Coop', label: 'Coop Supercard' },
            { value: 'Both', label: i18next.t('both') },
            { value: 'None', label: i18next.t('none') }
        ]
        this.handleCloseIneligibility = this.handleCloseIneligibility.bind(this);
    }

    handleOptionChange = (e) => {
        var option = e.value;
        this.props.handleChange('loyaltyCards', option, (value) => { console.log('Selected option: ' + value) });
    }

    validateForm = (e) => {
        e.preventDefault();

        let option = this.props.values.connectedLoyaltyCards;

        // check if no selection made then return to wait for selection

        if (option === '') {
            this.setState({ selectionError: true });
            return;
        }

        // check if 'none' was chosen which means ineligiblity for study participation
        else if (option === 'none') {
            this.setState({ displayEligibilityAlert: true });
            return;
        }

        // proceed if eligible selection made
        this.props.nextStep();
    }

    handleCloseIneligibility() {
        this.setState({ displayEligibilityAlert: false });
        this.setState({ navigateToHome: true });
    }

    render() {
        const { values, t } = this.props;
        const { navigateToHome } = this.state;

        return (
            navigateToHome ? <Navigate to='/' /> :
                <SurveyPage
                    title={t('survey_question_userloyaltycards')}
                    content={[

                        <Select
                            name='loyaltycards-selection'
                            placeholder={t('survey_select_label_loyaltycards')}
                            value={this.options.filter(({ value }) => value === values.connectedLoyaltyCards)} onChange={this.handleOptionChange}
                            options={this.options}
                            isSearchable={false}
                            styles={{ menu: base => ({ ...base, zIndex: 100 }) }}
                        />,
                        <>
                            {this.state.selectionError && <FormHelperText error>{t('form_error_selection')}</FormHelperText>}
                        </>,
                        <ErrorAlert
                            open={this.state.displayEligibilityAlert}
                            onClose={this.handleCloseIneligibility}
                            text={t('alert_ineligibility')}
                        />,
                        <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                    ]}
                />
        )
    }
}

export default withTranslation()(LoyaltyCardsQuestion); 
