import MaterialTable from 'material-table';
import React, { forwardRef } from 'react';
import { AddBox, ArrowUpward, Check, ChevronRight, Remove, ViewColumn } from '@material-ui/icons';
import { Grid, Paper } from '@mui/material';
import { Dialog } from '@mui/material';
import useSWR from 'swr';
import { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Title } from '../../utils/commonComponents';
import './style.css';
import ProductsTable from '../../components/Recommendations/ProductsTable';
import { ErrorAlert } from '../../components/Alert/Alerts';
import { getData } from '../../services/dietician';
import { minor_category_translations } from '../../utils/ListOfThings';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const NutrientTable = (props) => {
    const { t } = useTranslation();
    const [germanTranslation, setGermanTranslation] = useState(false);
    useEffect(() => {
        if (i18next.language === 'de' || i18next.language === 'de-CH') setGermanTranslation(true);
        else setGermanTranslation(false);
    }, []);

    const [category, setCategory] = useState(null);
    const {
        data: categoryHistory,
        error: categoryHistoryError,
        isValidating,
    } = useSWR(
        category ? ['/dietician/category-history-and-recommendations', category] : null,
        (url) => {
            return getData(
                url,
                props.userid,
                Math.round(props.startDate.getTime() / 1000),
                Math.round(props.endDate.getTime() / 1000),
                category
            ).then((res) => res.category_history || []);
        },
        { revalidateOnFocus: false }
    );

    const [openBetterProductsFetchError, setOpenBetterProductsFetchError] = useState(false);
    useEffect(() => {
        if (categoryHistoryError && !isValidating) {
            setOpenBetterProductsFetchError(true);
        }
    }, [categoryHistoryError, isValidating]);

    let message = null;
    if (props.nutrientTableDataError) message = t('error_fetching', { data: t('error_fetching_nutrient_table') });
    else if (!props.minDate || !props.maxDate) message = t('no_shoppingdata');
    else if (props.tableData.length === 0) message = t('text_nonutrienttabledata');

    const clickableQuantityCells = ['Fruits', 'Vegetables', 'Beverages'];
    if (message)
        return (
            <Grid item xs={12}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'grid',
                        flexDirection: 'column',
                        height: 'auto',
                    }}
                >
                    <Title sx={{ flexGrow: 1, pl: 2.5 }} title="Nutrient Table" />
                    <Grid container ml={2.5}>
                        {message}
                    </Grid>
                </Paper>
            </Grid>
        );
    else
        return (
            <>
                <MaterialTable
                    icons={tableIcons}
                    data={props.tableData}
                    columns={[
                        {
                            title: '',
                            field: 'name',
                            cellStyle: {
                                textAlign: 'left',
                                height: '48px',
                                maxWidth: 150,
                                padding: 0,
                            },
                            render: (row) => (
                                <div
                                    className={!row.parent && row.quantity !== '-' ? ' clickableCell' : ''}
                                    onClick={() => {
                                        if (!row.parent && row.quantity !== '-') setCategory(row.name);
                                    }}
                                >
                                    {germanTranslation && minor_category_translations[row.name]
                                        ? minor_category_translations[row.name]
                                        : row.name}
                                </div>
                            ),
                        },
                        {
                            title: t('quantity') + ' (g)',
                            field: 'quantity',
                            type: 'numeric',
                            cellStyle: {
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 100,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${
                                        clickableQuantityCells.includes(row.name) && !row.parent && row.quantity !== '-'
                                            ? ' clickableCell'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'quantity',
                                            row.name,
                                            row.quantity
                                        );
                                    }}
                                >
                                    {!isNaN(row.quantity) ? Math.round(row.quantity) : row.quantity}
                                </div>
                            ),
                        },
                        {
                            // This column does not allow for any action suggestions
                            // by the dieticians, therefore no render method
                            title: t('energy') + ' (%)',
                            field: 'energyShare',
                            type: 'numeric',
                            cellStyle: {
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 100,
                                padding: 0,
                            },
                        },
                        {
                            title: t('energy'),
                            field: 'energy',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'lightcoral' },
                            cellStyle: {
                                color: 'red',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${!row.parent && row.energy !== '-' && 'clickableCell'}`}
                                    onClick={() => {
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'Reduce',
                                            'energy',
                                            row.name,
                                            row.energy
                                        );
                                    }}
                                >
                                    {row.energy}
                                </div>
                            ),
                        },
                        {
                            title: t('sugars'),
                            field: 'sugar',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'lightcoral' },
                            cellStyle: {
                                color: 'red',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${!row.parent && row.sugar !== '-' && 'clickableCell'}`}
                                    onClick={() =>
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'Reduce',
                                            'sugar',
                                            row.name,
                                            row.sugar
                                        )
                                    }
                                >
                                    {row.sugar}
                                </div>
                            ),
                        },
                        {
                            title: t('saturated_fat'),
                            field: 'saturatedfat',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'lightcoral' },
                            cellStyle: {
                                color: 'red',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${
                                        !row.parent && row.saturatedfat !== '-' && 'clickableCell'
                                    }`}
                                    onClick={() =>
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'Reduce',
                                            'saturated fat',
                                            row.name,
                                            row.saturatedfat
                                        )
                                    }
                                >
                                    {row.saturatedfat}
                                </div>
                            ),
                        },
                        {
                            title: t('sodium'),
                            field: 'sodium',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'lightcoral' },
                            cellStyle: {
                                color: 'red',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${!row.parent && row.sodium !== '-' && 'clickableCell'}`}
                                    onClick={() =>
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'Reduce',
                                            'sodium',
                                            row.name,
                                            row.sodium
                                        )
                                    }
                                >
                                    {row.sodium}
                                </div>
                            ),
                        },
                        {
                            // This column does not allow for any action suggestions by the dieticians, therefore no render method
                            title: t('fruits_vegetables'),
                            field: 'fruitveg',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'mediumaquamarine ' },
                            cellStyle: {
                                color: 'green',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                            },
                        },
                        {
                            title: t('fiber'),
                            field: 'DietaryFiber',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'mediumaquamarine' },
                            cellStyle: {
                                color: 'green',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${
                                        !row.parent && row.DietaryFiber !== '-' && 'clickableCell'
                                    }`}
                                    onClick={() =>
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'Increase',
                                            'fiber',
                                            row.name,
                                            row.DietaryFiber
                                        )
                                    }
                                >
                                    {row.DietaryFiber}
                                </div>
                            ),
                        },

                        {
                            title: 'Protein',
                            field: 'protein',
                            type: 'numeric',
                            headerStyle: { backgroundColor: 'mediumaquamarine ' },
                            cellStyle: {
                                color: 'green',
                                borderLeft: '1px solid silver',
                                textAlign: 'center',
                                maxWidth: 80,
                                padding: 0,
                                position: 'relative',
                            },
                            render: (row) => (
                                <div
                                    className={`tableCell ${!row.parent && row.protein !== '-' && 'clickableCell'}`}
                                    onClick={() =>
                                        props.handleUpdateSuggestions(
                                            row.parent,
                                            row.parentId,
                                            'Increase',
                                            'protein',
                                            row.name,
                                            row.protein
                                        )
                                    }
                                >
                                    {row.protein}
                                </div>
                            ),
                        },
                    ]}
                    parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
                    options={{
                        toolbar: false, // hide table title
                        selection: false,
                        search: false,
                        paging: false,
                        sorting: true,
                        headerStyle: {
                            backgroundColor: '#bfbfbf',
                            textAlign: 'center',
                            firstChild: {
                                borderLeft: 'none',
                            },
                            maxWidth: 80,
                            padding: 0,
                            height: '48px',
                            flexDirection: 'unset',
                        },
                        rowStyle: (rowData) => ({
                            backgroundColor: rowData.parent ? '#e6e6e6' : '#fff',
                        }),
                    }}
                />
                {categoryHistory && categoryHistory.length > 0 ? (
                    <Dialog open onClose={() => setCategory(null)}>
                        <ProductsTable history={categoryHistory} />
                    </Dialog>
                ) : (
                    <ErrorAlert
                        open={openBetterProductsFetchError}
                        onClose={() => setOpenBetterProductsFetchError(false)}
                        text={t('alert_errorfetchingbetterproducts')}
                    />
                )}
            </>
        );
};

export default withTranslation()(NutrientTable);
