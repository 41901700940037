import React, { Component } from 'react';
import { TextField, Grid, Button, Box, Dialog, DialogContent, Divider, InputAdornment } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import UserSignUp from './UserSignUp';
import ControlGroupSignUp from './ControlGroupSignUp';
import SurveyInfoPage from '../../pages/SurveyInfoPage';
import migros_logo from '../../assets/images/migros_logo.png';
import coop_logo from '../../assets/images/coop_logo.png';
import Deactivate2fa from './Deactivate2fa';
import { scraperRequest } from '../../services/scraper';

class ConnectLoyaltyCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            migrosEmail: '',
            migrosPassword: '',
            coopEmail: '',
            coopPassword: '',
            validatingMigrosCredentials: false,
            validatingCoopCredentials: false,
            migrosCredentialsValidated: null,
            coopCredentialsValidated: null,
            openMigrosDialog: false,
            openCoopDialog: false,
            showMigrosPassword: false,
            showCoopPassword: false,
        };
    }

    validateMigrosCredentials = async () => {
        this.setState({ validatingMigrosCredentials: true });
        try {
            // TESTING
            const status = await scraperRequest(
                'GET',
                '/validate/migros-credentials',
                this.state.migrosEmail,
                this.state.migrosPassword
            );
            // const validated = true;

            if (status === 200) {
                this.setState({ migrosCredentialsValidated: true });
                this.props.handleChange({
                    migrosEmail: this.state.migrosEmail,
                    migrosPassword: this.state.migrosPassword,
                });
            } else if (status === 400) {
                alert(this.props.t('error_invalid_migros_credentials'));
            } else {
                alert(this.props.t('error_connection'));
            }
        } catch (error) {
            alert(this.props.t('validating_migros_unsuccessful'));
        }
        this.setState({ validatingMigrosCredentials: false });
    };

    validateCoopCredentials = async () => {
        this.setState({ validatingCoopCredentials: true });
        try {
            const status = await scraperRequest(
                'GET',
                '/validate/coop-credentials',
                null,
                null,
                this.state.coopEmail,
                this.state.coopPassword
            );
            if (status === 200) {
                this.setState({ coopCredentialsValidated: true });
                this.props.handleChange({
                    coopEmail: this.state.coopEmail,
                    coopPassword: this.state.coopPassword,
                });
            } else if (status === 400) {
                alert(this.props.t('error_invalid_coop_credentials'));
            } else {
                alert(this.props.t('error_connection'));
            }
        } catch (error) {
            alert(this.props.t('validating_coop_unsuccessful'));
        }
        this.setState({ validatingCoopCredentials: false });
    };

    render() {
        const { t, controlGroup, dataFetchingMethod, handleChange, authValues, prevStep, nextStep } = this.props;

        return (
            <>
                <SurveyInfoPage
                    title={t('survey_step_1_hsg')}
                    content={[
                        <>
                            <Typography fontWeight={600} fontSize={18} align="center">
                                {t('connect_loyalty_cards')}
                            </Typography>
                            <Grid container justifyContent="space-around" spacing={2} sx={{ pt: 2, pb: 2 }}>
                                <Grid container direction="column" item xs={6} alignItems={'center'}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ openMigrosDialog: true });
                                        }}
                                    >
                                        <img
                                            src={migros_logo}
                                            alt="Logo"
                                            width="150"
                                            height="150"
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                                boxShadow: '0px 0px 5px 2px grey',
                                            }}
                                        />
                                    </Button>
                                    <Box width={32} height={32}>
                                        {this.state.validatingMigrosCredentials ? (
                                            <CircularProgress size={32} />
                                        ) : this.state.migrosCredentialsValidated ? (
                                            <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                                        ) : (
                                            this.state.migrosCredentialsValidated === false && (
                                                <ErrorIcon style={{ color: 'red', fontSize: '2rem' }} />
                                            )
                                        )}
                                    </Box>
                                </Grid>
                                <Grid container direction="column" item xs={6} alignItems={'center'}>
                                    <Button
                                        onClick={() => {
                                            this.setState({ openCoopDialog: true });
                                        }}
                                    >
                                        <img
                                            src={coop_logo}
                                            alt="Logo"
                                            width="150"
                                            height="150"
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                                boxShadow: '0px 0px 5px 2px grey',
                                            }}
                                        />
                                    </Button>
                                    {this.state.validatingCoopCredentials ? (
                                        <>
                                            <Box width={32} height={32}>
                                                <CircularProgress size={32} />
                                            </Box>
                                            <Typography fontWeight={600} fontSize={18} align="center">
                                                {t('validating_coop')}
                                            </Typography>
                                        </>
                                    ) : this.state.coopCredentialsValidated ? (
                                        <Box width={32} height={32}>
                                            <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                                        </Box>
                                    ) : (
                                        this.state.coopCredentialsValidated === false && (
                                            <Box width={32} height={32}>
                                                <ErrorIcon style={{ color: 'red', fontSize: '2rem' }} />
                                            </Box>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                            {controlGroup ? (
                                <ControlGroupSignUp
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    handleChange={handleChange}
                                    values={authValues}
                                    dataFetchingMethod={dataFetchingMethod}
                                    migrosCredentialsValidated={this.state.migrosCredentialsValidated}
                                    coopCredentialsValidated={this.state.coopCredentialsValidated}
                                />
                            ) : (
                                <UserSignUp
                                    nextStep={nextStep}
                                    prevStep={prevStep}
                                    handleChange={handleChange}
                                    values={authValues}
                                    dataFetchingMethod={dataFetchingMethod}
                                    migrosCredentialsValidated={this.state.migrosCredentialsValidated}
                                    coopCredentialsValidated={this.state.coopCredentialsValidated}
                                />
                            )}
                        </>,
                    ]}
                />
                <Dialog open={this.state.openMigrosDialog} onClose={() => this.setState({ openMigrosDialog: false })}>
                    <DialogContent
                        sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                    >
                        <img
                            src={migros_logo}
                            alt="Logo"
                            width="150"
                            height="150"
                            style={{
                                padding: 10,
                                marginBottom: 10,
                                boxShadow: '0px 0px 5px 2px grey',
                            }}
                        />
                        <Deactivate2fa />
                        <Divider sx={{ width: '100%', my: 2 }} />
                        <TextField
                            margin="normal"
                            required
                            name="Migros Email"
                            label={t('Migros Email')}
                            type="Email"
                            id="Migros Email"
                            autoComplete="current-password"
                            value={this.state.migrosEmail}
                            onChange={(e) => this.setState({ migrosEmail: e.target.value })}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="Migros password"
                            label={t('Migros password')}
                            id="Migros password"
                            autoComplete="current-password"
                            value={this.state.migrosPassword}
                            onChange={(e) => this.setState({ migrosPassword: e.target.value })}
                            type={this.state.showMigrosPassword ? 'text' : 'password'}
                            InputProps={{
                                style: { width: '230px' },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                this.setState({ showMigrosPassword: !this.state.showMigrosPassword })
                                            }
                                        >
                                            {this.state.showMigrosPassword ? (
                                                <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                            ) : (
                                                <VisibilityIcon style={{ fontSize: 'small' }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            onClick={() => {
                                this.setState({ openMigrosDialog: false });
                                this.validateMigrosCredentials();
                            }}
                        >
                            check Migros credentials
                        </Button>
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.openCoopDialog} onClose={() => this.setState({ openCoopDialog: false })}>
                    <DialogContent
                        sx={{ textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                    >
                        <img
                            src={coop_logo}
                            alt="Logo"
                            width="150"
                            height="150"
                            style={{
                                padding: 10,
                                marginBottom: 10,
                                boxShadow: '0px 0px 5px 2px grey',
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="Supercard Email"
                            label="Supercard Email"
                            type="Email"
                            id="Supercard Email"
                            autoComplete="current-password"
                            value={this.state.coopEmail}
                            onChange={(e) => this.setState({ coopEmail: e.target.value })}
                        />
                        <TextField
                            margin="normal"
                            required
                            name="Supercard password"
                            label={t('supercard_password')}
                            id="Supercard password"
                            autoComplete="current-password"
                            value={this.state.coopPassword}
                            onChange={(e) => this.setState({ coopPassword: e.target.value })}
                            type={this.state.showCoopPassword ? 'text' : 'password'}
                            InputProps={{
                                style: { width: '230px' },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                this.setState({ showCoopPassword: !this.state.showCoopPassword })
                                            }
                                        >
                                            {this.state.showCoopPassword ? (
                                                <VisibilityOffIcon style={{ fontSize: 'small' }} />
                                            ) : (
                                                <VisibilityIcon style={{ fontSize: 'small' }} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            onClick={() => {
                                this.setState({ openCoopDialog: false });
                                this.validateCoopCredentials();
                            }}
                        >
                            check Coop credentials
                        </Button>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withTranslation()(ConnectLoyaltyCards);
