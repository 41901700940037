import React from 'react';
import { Typography, Paper, Button, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DashboardPage from './DashboardPage';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../services/useAuth';
import { useNavigate } from 'react-router-dom';
import LanguageMenu from '../components/Language/LanguageMenu';

const SettingsPage = () => {
    const { t } = useTranslation();
    let auth = useAuth();
    let navigate = useNavigate();

    const handleLogout = () => {
        auth.logout();
        navigate('/');
    };

    return (
        <DashboardPage
            content={
                <Paper
                    sx={{
                        mt: 2,
                        mb: 9,
                        display: 'grid',
                        height: 'auto',
                    }}
                >
                    <Typography variant="h5" noWrap align="center" sx={{ mt: 2, mb: 2 }}>
                        {t('settings')}
                    </Typography>
                    <Grid container justifyContent="center" sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="body1" noWrap align="center" sx={{ mt: 2, mb: 2 }}>
                                {t('change_language')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <LanguageMenu color="primary" />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justifyContent="center" sx={{ mt: 2, mb: 2 }}>
                        <Button variant="outlined" color="alert" startIcon={<LogoutIcon />} onClick={handleLogout}>
                            Logout
                        </Button>
                    </Grid>
                </Paper>
            }
        />
    );
};

export default SettingsPage;
