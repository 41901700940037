import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Typography } from '@mui/material';
import useSWR from 'swr';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

import DashboardPage from '../DashboardPage';
import { getData } from '../../services/users';
import CircularLoading from '../../components/Loading/CircularLoading';
import { ProfileInfo } from '../../utils/commonComponents';
import {
    getAllergiesValue,
    getAbstentionsValue,
    getDiseasesValue,
    toUpperCase,
    sports_list,
    educations_list,
    activityLevelAtWork_list,
} from '../../utils/ListOfThings';

export default function Profile() {
    const { t } = useTranslation();
    let birthdate = null;
    // let abstentions = null;

    const { data, error } = useSWR('/user/survey', getData);

    if (error) {
        return (
            <DashboardPage
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('error_fetching', { data: t('error_fetching_data') })}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    if (data === undefined || data.length === 0) {
        return (
            <DashboardPage
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('no_survey', { data: t('no_survey') })}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    if (data) {
        birthdate = new Date(data.birthdate);
    }

    return (
        <DashboardPage
            content={
                <>
                    {!data ? (
                        <CircularLoading />
                    ) : (
                        <Box sx={{ pt: 2, pb: 8 }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_personalinfo')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProfileInfo
                                        label={t('form_label_birthdate')}
                                        value={birthdate.toLocaleDateString('de-CH')}
                                    />
                                    <ProfileInfo label={t('gender')} value={t(data.gender.toLowerCase())} />
                                    <ProfileInfo label={t('height')} value={`${data.height} cm`} />
                                    <ProfileInfo label={t('weight')} value={`${data.weight} kg`} />
                                    <ProfileInfo label="Education" value={t(educations_list[data.education])} />
                                    {data.householdIncome === 'prefer not to answer' ? (
                                        <ProfileInfo label={t('householdIncome')} value={t('not_answered')} />
                                    ) : (
                                        <ProfileInfo label={t('householdIncome')} value={data.householdIncome} />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_allergiesandabstentions')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data.allergiesAndAbstentions.allergies.length !== 0 && (
                                        <ProfileInfo label={t('allergens')} value={getAllergiesValue(data)} />
                                    )}
                                    {(data.allergiesAndAbstentions.abstentions.length !== 0 || data.meat) && (
                                        <ProfileInfo
                                            label={t('abstentions')}
                                            value={
                                                data.meat
                                                    ? getAbstentionsValue(data)
                                                    : `${getAbstentionsValue(data)}, ${t('meat')}`
                                            }
                                        />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_health')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* TODO: check if other diseases correct display */}
                                    {!data.disorders.includes('None of the above') && (
                                        <ProfileInfo label={t('disorders')} value={getDiseasesValue(data)} />
                                    )}
                                    <ProfileInfo
                                        label={t('profile_activitylevel')}
                                        value={t(activityLevelAtWork_list[data.activityLevelAtWork])}
                                    />
                                    <ProfileInfo label="Sports" value={t(sports_list[data.sports])} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {t('profile_loyaltycards')}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ProfileInfo
                                        label={t('loyaltycards')}
                                        value={
                                            data.loyaltyCards === 'both'
                                                ? 'Migros, Coop'
                                                : toUpperCase(data.loyaltyCards)
                                        }
                                    />
                                    {data.percShoppingMigros !== -1 && (
                                        <ProfileInfo
                                            label={`Migros ${t('profile_shoppingfrequency')}`}
                                            value={`${data.percShoppingMigros} %`}
                                        />
                                    )}
                                    {data.usageMigros !== -1 && (
                                        <ProfileInfo
                                            label={`Migros ${t('profile_loyaltycardusage')}`}
                                            value={`${data.usageMigros} %`}
                                        />
                                    )}
                                    {data.percShoppingCoop !== -1 && (
                                        <ProfileInfo
                                            label={`Coop ${t('profile_shoppingfrequency')}`}
                                            value={`${data.percShoppingCoop} % `}
                                        />
                                    )}
                                    {data.usageCoop !== -1 && (
                                        <ProfileInfo
                                            label={`Coop ${t('profile_loyaltycardusage')}`}
                                            value={`${data.usageCoop} %`}
                                        />
                                    )}
                                    <ProfileInfo label={t('profile_adults')} value={data.loyaltyShareAdults} />
                                    <ProfileInfo label={t('profile_teens')} value={data.loyaltyShareTeens} />
                                    <ProfileInfo label={t('profile_kids')} value={data.loyaltyShareKids} />
                                </AccordionDetails>
                            </Accordion>
                            {/* Only display following accordion if there is data */}
                            {(data.historyUsingApps || data.currentlyUsingOtherApps) && (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        {t('profile_foodtrackingapps')}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {data.historyUsingApps && (
                                            <ProfileInfo
                                                label={t('profile_foodtrackingappsbefore')}
                                                value={data.historyApps}
                                            />
                                        )}
                                        {data.currentlyUsingOtherApps && (
                                            <ProfileInfo
                                                label={t('profile_foodtrackingappsnow')}
                                                value={data.currentOtherApps}
                                            />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Box>
                    )}
                </>
            }
        />
    );
}
