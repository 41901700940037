import { configData } from '../config.js';
import { getRequestOptions, getPostRequestOptions, handleResponse, handlePostResponse } from './utils.js';

export function getData(url, userId = null, startTimestamp = null, endTimestamp = null, category = null) {
    const requestOptions = getRequestOptions(userId, startTimestamp, endTimestamp, category);
    return fetch(`${configData.API_URL}${url}`, requestOptions).then(handleResponse);
}

export function saveRecommendations(recommendations, userid) {
    const requestOptions = getPostRequestOptions(recommendations, userid);
    return fetch(`${configData.API_URL}/dietician/recommendations-with-products`, requestOptions)
        .then(handlePostResponse)
        .catch(() => false);
}

export function registerDietician(data) {
    const signUpData = {
        email: data.credentials.email,
        password: data.credentials.password,
        firstName: data.dieticianInfo.firstName,
        lastName: data.dieticianInfo.lastName,
    };
    const requestOptions = getPostRequestOptions(signUpData);

    return fetch(`${configData.API_URL}/dietician/register`, requestOptions)
        .then(async (res) => {
            return res.status;
        })
        .catch((e) => {
            return 500;
        });
}
