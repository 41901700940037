import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    Grid,
} from '@mui/material';
import LocalizedDatePicker from '../DatePicker/LocalizedDatePicker';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../Alert/Alerts';
import { paperStyling } from '../../styles/ComponentStylings';
import { nutrientScores } from '../../utils/NutrientScore';
import {unixToParisDate} from '../../services/utils';

export default function NutrientTableDetailsTable({
    tableData,
    startDate,
    endDate,
    updateStartDate,
    updateEndDate,
    onFilter,
    minDate,
    maxDate,
}) {
    const { t } = useTranslation();
    const [openAlertDialog, setOpenAlertDialog] = useState(false);

    const handleFilterClick = () => {
        if (endDate.getTime() < startDate.getTime()) {
            setOpenAlertDialog(true);
        } else {
            onFilter();
        }
    };

    return (
        <Grid item xs={12}>
            <Paper sx={paperStyling}>
                {/* <Title sx={{ flexGrow: 1, pl: 2.5 }} title="Nutrient Table" /> */}
                <Grid container mt={1} spacing={0} direction="column" alignItems="flex-end">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" rowSpan={2}>
                                        Start ({t('earliest')} { unixToParisDate(tableData.latestTimestamp)})
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}>
                                        {t('end')} ({t('latest')} { unixToParisDate(tableData.earliestTimestamp)})
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}>
                                        Nutri-Score
                                    </TableCell>
                                    <TableCell align="center" rowSpan={2}>
                                        {t('in_table_baskets')}
                                    </TableCell>
                                    <TableCell align="center" colSpan={2}>
                                        {t('in_the_table')}
                                    </TableCell>
                                    <TableCell align="center" colSpan={2}>
                                        {t('total')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">{t('items')}</TableCell>
                                    <TableCell align="center">CHF</TableCell>
                                    <TableCell align="center">{t('items')}</TableCell>
                                    <TableCell align="center">CHF</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={tableData.averageNutriScore}>
                                    <TableCell>
                                        <LocalizedDatePicker
                                            id="start-datepicker"
                                            value={startDate}
                                            onChange={(e) => {
                                                updateStartDate(e);
                                            }}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <LocalizedDatePicker
                                            id="end-datepicker"
                                            value={endDate}
                                            onChange={(e) => {
                                                updateEndDate(e);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            {tableData.nutriscore !== '-'
                                                ? nutrientScores.get(tableData.averageNutriScore)
                                                : '-'}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">{tableData.inTableBasketNumber}</TableCell>
                                    <TableCell align="center">{tableData.inTableItems}</TableCell>
                                    <TableCell align="center">{tableData.inTableTotalPrice}</TableCell>
                                    <TableCell align="center">{tableData.allPurchasesItemNumber}</TableCell>
                                    <TableCell align="center">{tableData.allPurchasesTotalPrice}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box mt={2} mr={4} display="flex">
                        <Button variant="contained" onClick={handleFilterClick}>
                            {t('button_filter')}
                        </Button>
                        <ErrorAlert
                            open={openAlertDialog}
                            onClose={() => setOpenAlertDialog(false)}
                            text={t('alert_message_wrongdates')}
                        />
                    </Box>
                </Grid>
            </Paper>
        </Grid>
    );
}
