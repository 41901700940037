import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import { scraperRequest } from '../../services/scraper';

export default function FetchLoyaltyCards() {
    const { t } = useTranslation();

    const [fetchingData, setFetchingData] = useState(false);
    const [updatedMigros, setUpdatedMigros] = useState(null);
    const [updatedCoop, setUpdatedCoop] = useState(null);

    const updateShoppingData = async () => {
        setFetchingData(true);
        setUpdatedMigros(null);
        setUpdatedCoop(null);
        try {
            const success_migros = await scraperRequest('POST', '/user/update-migros');
            const success_coop = await scraperRequest('POST', '/user/update-coop');
            if (success_migros) setUpdatedMigros(true);
            if (success_coop) setUpdatedCoop(true);
            if (!success_migros || !success_coop) alert(t('something_went_wrong'));
        } catch (error) {
            setUpdatedMigros(false);
            setUpdatedCoop(false);
            alert(t('something_went_wrong'));
        }
        setFetchingData(false);
    };

    return (
        <Grid
            sx={{ display: 'flex', mt: 2, mb: 2, flexDirection: 'column' }}
            alignContent={'center'}
            justifyContent={'center'}
            container
        >
            <Button sx={{ width: '50%', bgcolor: '#ebebeb', color: 'black', mx: 'auto' }} onClick={updateShoppingData}>
                <Typography fontSize={15} align="center">
                    {updatedMigros && updatedCoop ? t('update_shopping_data_success') : t('update_shopping_data')}
                </Typography>
            </Button>
            {updatedMigros && updatedCoop && (
                <Typography fontSize={15} align="center" sx={{ mt: 1 }}>
                    {t('update_takes_a_while')}
                </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                {fetchingData ? (
                    <CircularProgress size={32} />
                ) : updatedMigros && updatedCoop ? (
                    <CheckCircleIcon style={{ color: 'green', fontSize: '2rem' }} />
                ) : (
                    updatedMigros || (updatedCoop === false && <ErrorIcon style={{ color: 'red', fontSize: '2rem' }} />)
                )}
            </Box>
        </Grid>
    );
}
