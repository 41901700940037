import React, { Component } from 'react'
import SurveyPage from '../../pages/SurveyPage';
import { SurveyButtonGroup } from '../../utils/commonComponents';
import { withTranslation } from 'react-i18next';


class VolunteerAdvise extends Component {
    constructor(props) {
        super(props);
        this.handleVolunteerTypeUpdate = this.handleVolunteerTypeUpdate.bind(this);
    }

    handleVolunteerTypeUpdate = () => {
        var controlGroup = this.props.controlGroup;
        var userType = this.props.values.userType;
        if (userType === 'Volunteer' && controlGroup) {
            this.props.handleChange('userType', "VolunteerControl");
        } else if (userType === 'Volunteer' && !controlGroup) {
            this.props.handleChange('userType', "VolunteerExperiment");
        } else {
            console.log("No user type update.");
        }
    };

    render() {
        const { t } = this.props;
        return (
            <SurveyPage
                title={t('survey_infotext_volunteer')}
                content={[
                    <SurveyButtonGroup
                        back={() => {
                            this.props.prevStep();
                        }}
                        next={() => {
                            this.handleVolunteerTypeUpdate();
                            this.props.nextStep();
                        }}
                    />
                ]}
            />
        )
    }
}

export default withTranslation()(VolunteerAdvise);