import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import SurveyPage from '../../pages/SurveyPage';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';


class CardsSharingQuestion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            shareAdultsErrorText: 'error',
            shareTeensErrorText: 'error',
            shareKidsErrorText: 'error',
            value: props.value
        }
    }

    validInput() {
        let shareAdultsValidity = false;
        let shareTeensValidity = false;
        let shareKidsValidity = false;
        let totalValidity = false;

        let shareAdults = this.props.values.loyaltyShareAdults;
        let shareTeens = this.props.values.loyaltyShareTeens;
        let shareKids = this.props.values.loyaltyShareKids;

        if (!validateSurveyFields('shareAdult', shareAdults)) {
            this.setState({ shareAdultsErrorText: 'Invalid input!' });
            shareAdultsValidity = false;
        } else {
            this.setState({ shareAdultsErrorText: '' });
            shareAdultsValidity = true;
        }

        if (!validateSurveyFields('number', shareTeens)) {
            this.setState({ shareTeensErrorText: 'Invalid input!' });
            shareTeensValidity = false;
        } else {
            this.setState({ shareTeensErrorText: '' });
            shareTeensValidity = true;
        }

        if (!validateSurveyFields('number', shareKids)) {
            this.setState({ shareKidsErrorText: 'Invalid input!' });
            shareKidsValidity = false;
        } else {
            this.setState({ shareKidsErrorText: '' });
            shareKidsValidity = true;
        }

        // check min 1, max 50 people
        let total = shareAdults + shareTeens + shareKids;
        if (total < 1 || total > 50) {
            totalValidity = false;
        } else totalValidity = true;

        this.setState({ validated: true });
        return shareAdultsValidity && shareTeensValidity && shareKidsValidity & totalValidity;
    }

    validateForm = (e) => {
        e.preventDefault();
        if (!this.validInput()) return;
        this.props.nextStep();
    }

    render() {
        const { values, handleChange, handleNumberInput, t } = this.props;
        return (
            <SurveyPage
                title={t('survey_question_usersharing')}
                content={[
                    <SurveyTextField
                        id='share-loyalty-adults'
                        label={t('form_label_sharingadults')}
                        value={values.loyaltyShareAdults}
                        onChange={(e) => handleChange('loyaltyShareAdults', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.shareAdultsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.shareAdultsErrorText.length !== 0 ? t('form_error_sharingAdults') +
                            t('form_error_sharingSelf') : ''}
                        autoFocus={true}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='share-loyalty-teens'
                        label={t('form_label_sharingteenagers')}
                        value={values.loyaltyShareTeens}
                        onChange={(e) => handleChange('loyaltyShareTeens', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.shareTeensErrorText.length !== 0}
                        helperText={this.state.validated && this.state.shareTeensErrorText.length !== 0 ? t('form_error_sharingTeens') +
                            t('form_error_sharingzero') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        id='share-loyalty-kids'
                        label={t('form_label_sharingkids')}
                        value={values.loyaltyShareKids}
                        onChange={(e) => handleChange('loyaltyShareKids', handleNumberInput(e.target.value))}
                        error={this.state.validated && this.state.shareKidsErrorText.length !== 0}
                        helperText={this.state.validated && this.state.shareKidsErrorText.length !== 0 ? t('form_error_sharingkids') +
                            t('form_error_sharingzero') : ''}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]}
            />
        )
    }
}

export default withTranslation()(CardsSharingQuestion);