import React from 'react';

import SurveyPage from '../../pages/SurveyPage';
import { withTranslation } from 'react-i18next';
import validateAuthFields from '../../utils/formValidator';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
// import { getData } from '../../services/dietician';
import { ErrorAlert } from '../Alert/Alerts';

class DieticianDemographics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstNameError: false,
            lastNameError: false,
            dieticianEmailError: false,
            dieticianPasswordError: false,
            passwordConfirmError: false,
            openErrorConnectionToServer: false,
        };
    }

    validateForm = async (e) => {
        let firstNameError = false;
        let lastNameError = false;
        let emailError = false;
        let passwordError = false;
        let passwordConfirmError = false;

        this.props.data.dieticianInfo.firstName.length === 0 ? (firstNameError = true) : (firstNameError = false);
        this.props.data.dieticianInfo.lastName.length === 0 ? (lastNameError = true) : (lastNameError = false);
        try {
            emailError =
                this.props.data.credentials.email.length === 0;
        } catch (_) {
            this.setState({ openErrorConnectionToServer: true });
        }
        passwordError = !validateAuthFields('password', this.props.data.credentials.password);
        passwordConfirmError = !(this.props.data.credentials.password === this.props.data.credentials.passwordConfirm);
        this.setState({
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            dieticianEmailError: emailError,
            dieticianPasswordError: passwordError,
            passwordConfirmError: passwordConfirmError,
        });
        if (firstNameError || lastNameError || emailError || passwordError || passwordConfirmError) return;
        this.props.submitData();
    };

    render() {
        const { data, handleAuthChange, handleDieticianInfoChange, t } = this.props;

        return (
            <SurveyPage
                title={t('survey_question_dietician')}
                content={[
                    <SurveyTextField
                        value={data.dieticianInfo.firstName}
                        id="dieticianFirstName"
                        label={t('form_label_firstname')}
                        placeholder={t('form_label_firstname')}
                        error={this.state.firstNameError}
                        onChange={(e) => handleDieticianInfoChange('firstName', e.target.value)}
                        helperText={this.state.firstNameError && t('form_error_name')}
                    />,
                    <SurveyTextField
                        value={data.dieticianInfo.lastName}
                        id="dieticianLastName"
                        label={t('form_label_lastname')}
                        placeholder={t('form_label_lastname')}
                        error={this.state.lastNameError}
                        onChange={(e) => handleDieticianInfoChange('lastName', e.target.value)}
                        helperText={this.state.lastNameError && t('form_error_name')}
                    />,
                    <SurveyTextField
                        value={data.credentials.email}
                        id="dieticianEmail"
                        label="Email"
                        placeholder="E-Mail"
                        error={this.state.dieticianEmailError}
                        onChange={(e) => handleAuthChange({ email: e.target.value })}
                        helperText={this.state.dieticianEmailError && t('alert_message_dieticiansignup')}
                    />,
                    <SurveyTextField
                        value={data.credentials.password}
                        id="dieticianPassword"
                        label={t('form_password')}
                        placeholder={t('form_password')}
                        type="password"
                        error={this.state.dieticianPasswordError}
                        onChange={(e) => handleAuthChange({ password: e.target.value })}
                        helperText={this.state.dieticianPasswordError && t('form_error_password')}
                    />,
                    <SurveyTextField
                        value={data.credentials.passwordConfirm}
                        id="passwordConfirm"
                        label={t('form_confirmpassword')}
                        placeholder={t('form_confirmpassword')}
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => handleAuthChange({ passwordConfirm: e.target.value })}
                        error={this.state.passwordConfirmError}
                        helperText={this.state.passwordConfirmError && t('form_error_passwordmatch')}
                    />,
                    <SurveyButtonGroup
                        back={() => {
                            this.props.prevStep();
                        }}
                        next={this.validateForm}
                    />,
                    <ErrorAlert
                        open={this.state.openErrorConnectionToServer}
                        onClose={() => this.setState({ openErrorConnectionToServer: false })}
                        text={this.props.t('error_connection')}
                    />,
                ]}
            />
        );
    }
}

export default withTranslation()(DieticianDemographics);
