import * as React from 'react';
import { Grid, Paper, Button } from '@mui/material';
import ShoppingHistoryTable from '../Tables/ShoppingHistoryTable';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function UserShoppingHistory({ shoppingHistory, shoppingHistoryError }) {
    const [openShoppingHistory, setOpenShoppingHistory] = useState(false);

    const { t } = useTranslation();

    return shoppingHistoryError ? (
        <Grid item xs={12} sx={{ mt: 2 }}>
            <Paper
                sx={{
                    p: 2,
                    display: 'grid',
                    flexDirection: 'column',
                    height: 'auto',
                }}
            >
                <Grid container ml={2.5}>
                    {t('error_fetching', {
                        data: t('error_fetching_user_shopping_data'),
                    })}
                </Grid>
            </Paper>
        </Grid>
    ) : (
        <>
            {shoppingHistory && shoppingHistory.length > 0 ? (
                <Paper
                    sx={{
                        p: 2,
                        display: 'grid',
                        flexDirection: 'column',
                        height: 'auto',
                        mt: 2,
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpenShoppingHistory(!openShoppingHistory);
                        }}
                    >
                        {t('show_shopping_history')}
                    </Button>
                    {openShoppingHistory ? <ShoppingHistoryTable data={shoppingHistory} /> : null}
                </Paper>
            ) : null}
        </>
    );
}
