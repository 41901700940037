import { createTheme } from '@mui/material/styles';


export const colourTheme = createTheme({
    palette: {
        primary: {
            main: '#009870'
        },
        secondary: {
            main: '#FFFAFA'
        },
        info: {
            main: '#99d5c5'
        },
        alert: {
            main: '#EB4D42'
        }
    },
    overrides: {
        MuiAvatar: {
            root: {
                width: '140px',
                height: '140px',
            }
        }
    }
});


export default colourTheme; 