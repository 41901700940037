import * as React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Paper, Card, CardContent, Typography } from '@mui/material';

import { getData } from '../../services/dietician';
import { withTranslation } from 'react-i18next';
import UserListTable from '../../components/Tables/UserListTable';
import DieticianDashboardPage from './DieticianDashboardPage';
import { Title } from '../../utils/commonComponents';
import CircularLoading from '../../components/Loading/CircularLoading';

const Volunteers = () => {
    const {
        data: users,
        error,
        isValidating,
    } = useSWR('volunteers', async () => {
        return getData('/dietician/users').then(
            // Currently VolunteerControl is not displayed to users
            async (users) => (users = users.filter((user) => user.userType === 'VolunteerExperiment'))
        );
    });

    const { t } = useTranslation();
    let message = '';
    if (error) message = t('alert_error');
    else if (users && users.length === 0) message = t('no_participants');

    if (isValidating)
        return (
            <DieticianDashboardPage
                navTitle={t('title_dietician_dashboard')}
                isDietician={true}
                content={<CircularLoading />}
            />
        );
    return (
        <DieticianDashboardPage
            navTitle={t('title_dietician_dashboard')}
            isDietician={true}
            content={
                <Container sx={{ mt: 4, mb: 4, ml: 2 }}>
                    <Grid container spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    p: 2,
                                    display: 'grid',
                                    flexDirection: 'column',
                                    height: 'auto',
                                }}
                            >
                                <Title title={t('paper_title_volunteerlist')} />
                                {(users && users.length === 0) || error ? (
                                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {message}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <UserListTable users={users} />
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            }
        />
    );
};

export default withTranslation()(Volunteers);
