import * as React from 'react';
import { useEffect, useState } from 'react';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18next from 'i18next';

const localeMap = {
    en: enLocale,
    de: deLocale,
    'de-DE': deLocale,
    'en-US': enLocale,
};

const maskMapper = {
    en: '__/__/____',
    de: '__.__.____',
};

export default function LocalizedDatePicker(props) {
    const [language, setLanguage] = useState({ locale: i18next.language, mask: '' });
    useEffect(() => {
        i18next.on('languageChanged', (lng) => {
            setLanguage({ locale: lng, mask: maskMapper[lng] });
        });
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[language.locale]}>
            <>
                <DatePicker
                    // mask={language.mask}
                    format="dd.MM.yy"
                    label={props.label}
                    value={props.value}
                    onChange={props.onChange}
                    error={props.error}
                    fullWidth
                    minDate={props.minDate}
                    maxDate={props.maxDate ? props.maxDate : new Date()}
                    renderInput={(params) => <TextField {...params} helperText={props.helperText}/>}
                />
            </>
        </LocalizationProvider>
    );
}
