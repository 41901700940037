import { Navigate } from 'react-router-dom';

import Home from './pages/Home';
import Error404Page from './pages/Error404Page';
import SignIn from './components/SignIn/SignIn';
import ForgotPassword from './components/SignIn/ForgotPassword';
import MultiStepSignUpSurvey from './components/SignUpSurvey/MultiStepSignUpSurvey';
import DashboardHome from './pages/UserDashboard/UserDashboard';
import Recommendations from './pages/UserDashboard/Recommendations';
import Profile from './pages/UserDashboard/Profile';
import FAQ from './pages/UserDashboard/FAQ';
import Analysis from './pages/UserDashboard/Analysis';
import EmailVerifiedPage from './pages/EmailVerifiedPage';
import VerifyEmailFirstPage from './pages/VerifyEmailFirstPage';
import ChangePassword from './components/SignIn/ChangePassword';
import SettingsPage from './pages/SettingsPage';
import AccountVerifiedPage from './pages/AccountVerifiedPage';
import { useAuth } from './services/useAuth';
import DieticianDashboard from './pages/DieticianDashboard/DieticianDashboard';
import PatientOverview from './pages/DieticianDashboard/PatientOverview';
import Patients from './pages/DieticianDashboard/Patients';
import DieticianProfile from './pages/DieticianDashboard/DieticianProfile';
import Settings from './pages/DieticianDashboard/Settings';
import Volunteers from './pages/DieticianDashboard/Volunteers';
import Brainfoodtest from './pages/DieticianDashboard/Brainfoodtest';

// checks if user is logged in and if so redirects to desired page
const ProtectedRoute = ({ children }) => {
    const auth = useAuth();
    if (!auth.user) return <Navigate to="error" replace />;
    return children;
};

const routes = [
    {
        path: 'dietician',
        children: [
            {
                path: 'dashboard',
                element: (
                    <ProtectedRoute>
                        <DieticianDashboard />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'participant/:userid',
                element: (
                    <ProtectedRoute>
                        <PatientOverview />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'participants',
                element: (
                    <ProtectedRoute>
                        <Patients />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'volunteers',
                element: (
                    <ProtectedRoute>
                        <Volunteers />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'brainfoodtest',
                element: (
                    <ProtectedRoute>
                        <Brainfoodtest />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'profile',
                element: (
                    <ProtectedRoute>
                        <DieticianProfile />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'settings',
                element: (
                    <ProtectedRoute>
                        <Settings />
                    </ProtectedRoute>
                ),
            },
            { path: '*', element: <Error404Page /> },
        ],
    },
    {
        path: 'user',
        children: [
            {
                path: 'dashboard',
                element: (
                    <ProtectedRoute>
                        <DashboardHome />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'recommendations',
                element: (
                    <ProtectedRoute>
                        <Recommendations />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'shopping-analysis',
                element: (
                    <ProtectedRoute>
                        <Analysis />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'profile',
                element: (
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'questions-and-answers',
                element: (
                    <ProtectedRoute>
                        <FAQ />
                    </ProtectedRoute>
                ),
            },
            { path: '*', element: <Error404Page /> },
        ],
    },
    {
        path: '/',
        children: [
            { path: '/', element: <Home /> },
            { path: '/signin', element: <SignIn /> },
            { path: '/signup', element: <MultiStepSignUpSurvey /> },
            { path: '/email-verified', element: <EmailVerifiedPage /> },
            { path: '/account-verified', element: <AccountVerifiedPage /> },
            { path: '/reset-password', element: <ForgotPassword /> },
            {
                path: '/settings',
                element: (
                    <ProtectedRoute>
                        <SettingsPage />
                    </ProtectedRoute>
                ),
            },
            { path: '/email-needs-verification', element: <VerifyEmailFirstPage /> },
            { path: '/change-password', element: <ChangePassword /> },
            { path: '*', element: <Error404Page /> },
            {
                path: '/complete-registration',
                element: <MultiStepSignUpSurvey completeRegistration={true}/>,
            },
        ],
    },
];

export default routes;
