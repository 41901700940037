import React from 'react';
import 'react-base-table/styles.css';
import './draggableTable.css';
import Table, { callOrReturn } from 'react-base-table';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

const DraggableContainer = sortableContainer(({ children }) => children);
const DraggableElement = sortableElement(({ children }) => children);
const DraggableHandle = sortableHandle(({ children }) => children);

const Row = ({ key, index, children, ...rest }) => (
    <DraggableElement key={key} index={index}>
        <div {...rest}>
            <DraggableHandle>
                <div className="Handle" />
            </DraggableHandle>
            {children}
        </div>
    </DraggableElement>
);

// Draggable Table based on the base table API
export default class DraggableTable extends React.Component {
    rowClassName = ({ rowIndex }) => {
        // first three rows of table should be coloured different
        // and therefore labelled as sticky rows
        if (!this.props.highlight) return;
        if (rowIndex >= 0 && rowIndex <= 2) return 'sticky-row';
    };

    // rowProps = ({ rowIndex }) => ({
    //     tagName: Row,
    //     index: rowIndex,
    // });

    table = React.createRef();

    getContainer = () => {
        return this.table.current.getDOMNode().querySelector('.BaseTable__body');
    };

    getHelperContainer = () => {
        return this.table.current.getDOMNode().querySelector('.BaseTable__table');
    };

    rowProps = (args) => {
        // passing the incoming rowProps
        const extraProps = callOrReturn(this.props.rowProps);
        return {
            ...extraProps,
            tagName: Row,
            index: args.rowIndex,
        };
    };

    handleSortEnd = ({ oldIndex, newIndex }) => {
        const suggestions = [...this.props.suggestions];
        const [removed] = suggestions.splice(oldIndex, 1);
        suggestions.splice(newIndex, 0, removed);
        suggestions.forEach((s, i) => (s.recommendation.id = i + 1));
        this.props.updateSuggestions(suggestions);
    };

    render() {
        // const recommendations = [...this.props.suggestions.map((s) => s.recommendation)];
        return (
            <DraggableContainer
                useDragHandle
                getContainer={this.getContainer}
                helperContainer={this.getHelperContainer}
                onSortEnd={this.handleSortEnd}
            >
                <Table
                    {...this.props}
                    ref={this.table}
                    data={this.props.suggestions}
                    fixed={false} // dragging the dots handle only works in flex mode(fixed=false)
                    rowProps={this.rowProps}
                    rowClassName={this.rowClassName}
                />
            </DraggableContainer>
        );
    }
}
