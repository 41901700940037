const dev = {
    // TESTING
    API_URL: 'https://diet-coach.interactions.ics.unisg.ch/dietcoach/backend',
    // API_URL: 'http://localhost:5682/dietcoach/backend',
    username: 'foodcoach',
    password: 'KaKUCxe4X7+QmpQ9',
};

const prod = {
    // API_URL: 'http://localhost:5682/dietcoach/backend',
    API_URL: 'https://api.interactions.ics.unisg.ch/dietcoach/backend',
    username: 'foodcoach',
    password: 'KaKUCxe4X7+QmpQ9',
};

export const configData = process.env.REACT_APP_ENVIRONMENT === 'development' ? dev : prod;
