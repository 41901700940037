import { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import i18next from 'i18next';
import { Paper, Card, CardContent, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import { Title } from '../../utils/commonComponents';
import CircularLoading from '../../components/Loading/CircularLoading';
import NutrientGraph from '../../components/NutrientGraph/NutrientGraph';
import DashboardPage from '../DashboardPage';
import { getData } from '../../services/users';
import FetchLoyaltyCards from './FetchLoyaltyCards';
import ReconnectLoyaltyCards from './ReconnectLoyaltyCards';
import DashboardHomePage from './DasboardHomePage';

export default function DashboardHome() {
    const { search } = useLocation();
    const initialLoad = useMemo(() => new URLSearchParams(search), [search]).get('initial');

    const { t } = useTranslation();
    const [displayGermanDisorders, setDisplayGermanDisorders] = useState(false);
    useEffect(() => {
        if (i18next.language === 'de' || i18next.language === 'de-CH') {
            setDisplayGermanDisorders(true);
        } else setDisplayGermanDisorders(false);
        toast(t('update_triggered'));
    }, [t]);

    let user = JSON.parse(localStorage.getItem('user'));
    let now = Math.round(new Date().valueOf() / 1000);

    const { data, error } = useSWR('/user/nutri-score-graph', (url) =>
        getData(url, user.external_id, now - 365 * 24 * 60 * 60, now)
    );

    let data_points = [];
    const labels = [];

    if (data && data.length !== 0) {
        for (const i in data.basket_nutri_score.slice(0, 7)) {
            const date = new Date(data.basket_nutri_score[i].basket_timestamp * 1000);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            labels.push(day + '/' + month);
            data_points.push(ofcom_conversion(data.basket_nutri_score[i].basket_ofcom));
        }
    }

    const nutrientData = {
        labels: labels.reverse(),
        datasets: [
            {
                label: 'Basket Data',
                data: data_points.reverse(),
                backgroundColor: 'primary',
                borderColor: 'white',
                borderWidth: 2,
            },
        ],
    };

    if (error) {
        return (
            <DashboardPage
                content={
                    <Card sx={{ bgcolor: '#f44336', m: 2 }}>
                        <CardContent>
                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                {t('error_fetching', { data: t('error_fetching_data') })}
                            </Typography>
                        </CardContent>
                    </Card>
                }
            />
        );
    }

    if (user.bam_user)
        return (
            <DashboardPage
                content={
                    <Paper
                        sx={{
                            mt: 2,
                            mb: 9,
                            display: 'grid',
                            height: 'auto',
                        }}
                    >
                        {!data ? (
                            <CircularLoading />
                        ) : user.bam_tokens_received ? (
                            <>
                                {nutrientData && nutrientData.datasets[0].data.length === 0 ? (
                                    <>
                                        <Card
                                            sx={{
                                                bgcolor: '#17541b',
                                                m: 2,
                                            }}
                                        >
                                            <CardContent>
                                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                    {t('infotext_bam_tokens_received')}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                        <Card
                                            sx={{
                                                bgcolor: '#17a2b8',
                                                m: 2,
                                            }}
                                        >
                                            <CardContent>
                                                <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                    {t('contact_us')}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </>
                                ) : (
                                    <>
                                        <Title sx={{ flexGrow: 1, pl: 2 }} title={t('section_title_nutrigraph')} />
                                        <NutrientGraph data={nutrientData} />

                                        {displayGermanDisorders ? (
                                            <>
                                                <Card
                                                    sx={{
                                                        bgcolor: 'white',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            sx={{ color: '#1d211f', fontWeight: 500, fontSize: 12 }}
                                                            align="center"
                                                            variant="subtitle1"
                                                        >
                                                            {data.user_nutri_score_message_de}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                                {data.user_nutri_score_message_de !==
                                                    data.user_ofcom_percentage_message_de && (
                                                    <Card
                                                        sx={{
                                                            bgcolor: 'white',
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                sx={{ color: '#1d211f', fontWeight: 500, fontSize: 12 }}
                                                                align="center"
                                                                variant="subtitle1"
                                                            >
                                                                {data.user_ofcom_percentage_message_de}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Card
                                                    sx={{
                                                        bgcolor: 'white',
                                                        mb: 1,
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            sx={{ color: '#1d211f', fontWeight: 500, fontSize: 12 }}
                                                            align="center"
                                                            variant="subtitle1"
                                                        >
                                                            {data.user_nutri_score_message_en}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                                <Card
                                                    sx={{
                                                        bgcolor: 'white',
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Typography
                                                            sx={{ color: '#1d211f', fontWeight: 500, fontSize: 12 }}
                                                            align="center"
                                                            variant="subtitle1"
                                                        >
                                                            {data.user_ofcom_percentage_message_en}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : !user.bam_tokens_received && user.register_time * 1000 + 3600 * 24 * 1000 < now * 1000 ? (
                            <>
                                <Card
                                    sx={{
                                        bgcolor: '#f44336',
                                        m: 2,
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('infotext_no_bam_account')}
                                        </Typography>
                                    </CardContent>
                                </Card>

                                <Button variant="contained" sx={{ mt: 3, mb: 2 }} href={user.bam_link}>
                                    LINK
                                </Button>
                                <Card
                                    sx={{
                                        bgcolor: '#17a2b8',
                                        m: 2,
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('contact_us')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </>
                        ) : (
                            <>
                                <Card
                                    sx={{
                                        bgcolor: '#17a2b8',
                                        m: 2,
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('infotext_accessingdata')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        bgcolor: '#17a2b8',
                                        m: 2,
                                    }}
                                >
                                    <CardContent>
                                        <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                            {t('contact_us')}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </>
                        )}
                    </Paper>
                }
            />
        );
    else {
        return (
            <>
                <DashboardPage
                    content={
                        <Paper
                            sx={{
                                mt: 2,
                                mb: 2,
                                display: 'grid',
                                height: 'auto',
                            }}
                        >
                            {initialLoad && <Toaster />}
                            {!data ? (
                                <CircularLoading />
                            ) : nutrientData && nutrientData.datasets[0].data.length === 0 ? (
                                user.register_time * 1000 + 3600 * 5 * 1000 < now * 1000 ? (
                                    <Card
                                        sx={{
                                            bgcolor: '#f44336',
                                            m: 2,
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('infotext_no_data_new')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <Card
                                        sx={{
                                            bgcolor: '#17a2b8',
                                            m: 2,
                                        }}
                                    >
                                        <CardContent>
                                            <Typography sx={{ color: 'white' }} align="center" variant="subtitle1">
                                                {t('infotext_accessingdata_new')}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )
                            ) : (
                                <DashboardHomePage
                                    data={data}
                                    displayGermanDisorders={displayGermanDisorders}
                                    nutrientData={nutrientData}
                                />
                            )}
                        </Paper>
                    }
                />
                {data && (
                    <>
                        <FetchLoyaltyCards />
                        <ReconnectLoyaltyCards />
                    </>
                )}
            </>
        );
    }
}

const ofcom_conversion = (ofcom_value) => {
    switch (true) {
        case ofcom_value < -15:
            return 0;
        case ofcom_value <= -1:
            return (-ofcom_value - 1) / 14 + 4;
        case ofcom_value <= 2:
            return (-ofcom_value + 2) / 3 + 3;
        case ofcom_value <= 10:
            return (-ofcom_value + 10) / 8 + 2;
        case ofcom_value <= 18:
            return (-ofcom_value + 18) / 8 + 1;
        case ofcom_value <= 40:
            return (-ofcom_value + 40) / 22 + 0;
        default:
            return 0;
    }
};
