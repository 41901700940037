import React, { Component } from 'react'
import { FormHelperText, Typography } from '@mui/material';
import Select from 'react-select'
import i18next from 'i18next';
import { WarningAlert } from '../Alert/Alerts';
import { SurveyTextField, SurveyButtonGroup } from '../../utils/commonComponents';
import { validateSurveyFields } from '../../utils/formValidator';
import SurveyPage from '../../pages/SurveyPage';
import { withTranslation } from 'react-i18next';


class DemographicQuestions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            weightErrorText: 'error',
            heightErrorText: 'error',
            bmiErrorText: 'error',
            genderErrorText: '',
            showGenderHelperText: false,
            openBMIInfoDialog: false,
            value: props.value
        }
        this.genderOptions = [
            { value: 'Female', label: i18next.t('female') },
            { value: 'Male', label: i18next.t('male') },
            { value: 'Other', label: i18next.t('other') }
        ];
    }

    // method checks if weight and height make sense
    // BMI formula: kg/m^2
    validBMI(weight, height) {
        if (weight !== '' && height !== '') {
            var bmi = weight / (Math.pow((height / 100), 2));
            this.props.values.bmi = Math.round(bmi * 100) / 100;
            if (bmi >= 8 && bmi <= 200) {
                return true;
            }
            if (bmi > 200) {
                // if bmi is over 200, send info that they should check if that's ok, but they can continue
                this.setState({ 'openBMIInfoDialog': true });
                return true;
            }
        }
        return false;
    }

    validInput() {
        let invalidCount = 0;

        if (!validateSurveyFields('weight', this.props.values.weight)) {
            this.setState({ weightErrorText: 'Invalid input!' });
            invalidCount += 1;
        } else this.setState({ weightErrorText: '' });

        if (!validateSurveyFields('height', this.props.values.height)) {
            this.setState({ heightErrorText: 'Invalid input!' });
            invalidCount += 1;
        } else this.setState({ heightErrorText: '' });

        if (!this.validBMI(this.props.values.weight, this.props.values.height)) {
            this.setState({ bmiErrorText: 'Invalid input!' });
            invalidCount += 1;
        } else { this.setState({ bmiErrorText: '' }) };

        if (!validateSurveyFields('select', this.props.values.gender)) {
            this.setState({ genderErrorText: 'Invalid input!' })
            this.setState({ showGenderHelperText: true });
            invalidCount += 1;
        } else this.setState({ showGenderHelperText: false });

        this.setState({ validated: true });
        if (invalidCount > 0) return false;
        return true;
    }

    handleGenderChange = (e) => {
        var gender = e.value;
        this.props.handleChange('gender', gender, (value) => { console.log('Gender selected: ' + value) });
    }

    handleCloseDialog(closeDialog) { this.setState({ [closeDialog]: false }); }

    validateForm = (e) => {
        e.preventDefault();

        if (!this.validInput()) return;
        this.props.nextStep();
    }

    render() {
        const { values, handleChange, handleNumberInput, t } = this.props;
        const { showGenderHelperText } = this.state;

        return (
            <SurveyPage
                title={t('survey_question_userdemographics')}
                content={[
                    <SurveyTextField
                        value={values.height}
                        id='height'
                        label={t('form_label_height')}
                        placeholder={t('form_label_height')}
                        onChange={(e) => handleChange('height', handleNumberInput(e.target.value))}
                        error={this.state.validated && ((this.state.heightErrorText.length === 0 && this.state.bmiErrorText.length === 0) ? false : true)}
                        helperText={this.state.validated && ((this.state.heightErrorText.length === 0 && this.state.bmiErrorText.length === 0) ? '' : t('form_error_height'))}
                        autoFocus={true}
                        inputProps={{ inputMode: 'numeric' }}
                    />,
                    <SurveyTextField
                        value={values.weight}
                        id='weight'
                        label={t('form_label_weight')}
                        placeholder={t('form_label_weight')}
                        onChange={(e) => handleChange('weight', handleNumberInput(e.target.value))}
                        error={this.state.validated && ((this.state.weightErrorText.length === 0 && this.state.bmiErrorText.length === 0) ? false : true)}
                        helperText={this.state.validated && ((this.state.weightErrorText.length === 0 && this.state.bmiErrorText.length === 0) ? '' : t('form_error_weight'))}
                        inputProps={{ inputMode: 'numeric' }}

                    />,
                    <>
                        <Typography
                            variant='h6'
                            align='center'
                            sx={{ mt: 2, mb: 2 }}
                        >
                            {t('form_gender')}
                        </Typography>
                        <Select name='gender-selection' placeholder={t('form_selection')} required
                            value={this.genderOptions.filter(({ value }) => value === values.gender)} onChange={this.handleGenderChange}
                            isSearchable={false}
                            options={this.genderOptions}>
                            styles={{ menu: base => ({ ...base, zIndex: 100 }) }}
                        </Select>
                        {showGenderHelperText ? <FormHelperText error>{t('form_error_gender')}</FormHelperText> : ''}
                    </>,
                    <WarningAlert
                        open={this.state.openBMIInfoDialog}
                        onClose={this.handleCloseDialog.bind(this, 'openBMIInfoDialog')}
                        text={t('alert_message_suspiciousbmi')}
                    />,
                    <SurveyButtonGroup back={() => { this.props.prevStep(); }} next={this.validateForm} />
                ]}
            />
        )
    }
}

export default withTranslation()(DemographicQuestions); 
