import React, { useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container, ThemeProvider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';
import { colourTheme } from '../../styles/ColourTheme';
import LanguageHeader from '../Language/LanguageHeader';
import { useTranslation } from 'react-i18next';
import { linkWithUnderline } from '../../styles/ComponentStylings';
import validateAuthFields from '../../utils/formValidator';
import { ErrorAlert, FeatureNotImplementedAlert } from '../Alert/Alerts';
import { sendResetPasswordMail } from '../../services/users';
import { InfoAlert } from '../Alert/Alerts';

export default function ForgotPassword() {
    const { t } = useTranslation();

    const [emailSent, setEmailSent] = useState(false);
    const [validEmailError, setValidEmailError] = useState(false);
    const [emailExistsError, setEmailExistsError] = useState(false);
    const [emailSentError, setEmailSentError] = useState(false);
    const [controlGroupUserError, setControlGroupUserError] = useState(false);
    const [notImplementedYet, setNotImplementedYet] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const email = new FormData(e.currentTarget).get('email');
        if (validateAuthFields('email', email)) {
            sendResetPasswordMail(email).then((response) => {
                if (response == null) {
                    setEmailSentError(true);
                } else if (response.status === 200) {
                    setEmailSent(true);
                } else if (response.status === 400 && response.body === 'Control users cannot reset their password') {
                    setControlGroupUserError(true);
                } else {
                    setEmailExistsError(true);
                }
            });
        } else {
            setValidEmailError(true);
        }
    };

    return (
        <ThemeProvider theme={colourTheme}>
            <LanguageHeader />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1 }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t('resetpassword')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h6" align="center">
                                    {t('resetpassword_enteremail')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="signin-email"
                                    type="email"
                                    label="E-Mail"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            {t('resetpassword')}
                        </Button>
                        <ErrorAlert
                            open={validEmailError}
                            onClose={() => {
                                setValidEmailError(false);
                            }}
                            text={t('alert_invalidemail')}
                        />
                        <ErrorAlert
                            open={emailExistsError}
                            onClose={() => {
                                setEmailExistsError(false);
                            }}
                            text={t('form_error_email_not_found')}
                        />
                        <ErrorAlert
                            open={emailSentError}
                            onClose={() => {
                                setEmailSentError(false);
                            }}
                            text={t('alert_emailnotsent')}
                        />
                        <InfoAlert
                            open={controlGroupUserError}
                            onClose={() => {
                                setControlGroupUserError(false);
                            }}
                            text={t('alert_controlgroup_password_reset_error')}
                        />
                        <InfoAlert
                            open={emailSent}
                            onClose={() => {
                                setEmailSent(false);
                            }}
                            text={t('info_emailsent')}
                        />
                        <FeatureNotImplementedAlert
                            open={notImplementedYet}
                            onClose={() => {
                                setNotImplementedYet(false);
                            }}
                        />
                        <Grid container>
                            <Grid item>
                                <Link to="/" style={linkWithUnderline}>
                                    {t('button_home')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
