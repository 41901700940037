import { configData } from '../config.js';

export function getRequestOptions(study_id = null, startTimestamp = null, endTimestamp = null, category = null) {
    let user = JSON.parse(localStorage.getItem('user'));
    return {
        method: 'GET',
        headers: {
            'Participant-Id': study_id,
            'Start-Timestamp': startTimestamp,
            'End-Timestamp': endTimestamp,
            'DietCoach-Minor-Category': category,

            ...(user && { Authentication: user.token }),
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
    };
}

export function getNoUserRequestOptions(userid) {
    return {
        method: 'GET',
        headers: {
            'Participant-Id': userid,
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
    };
}

export function getPostRequestOptions(body, study_id = null) {
    let user = JSON.parse(localStorage.getItem('user'));
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...(user && { Authentication: user.token }),
            'Study-Id': study_id,
        },
        body: JSON.stringify(body),
    };
}

export function getNoUserPostRequestOptions(body) {
    return {
        method: 'POST',
        headers: {
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
}

export function getShoppingAnalysisRequestOptions(study_id, analysisType) {
    let user = JSON.parse(localStorage.getItem('user'));
    return {
        method: 'GET',
        headers: {
            'Participant-Id': study_id,
            'Analysis-Type': analysisType,
            ...(user && { Authentication: user.token }),
            Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
            'Content-Type': 'application/json',
        },
    };
}

export function getBetterProductsRequestOptions(worstGTIN) {
    let user = JSON.parse(localStorage.getItem('user'));
    return {
        'Worst-Product-GTIN': worstGTIN,
        ...(user && { Authentication: user.token }),
        Authorization: 'Basic ' + window.btoa(configData.username + ':' + configData.password),
        'Content-Type': 'application/json',
    };
}

export function handleResponse(response) {
    if (response.status === 204) {
        return [];
    }
    return response.json().then((json) => {
        if (response.status !== 200 && response.status !== 201) {
            const error = (json && json.message) || response.statusText;
            return Promise.reject(error);
        }
        return json;
    });
}

export function handleResponseObject(response) {
    if (response.status === 204) {
        return {};
    }
    return response.json().then((json) => {
        // const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (json && json.message) || response.statusText;
            return Promise.reject(error);
        }
        return json;
    });
}

export function handlePostResponse(response) {
    if (!response.ok) {
        return false;
    }
    return true;
}

export function getUserBAMLink() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.link) {
        return user.link;
    }
    return;
}

export function unixToParisDate(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);

    // Convert to Paris timezone and format
    return date.toLocaleDateString('de-DE', {
        timeZone: 'Europe/Paris',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}

