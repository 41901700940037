import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, ThemeProvider, Box, Button, } from '@mui/material';
import colourTheme from '../styles/ColourTheme';
import { withTranslation } from 'react-i18next';
import { flexBox, mainButtonStyle } from '../styles/ComponentStylings';
import SurveyLanguageHeader from '../components/Language/SurveyLanguageHeader';
// import { useReactPWAInstall } from "react-pwa-install";
// import myLogo from "../assets/favicon.ico";

const SurveySuccessPage = (props) => {
    const { t } = props;

    return (
        <div className='SignUpCompletePage'>
            <ThemeProvider theme={colourTheme}>
                <SurveyLanguageHeader />
                <Container component='main' maxWidth='s'>
                    <Box sx={{
                        pt: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 5
                    }} >
                        <Typography component='h1' align='center' marginTop={5}>
                            {t('text_dietician_register_success')}
                        </Typography>
                    </Box>
                    <Box sx={flexBox}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Button
                                color='primary'
                                variant='contained'
                                sx={mainButtonStyle}
                            >
                                {t('button_home')}
                            </Button>
                        </Link>
                    </Box>
                </Container>
            </ThemeProvider>
        </div >
    )
}

export default withTranslation()(SurveySuccessPage);