// Hashmap of major food category ids for nutrient table row order
export const majorFoodCategoryIds = new Map([
    ['Beverages', 1],
    ['Fruits', 2],
    ['Grains, potatoes and legumes', 3],
    ['Oils, fats, sauces, nuts and seeds', 4],
    ['Processed food', 5],
    ['Protein foods', 6],
    ['Vegetables', 7],
]);

// Hashmap to get majorFoodCagetoryName by parentID
export const majorFoodCategoryNames = new Map([
    [1, 'Beverages'],
    [2, 'Fruits'],
    [3, 'Grains, potatoes and legumes'],
    [4, 'Oils, fats, sauces and nuts'],
    [5, 'Processed food'],
    [6, 'Protein foods'],
    [7, 'Vegetables'],
]);

// Hashmap of minor food category parent ids for nutrient table row order
export const minorFoodCategoryParentIds = new Map([
    ['Juices', 1],
    ['Milk drinks', 1],
    ['Sweet drinks', 1],

    ['Fruits', 2],

    ['Breads', 3],
    ['Muesli and cereals', 3],
    ['Grain products', 3],
    ['Legumes', 3],
    ['Dough', 3],
    ['Potatoes', 3],

    ['Fats and oil', 4],
    ['Cream', 4],
    ['Sauces', 4],
    ['Nuts, seeds and oil fruits', 4],

    ['Sausages and cold cuts', 5],
    ['Fast food', 5],
    ['Cheese products', 5],
    ['Salty snacks', 5],
    ['Ice cream and puddings', 5],
    ['Sweet snacks', 5],
    ['Jams and sweet spreads', 5],
    ['Bars', 5],

    ['Cream cheese and cottage cheese', 6],
    ['Milk products', 6],
    ['Meat and fish', 6],
    ['Vegetarian alternatives', 6],
    ['Eggs', 6],

    ['Vegetables', 7],
]);
